<template>
  <div v-if="page_loading">
      <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />
    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title style="height: 60px;">
        <h4>COD WALLET</h4> <v-icon>mdi-chevron-right</v-icon>  <span class="font-weight-regular body-1"> ข้อมูล COD </span>
        <v-spacer/>
        <vue-excel-xlsx
            :data="datalist"
            :columns="excel_wallet_detail_header"
            :filename="'รายงานรายละเอียด cod wallet'"
            :sheetname="'รายงานรายละเอียด cod wallet'"
            >
            <v-btn color="success" outlined >Export to Excel</v-btn>
        </vue-excel-xlsx>
      </v-card-title>
    </v-card>
    <v-card class="elevation-0 v-card-bottom-30">
      <v-card-title>
       <font class="hidden-xs-only" >ข้อมูล {{ codWalletID }} (เวลาออกใบเสร็จ {{ codWalletDate }})</font>
       <font class="hidden-sm-and-up" >ข้อมูล {{ codWalletID }}<br>(เวลาออกใบเสร็จ {{ codWalletDate }})</font>
      </v-card-title>
      <v-card-text>
        <!-- PC -->
        <v-data-table
          :headers="headers"
          :items="datalist"
          hide-default-footer :items-per-page="10000"
          item-key="orderID"
          class="elevation-0 packhai-border-table hidden-xs-only"
          >
          <template v-slot:item.orderID="{ item }">
            <v-btn style="text-decoration: underline" color="primary" text @click="detail_page(item.token)">{{ item.orderID }}</v-btn>
          </template>
          <template v-slot:item.expressCompanyLogo="{ item }">
            <div align="center">
              <v-img style="margin:5px;"
                :src="item.expressCompanyLogo"
                class="grey lighten-2"
                max-width="50"
                max-height="50"
              ></v-img>
            </div>
          </template>
        </v-data-table>
        <!-- Mobile -->
        <div >
          <v-simple-table class="hidden-sm-and-up packhai-border-table" >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" style="min-width: 100px;">
                    ออเดอร์
                  </th>
                  <th class="text-left" style="min-width: 170px;">
                    ส่งให้ขนส่ง
                  </th>
                  <th class="text-center" style="min-width: 100px;">
                    ขนส่ง
                  </th>
                  <th class="text-left" style="min-width: 100px;">
                    เลขแทรค
                  </th>
                  <th class="text-left" style="min-width: 250px;">
                    ชื่อผู้รับ
                  </th>
                  <th class="text-right" style="min-width: 120px;">
                    ยอด COD
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in datalist" :key="index" >
                  <td class="text-left">
                    <v-btn style="text-decoration: underline" color="primary" text @click="detail_page(item.token)">{{ item.orderID }}</v-btn>
                  </td>
                  <td class="text-left">{{ item.sendToExpressDatetime }}</td>
                  <td class="text-center">
                    <v-img style="margin:5px;"
                      :src="item.expressCompanyLogo"
                      class="grey lighten-2"
                      max-width="50"
                      max-height="50"
                    ></v-img>
                  </td>
                  <td class="text-left">{{ item.trackingNo }}</td>
                  <td class="text-left">{{ item.receiverName }}</td>
                  <td class="text-right">{{ item.codAmount }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div align="right" class="my-2">
          รวม  {{ this.sun_CODAmount }} บาท
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import Loading from '@/website/components/Loading'
  import axios from 'axios'
  import { shopService_dotnet } from '@/website/global'
  import { format_number, format_order_id, format_price, FormatInvoice, FormatCODWallet, set_format_date_time } from '@/website/global_function'
   
  export default {
    components: {
        Loading
    },

    data: () => ({
      header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
      page_loading: true,
      loading: false,
      headers: [
          { text: 'ออเดอร์', align: 'center', value: 'orderID', sortable: false, width: '8%' },
          { text: 'ส่งให้ขนส่ง', align: 'center', value: 'sendToExpressDatetime', sortable: false, width: '10%' },
          { text: 'ขนส่ง', align: 'center', value: 'expressCompanyLogo', sortable: false, width: '10%' },
          { text: 'เลขแทรค', align: 'center', value: 'trackingNo', sortable: false, width: '10%' },
          { text: 'ชื่อผู้รับ', align: 'left', value: 'receiverName', sortable: false, width: '20%' },
          { text: 'ยอด COD', align: 'right', value: 'codAmount', sortable: false, width: '10%' },
        ],
        datalist: [],
        to_excel_data: [],
        sun_CODAmount: "0",
        codWalletID: null,
        codWalletDate: null,

        excel_wallet_detail_header : [
          { label: "ออเดอร์", field: "orderID", width: 20},
          { label: "ส่งให้ขนส่ง", field: "sendToExpressDatetime", width: 30, dataFormat: set_format_date_time },
          { label: "ขนส่ง", field: "expressCompanyName", width: 20 },
          { label: "เลขแทรค", field: "trackingNo", width: 25 },
          { label: "ชื่อผู้รับ", field: "receiverName", width: 30 },
          { label: "ยอด COD", field: "codAmount", width: 15 }
        ],

    }),
    async created() {
      try {
        this.CODReportID = this.$route.query.id
        if (typeof(this.CODReportID) ==='undefined') {
          window.close()
        } else {
          await this.get_order_cod()
        }
      } catch (e) {
        window.close()
      }

      this.page_loading = false

    },
    methods: {
      format_number, format_order_id, format_price, FormatInvoice, FormatCODWallet, set_format_date_time,
      async get_order_cod () {

        this.loading = true

        let res1 = await axios.post(shopService_dotnet+'CODWallet/get-cod-wallet-detail',{'reportID' : this.CODReportID, 'shopID': localStorage.getItem("shop_id")},{ headers: this.header_token})
        this.datalist = res1.data.data_list
        this.sun_CODAmount = res1.data.sumCODAmount
        this.codWalletID = res1.data.codWalletID
        this.codWalletDate = res1.data.codWalletDate

        this.loading = false

      },

      detail_page (token) {
        window.open('order-detail?token='+token, '_blank');
      },
    }
  }
</script>
<style>
  .packhai-border-table{
    /* border:1px solid #e9edf4; */
    border-collapse: collapse;
    border-spacing: 0px;
    border-right: 1px solid #e9edf4;
    border-bottom: 1px solid #e9edf4;
    border-top: 1px solid #e9edf4;
  }
  .packhai-border-table thead{
    color:white;
  }
  .packhai-border-table thead tr th{
    color: black !important;
    font-weight: bold;
    font-size: 14px;
  }
  .packhai-border-table tr {
    /* border-collapse: collapse; */
    /* border: 1px solid #e9edf4; */
  }
  .packhai-border-table th {
    /* border-collapse: collapse; */
      border-left: 1px solid #e9edf4;
  }
  .packhai-border-table tr td{
    border-left: 1px solid #e9edf4;
  }


</style>
