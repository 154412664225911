<template>
     
    
    
      
  
      <!-- popup -->
      <v-dialog v-model="dialog" scrollable max-width="60%"  persistent :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card>
          <v-card-title >
            <span>วิธีการตั้งค่า สำหรับร้านค้าใหม่</span>
            <v-spacer></v-spacer>
            <v-btn icon  @click="dialog = false"><v-icon color="danger">mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-divider/>
          <v-card-text>
              <img src="@/assets/new_shop_step.jpg" class="img-responsive">
              <v-row justify="center">
              <v-btn color="#00aeef" @click="nevigate()"><span style="color:#fff">อ่านเพิ่มเติม</span></v-btn>
              </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
  
   
  </template>
  
  <script> 
    import axios from 'axios'
    import { shopService_dotnet } from '@/website/global'
   
    import { formatMoney, isNumberWithDot, isNumberWNoDot, DateNowNotNull, Trim_value, Trim_rules,get_languages } from '@/website/global_function'
  
    export default {
      components: {
          
      },
      data: () => ({
        // page
        page_loading: true,
        loading: false,
        dialog:false,
        
        use_language: null,
        set_language: null,
        //เช็คสิทธิ์
        authorize:false,
        header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
  
      }),
      async created() {
        this.dialog = true,
         //เก็บภาษามาไว้ใน use_language
           this.use_language = await this.get_languages(localStorage.getItem("set_language"));
          this.page_loading = false
        if(localStorage.getItem('staff_id') !== null || localStorage.getItem('staff_id') !== undefined)
        {
          await this.update_lastupdate_login()
        }
      },
  
      methods: {
        get_languages,
        formatMoney,
        isNumberWithDot,
        isNumberWNoDot,
        DateNowNotNull,
        Trim_value,
        Trim_rules,

        async update_lastupdate_login() {
          await axios.post(shopService_dotnet+'StaffShop/'+parseInt(localStorage.getItem('staff_id'))+'/lastlogin', {
        }, {headers: this.header_token})
        },
        nevigate(){
            this.$router.push("/teach");
        },
      }
    }
  </script>
  <style scoped>
.img-responsive {
  width: 100%; 
  height: auto;
  display: block; 
}
  </style>
  
  