<template>
  <div v-if="page_loading">
      <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />
    <v-card elevation="1" class="v-card-bottom-30 hidden-xs-only">
      <v-card-title  style="height: 60px;">
        <h4>{{use_language.finance}}</h4> <v-icon>mdi-chevron-right</v-icon><span class="font-weight-regular body-1">{{use_language.balance_master_list}}</span>
        <v-spacer/>
        <v-btn v-if="can_edit_balance_permission == true" dark icon color="primary" @click="can_edit_balance()"><v-icon>fa-edit</v-icon></v-btn>
      </v-card-title>
    </v-card>
    <v-card class="v-card-bottom-30">
      <v-card-title>
        <span v-if="balance > 0">{{use_language.balance_remaining_money}} : <font class="ml-3 text-success"> {{format_price(balance)}}</font></span>
        <span v-else>{{use_language.balance_remaining_money}} : <font class="ml-3 text-danger" > {{format_price(balance)}}</font></span>
        <v-spacer/>
        <v-btn color="success" outlined >
          <vue-excel-xlsx
              :data="topupHistory.table"
              :columns="excel_balance_header"
               v-bind:filename='balance_list_report_'
               v-bind:sheetname='balance_list_report'
              >
              {{use_language.export_excel}}
          </vue-excel-xlsx>
        </v-btn>

        <v-btn v-if="can_edit_balance_status == true" class="ml-1" @click="open_dialog_add_balance()"  outlined dark color="primary"><v-icon left color="primary">fa-plus-square</v-icon>{{use_language.add_balance}}</v-btn>


      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="2" lg="1" class="pb-3">
             <b>{{use_language.branch}} : </b>
          </v-col>
          <v-col cols="12" md="4" lg="4" class="pb-3">
            <v-combobox
              v-model="sh_shop_id"
              :items="sh_shop_list"
              item-text="name"
              item-value="id"
              outlined
              hide-details
              dense
            ></v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="2" lg="1" class="pb-3">
             <b>{{use_language.date}} : </b>
          </v-col>
          <v-col cols="12" md="2">
            <v-menu
                ref="search_menu_date_from"
                v-model="search_menu_date_from"
                :close-on-content-click="false"
                :return-value.sync="search_date_from"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      :value="computed_search_date_from"
                      @change="value => computed_search_date_from = value"
                      autocomplete="off"
                      label="เริ่ม"
                      outlined
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      hide-details
                      v-on="on"
                  >
                  <template v-slot:label> <span>{{use_language.start_}}</span> </template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="search_date_from" @input="search_menu_date_from = false, $refs.search_menu_date_from.save(search_date_from)" no-title :allowed-dates="allowedDates" class="my-0">
                </v-date-picker>
              </v-menu>
          </v-col>
          <v-col cols="12" md="2">
            <v-menu
                ref="search_menu_date_to"
                v-model="search_menu_date_to"
                :close-on-content-click="false"
                :return-value.sync="search_date_to"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      :value="computed_search_date_to"
                      @change="value => computed_search_date_to = value"
                      autocomplete="off"
                      label="สิ้นสุด"
                      outlined
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      hide-details
                      v-on="on"
                  >
                   <template v-slot:label> <span>{{use_language.end}}</span> </template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="search_date_to" @input="search_menu_date_to = false, $refs.search_menu_date_to.save(search_date_to)" no-title :allowed-dates="allowedDates" class="my-0">
                </v-date-picker>
              </v-menu>
          </v-col>
          <v-col cols="12" md="6">
            <v-btn color="primary" @click="getHistoryBalance(), descriptionIndex = null">{{use_language.search}}</v-btn>
          </v-col>

          <v-col cols="12" >
            <!-- แสดงจอ PC -->
            <div class="hidden-xs-only">
              <v-data-table :headers="headers" :items="topupHistory.table" hide-default-footer :items-per-page="10000" class="elevation-0 packhai-border-table" >
                <template v-slot:item.createdDateTime="{ item }">
                  {{ set_format_date_time(item.createdDateTime) }}
                </template>
                <template v-slot:item.description="{ item }">
                  <v-row v-if="item.description != null">
                    <v-col cols="12" align="left" v-if="item.income != null">
                      <span>{{ item.description }}</span>
                    </v-col>
                    <v-col cols="12" align="left" style="padding-left: 50px;" v-else>
                      {{ item.description }}
                     </v-col>
                  </v-row>
                </template>
                <template v-slot:item.income="{ item }">
                  <font v-if="item.income != null" class="text-success"><b>+ {{format_price(item.income)}}</b></font>
                </template>
                <template v-slot:item.expense="{ item }">
                  <font v-if="item.expense != null" class="text-danger"><b>- {{format_price(item.expense)}}</b></font>
                </template>
                <template v-slot:item.balance="{ item }">
                  <font><b>{{ format_price(item.balance) }}</b></font>
                </template>
                <template v-slot:item.seeMore="{ item }">
                  <span v-if="item.invoiceId != null">
                    <v-btn @click="seeMore_Invoice_List(item.invoiceId)"  icon><v-icon color="primary">mdi-clipboard-text</v-icon></v-btn>
                  </span>
                </template>
                <!-- <template v-slot:item.Print="{ item }">
                    <v-btn v-if="item.IsPrint != null" icon @click="PrintPreview(item.invoiceId)"><v-icon color="success">mdi-printer</v-icon></v-btn>
                </template> -->
                <template v-slot:item.edit="{ item }">
                  <span>
                    <v-btn @click="edit_balance_alert(item)"  icon><v-icon color="primary">fa-far fa-edit</v-icon></v-btn>
                  </span>
                </template>
                <template v-slot:item.delete="{ item }">
                  <span>
                    <v-btn @click="delete_balance_alert(item)"  icon><v-icon color="primary">fa-trash-alt</v-icon></v-btn>
                  </span>
                </template>
              </v-data-table>
            </div>
            <!-- แสดงจอมือถือ -->

            <div class="hidden-sm-and-up">
              <v-divider/>
              <v-card elevation="0" class="my-1" v-for="(item, index) in topupHistory.table" :key="index" >
                <v-row>
                  <v-col  cols="4" align="left"> <b>{{item.createdDateTime.substring(0, 10)}}</b> </v-col>
                  <v-col  cols="4" align="right">
                    <span v-show="item.income != null" class="text-success"><b>+ {{format_price(item.income)}}</b></span>
                    <span v-show="item.expense != null" class="text-danger"><b>- {{format_price(item.expense)}}</b></span>
                  </v-col>
                  <v-col  cols="4" align="right">= {{ format_price(item.balance) }}</v-col>
                </v-row>
                <v-row>
                  <v-col  cols="6" align="left">{{item.createdDateTime.substring(11, 16)}}</v-col>
                  <v-col cols="6" align="right">
                    <v-btn icon @click="showDescription(index, item.ID)" v-if="descriptionIndex != index" ><v-icon>fa-angle-down</v-icon></v-btn>
                    <v-btn icon @click="showDescription(index, item.ID)" v-else="" ><v-icon>fa-angle-up</v-icon></v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="descriptionIndex == index ">
                  <v-col cols="9" class="pb-5">
                    {{ item.description }}
                  </v-col>
                  <v-col cols="3" align="center">
                    <span v-if="item.invoiceId != null">
                      <v-btn @click="seeMore_Invoice_List(item.invoiceId)"  icon> <b><a href="#" style="color: #00BFFF; text-decoration: none;">{{use_language.details}} {{'>>'}} </a></b>  </v-btn>
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="py-2">
                    <v-divider/>
                  </v-col>
                </v-row>
              </v-card>
            </div>

          </v-col>
        </v-row>
      </v-card-text>
    </v-card>


    <!-- dialog add balance -->
    <v-dialog v-model="dialog_add_balance" scrollable :max-width="size_percent_for_mobile" persistent >
      <v-card class="my-0">
        <v-card-title >
          <span>{{use_language.add_balance_information}}</span>
          <v-spacer></v-spacer>
          <v-btn icon  @click="dialog_add_balance = false, clear_data_add_balance()"><v-icon color="danger">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-row  row>
              <v-col class="pb-0" cols="12" md="2">{{use_language.details}} :</v-col>
              <v-col class="pb-0" cols="12" md="10">
                <v-textarea
                  ref="d_abl_description"
                  v-model="d_abl_description"
                  outlined
                  name="input-7-4"
                  style="max-width: 500px;"
                  hide-details
                  dense
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row  row>
              <v-col class="pb-0" cols="12" md="2">{{use_language.in}} :</v-col>
              <v-col class="pb-0" cols="12" md="10">
                <v-text-field
                  ref="d_abl_income"
                  :value="d_abl_income"
                  @change="value => d_abl_income = value"
                  autocomplete="off"
                  outlined
                  type="number"
                  required
                  hide-details
                  dense
                  style="width: 250px"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row  row>
              <v-col class="pb-0" cols="12" md="2">{{use_language.leave}} :</v-col>
              <v-col class="pb-0" cols="12" md="10">
                <v-text-field
                  ref="d_abl_expense"
                  :value="d_abl_expense"
                  @change="value => d_abl_expense = value"
                  autocomplete="off"
                  outlined
                  type="number"
                  required
                  hide-details
                  dense
                  style="width: 250px"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider/>
        <v-card-actions >
          <v-col class="py-1" cols="12" align="right">
            <v-btn color="danger" dark @click="add_edit_data_balance()">{{use_language.save}}</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import Loading from '@/website/components/Loading'
  import axios from 'axios'
  import Swal from 'sweetalert2'
  import { agentService_dotnet, shopService_dotnet, financialService } from '@/website/global'
  import { format_number, format_order_id, format_price, set_format_date_time,get_languages } from '@/website/global_function'
 
  import { agent_language } from "@/website/language/agent_language";

  
  export default {
    components: {
        Loading
    },
    data: () => ({
      header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
      page_loading: true,
      loading: false,
      can_edit_balance_permission : null,

      use_language: null,
      set_language: null,


      topupDialog: false,
      transferMoney: '',
      transferDate: '',
      transferHour: new Date().getHours(),
      transferMin: new Date().getMinutes(),
      showPicker: false,
      bankAccount: {
        'selected': null,
        'list': []
      },

      search_menu_date_from: false,
      search_date_from: null,

      search_menu_date_to: false,
      search_date_to: null,

      balance: 0,
      filterSelect: 1,
      filterData: [
        { 'label': 'ทั้งหมด', 'value': 1 },
        { 'label': 'เติมเงิน', 'value': 2 },
        { 'label': 'ใช้ไป', 'value': 3 }
      ],
      topupHistory: {
        'headersAll': [],
        'table': [],
        'keyTable': 0
      },

      headersAll: [
        { text: 'วันที่', align: 'center', value: 'createdDateTime', sortable: false, width: '10%' },
        { text: 'รายละเอียด', align: 'left', value: 'description', sortable: false, width: '25%' },
        { text: 'เข้า', align: 'right', value: 'income', sortable: false, width: '10%' },
        { text: 'ออก', align: 'right', value: 'expense', sortable: false, width: '10%' },
        { text: 'คงเหลือ', align: 'right', value: 'balance', sortable: false, width: '10%' },
        { text: 'รายละเอียด', align: 'center', value: 'seeMore', sortable: false, width: '5%' },
        // { text: 'ปริ้น', align: 'center', value: 'print', sortable: false, width: '5%'},
      ],

      headersForEdit: [
        { text: 'วันที่', align: 'center', value: 'createdDateTime', sortable: false, width: '10%' },
        { text: 'รายละเอียด', align: 'left', value: 'description', sortable: false, width: '25%' },
        { text: 'เข้า', align: 'right', value: 'income', sortable: false, width: '10%' },
        { text: 'ออก', align: 'right', value: 'expense', sortable: false, width: '10%' },
        { text: 'คงเหลือ', align: 'right', value: 'balance', sortable: false, width: '10%' },
        { text: 'รายละเอียด', align: 'center', value: 'seeMore', sortable: false, width: '5%' },
        // { text: 'ปริ้น', align: 'center', value: 'print', sortable: false, width: '5%'},
        { text: 'แก้ไข', align: 'center', value: 'edit', sortable: false, width: '5%'},
        { text: 'ลบ', align: 'center', value: 'delete', sortable: false, width: '5%'},
      ],


      to_excel_data: [],
      footer_props: {
         'items-per-page-options': [50],
         'items-per-page-text': null,
         'disable-items-per-page': true
      },
      excel_balance_header : [
          { label: "วันที่", field: "createdDateTime", width: 20, dataFormat: set_format_date_time },
          { label: "รายละเอียด", field: "description", width: 35 },
          { label: "เข้า", field: "income", width: 10 },
          { label: "ออก", field: "expense", width: 10 },
          { label: "คงเหลือ", field: "balance", width: 10 },
        ],

      descriptionIndex: null,

      sh_shop_id: [],
      sh_shop_list : [],

      //edit balance
      can_edit_balance_status: false,
      dialog_add_balance : false,
      size_percent_for_mobile: '45%',
      isFullscreen: false,
      d_abl_id: null,
      d_abl_description: null,
      d_abl_income: null,
      d_abl_expense: null,
      d_abl_balance: null,
    }),
    computed: {
      headers: function() {
        if (this.filterSelect==1){
          return this.topupHistory['headersAll']
        } else {
          return this.topupHistory['headersAll']
        }
      },

      computed_search_date_from() {
        return this.formatDates(this.search_date_from)
      },
      computed_search_date_to() {
        return this.formatDates(this.search_date_to)
      },
    },
    watch: {
      filterSelect: async function() {
        await this.getHistoryBalance()
      }
    },
    async created() {

       //เก็บภาษามาไว้ใน use_language
         this.use_language = await this.get_languages(localStorage.getItem("set_language"));


      this.can_edit_balance_permission = JSON.parse(localStorage.getItem('permission'))['EditBalanceAgent']
      this.topupHistory.headersAll = this.headersAll ;
      // this.search_date_from = this.search_date_from.substring(0, 8) + "01"
      await this.agent_get_shop_list()
      // await this.getBalance()
      await this.getHistoryBalance()
      this.page_loading = false
    },
    methods: {
      format_number, format_order_id, format_price, set_format_date_time,get_languages,
      allowedDates: val => val <= new Date().toISOString().substr(0, 10),

      formatDate (date) {
        if (date.length == 0) return null

        if (date.length == 1) {
          const [year, month, day] = date[0].split('-')
          return `${day}-${month}-${year}`
        } else {
          const [year_from, month_from, day_from] = date[0].split('-')
          const [year_to, month_to, day_to] = date[1].split('-')
          if (date[0] < date[1]) {
            return `${day_from}-${month_from}-${year_from} ~ ${day_to}-${month_to}-${year_to}`
          } else {
            return `${day_to}-${month_to}-${year_to} ~ ${day_from}-${month_from}-${year_from}`
          }
        }
      },

      formatDates (date) {
        if (date == null){
          return null
        } else {
          const [year, month, day] = date.split('-')
          return `${day}-${month}-${year}`
        }
      },

      showDescription (index, ID) {
        if(this.descriptionIndex == index){
          this.descriptionIndex = null
        }else {
          this.descriptionIndex = index
        }

      },

      edit_date_format_for_dotnet ( val ){
        const _date = new Date(val)
        var informDatetime_tmp = new Date( _date.getTime() + 7 * (60 * 60 * 1000) ).toISOString()
        return informDatetime_tmp
      },

      async agent_get_shop_list() {
        let response = await axios.post(agentService_dotnet+'AgentFinance/agent-get-shop-list',{
           "masterShopId": localStorage.getItem('shop_id')
         } , { headers: this.header_token})
         this.sh_shop_list = response.data
         if ( response.data.length > 0) {
            this.sh_shop_id =  this.sh_shop_list[0]
         } else {
           this.sh_shop_id = { "name": "--- ไม่มีร้านค้า ---", "id": null }
         }
      },

      async getHistoryBalance() {
        this.loading = true
        var shop_select = 0
        if(typeof this.sh_shop_id != 'string' ){
          shop_select = this.sh_shop_id.id == null ? 0 : this.sh_shop_id.id
        }


        var search_date_from = this.search_date_from
        var search_date_to = this.search_date_to

        if(search_date_from != null){
          search_date_from = this.edit_date_format_for_dotnet(new Date(search_date_from).toISOString())
        }

        if(search_date_to != null){
          search_date_to = this.edit_date_format_for_dotnet(new Date(search_date_to).toISOString())
        }

         await axios.all([
           axios.post(agentService_dotnet+'AgentFinance/agent-get-shop-balance-list',{
              "shopId":  shop_select,
              "masterShopId": localStorage.getItem('shop_id'),
              "dataFrom":  search_date_from,
              "dateTo": search_date_to,
            } , { headers: this.header_token}),
            await axios.post(shopService_dotnet+'Shop/get-shop',{ "shopId": shop_select } , { headers: this.header_token}),
         ]).then(axios.spread((res_balanceHistory, res_shop) => {

           if(  res_shop.data != "" ){
             this.balance = res_shop.data.agentBalance  // AgentBalance
           } else {
             this.balance = 0
           }

           if(res_balanceHistory.data.balance_list != null) {
             this.topupHistory['table'] = res_balanceHistory.data.balance_list
           } else {
             this.topupHistory['table'] = []
           }

           if(res_balanceHistory.data.date_return != null){
             this.search_date_from = res_balanceHistory.data.date_return[0]
             this.search_date_to = res_balanceHistory.data.date_return[1]
           } else {
             var nowDate = new Date()
             this.search_date_to = nowDate.toISOString().substr(0, 10)
             nowDate.setDate(nowDate.getDate()-7)
             this.search_date_from = nowDate.toISOString().substr(0, 10)
           }

         }));

        this.loading = false
      },
      SearchingClearData () {

        var nowDate = new Date()
        this.search_date_to = nowDate.toISOString().substr(0, 10)
        nowDate.setDate(nowDate.getDate()-7)
        this.search_date_from = nowDate.toISOString().substr(0, 10)
      },


      seeMore_Invoice_List (invoiceId) {
        // localStorage.setItem('invoiceId', invoiceId)
        window.open('/view_detail_invoice?inv=' + invoiceId.toString(), '_blank');
      },

      // async PrintPreview(invoiceId){
      //   this.loading = true
      //   var dataPrint = []
      //   let response = await axios.post(financialService+'financialFC/get_invoice_print', {
      //     'invoiceID': invoiceId
      //   })
      //   if (response.data.status == 'Success'){
      //     dataPrint = response.data.result
      //     localStorage.setItem('shop_invoice_dataPrint',  JSON.stringify(dataPrint))
      //     this.loading = false
      //   } else {
      //     this.$swal({
      //       type: 'warning',
      //       title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
      //     })
      //     this.loading = false
      //   }
      //
      //   window.open('/print-invoice');
      // },

      can_edit_balance () {
         // this.can_edit_balance_status = false
        this.can_edit_balance_status = !this.can_edit_balance_status
        if(this.can_edit_balance_status){
          this.topupHistory.headersAll = this.headersForEdit
        } else {
          this.topupHistory.headersAll = this.headersAll
        }
      },

      open_dialog_add_balance (isFullscreen) {
        this.dialog_add_balance = true
        this.isFullscreen = isFullscreen
        if(isFullscreen == true){
          this.size_percent_for_mobile = '95%'
        }else {
          this.size_percent_for_mobile = '45%'
        }
      },

      clear_data_add_balance () {
        this.d_abl_id = null
        this.d_abl_description = null
        this.d_abl_income = null
        this.d_abl_expense = null
        this.d_abl_balance = null
      },

      edit_balance_alert (item) {
        this.d_abl_id = item.id
        this.d_abl_description = item.description
        this.d_abl_income = item.income
        this.d_abl_expense = item.expense
        // this.d_abl_balance = null
        this.dialog_add_balance = true
      },

      async add_edit_data_balance () {
        if(this.d_abl_id == null){
          this.d_abl_description = this.d_abl_description == '' ? null : this.d_abl_description
          this.d_abl_income = this.d_abl_income == '' ? null : this.d_abl_income
          this.d_abl_expense = this.d_abl_expense == '' ? null : this.d_abl_expense
          if(this.d_abl_description == null) {
            this.$refs.d_abl_description.focus()
            return
          } else if (this.d_abl_income == null && this.d_abl_expense == null) {
            this.$refs.d_abl_income.focus()
            return
          }

          this.loading = true
          let response = await axios.post(shopService_dotnet+'Balance/update-agent-balance',{
              "id": 0,
              "masterShopID": localStorage.getItem('shop_id'),
              "shopID": this.sh_shop_id.id,
              "description": this.d_abl_description,
              "income": this.d_abl_income,
              "expense": this.d_abl_expense,
              "createdDateTime": "2021-08-27T08:19:55.864Z",
              "createdBy": localStorage.getItem('staff_id'),
           } , { headers: this.header_token})

           this.loading = false
           this.dialog_add_balance = false
           this.clear_data_add_balance()
           this.search_date_from = null
           this.search_date_to = null
           this.getHistoryBalance()

        } else {
          this.d_abl_description = this.d_abl_description == '' ? null : this.d_abl_description
          this.d_abl_income = this.d_abl_income == '' ? null : this.d_abl_income
          this.d_abl_expense = this.d_abl_expense == '' ? null : this.d_abl_expense
          if(this.d_abl_description == null) {
            this.$refs.d_abl_description.focus()
            return
          } else if (this.d_abl_income == null && this.d_abl_expense == null) {
            this.$refs.d_abl_income.focus()
            return
          }

          this.loading = true
          let response = await axios.post(shopService_dotnet+'Balance/update-agent-balance',{
              "id": this.d_abl_id,
              "masterShopID": localStorage.getItem('shop_id'),
              "shopID": this.sh_shop_id.id,
              "description": this.d_abl_description,
              "income": this.d_abl_income,
              "expense": this.d_abl_expense,
              "createdDateTime": "2021-08-27T08:19:55.864Z",
              "createdBy": localStorage.getItem('staff_id'),
           } , { headers: this.header_token})
           this.loading = false
           this.dialog_add_balance = false
           this.clear_data_add_balance()
           this.search_date_from = null
           this.search_date_to = null
           this.getHistoryBalance()
        }

      },

      async delete_balance_alert (item) {
        Swal.fire({
          position: "top",
          type: 'warning',
          title: 'ต้องการลบ ใช่หรือไม่!!',
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          allowOutsideClick: false
        }).then(async(result) => {
          if (result.value) {
            this.loading = true
            let response = await axios.post(shopService_dotnet+'Balance/delete-agent-balance',{
               "shopBalanceID": item.id
             } , { headers: this.header_token})
             Swal.fire({
               toast: true,
               timer: 1500,
               showConfirmButton: false,
               position: 'top',
               type: 'success',
               title: 'ลบเรียบร้อย'
             })
             this.search_date_from = null
             this.search_date_to = null
             this.getHistoryBalance()
             this.loading = false
          }
        })
      },
    }
  }
</script>
<style>
  .packhai-border-table{
    /* border:1px solid #e9edf4; */
    border-collapse: collapse;
    border-spacing: 0px;
    border-right: 1px solid #e9edf4;
    border-bottom: 1px solid #e9edf4;
    border-top: 1px solid #e9edf4;
  }
  .packhai-border-table thead{
    color:white;
  }
  .packhai-border-table thead tr th{
    color: black !important;
    font-weight: bold;
    font-size: 14px;
  }
  .packhai-border-table tr {
    /* border-collapse: collapse; */
    /* border: 1px solid #e9edf4; */
  }
  .packhai-border-table th {
    /* border-collapse: collapse; */
      border-left: 1px solid #e9edf4;
  }
  .packhai-border-table tr td{
    border-left: 1px solid #e9edf4;
  }


</style>
