<template>
  <div v-if="page_loading">
      <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <v-card elevation="1"  class="v-card-bottom-30 hidden-xs-only">
      <v-card-title style="height: 60px;">
        <h4>{{use_language.finance}}</h4> <v-icon>mdi-chevron-right</v-icon><span class="font-weight-regular body-1">{{use_language.agent_payment_notification}}</span>
      </v-card-title>
    </v-card>

    <v-card class="v-card-bottom-30">
      <v-card-title>
       <v-icon size="20" color="primary">fa-sliders-h</v-icon><span class="py-2 ml-3">{{use_language.searchList}}</span>
       <v-spacer/>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="2">
            <v-menu
                ref="search_menu_date_from"
                v-model="search_menu_date_from"
                :close-on-content-click="false"
                :return-value.sync="search_date_from"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      :value="computed_search_date_from"
                      @change="value => computed_search_date_from = value"
                      autocomplete="off"
                      label="เริ่ม"
                      outlined
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      hide-details
                      dense
                      v-on="on"
                  >
                             <template v-slot:label> <span>{{use_language.start_}}</span> </template> 

                  </v-text-field>
                </template>
                <v-date-picker v-model="search_date_from" @input="search_menu_date_from = false, $refs.search_menu_date_from.save(search_date_from)" no-title :allowed-dates="allowedDates" class="my-0">
                </v-date-picker>
              </v-menu>
          </v-col>
          <v-col cols="12" md="2">
            <v-menu
                ref="search_menu_date_to"
                v-model="search_menu_date_to"
                :close-on-content-click="false"
                :return-value.sync="search_date_to"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      :value="computed_search_date_to"
                      @change="value => computed_search_date_to = value"
                      autocomplete="off"
                      label="สิ้นสุด"
                      outlined
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      hide-details
                      dense
                      v-on="on"
                  >
                    <template v-slot:label> <span>{{use_language.end}}</span> </template> 


                  </v-text-field>
                </template>
                <v-date-picker v-model="search_date_to" @input="search_menu_date_to = false, $refs.search_menu_date_to.save(search_date_to)" no-title :allowed-dates="allowedDates" class="my-0">
                </v-date-picker>
              </v-menu>
          </v-col>
          <v-col cols="12" md="6">
            <v-btn dark color="primary" @click="search_topup_history()" class="mr-3">{{use_language.search}}</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="v-card-bottom-30">
      <v-card-title>
       <v-icon size="20" color="success">fa-receipt</v-icon><span class="py-2 ml-3">{{use_language.payment_notification_history}}</span>
       <v-spacer/>
       <v-btn class="hidden-sm-and-up" @click="open_dialog_topup(true) " dark color="primary"><v-icon left>mdi-plus</v-icon> {{use_language.payment_notification}}</v-btn>
       <v-btn class="hidden-xs-only" @click="open_dialog_topup(false) " dark color="primary"><v-icon left>mdi-plus</v-icon> {{use_language.payment_notification}}</v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text class="padding-mobile-display">
        <v-row class="hidden-xs-only">
          <v-col cols="12" v-show="this.showdataTable.length != 0">
            <v-data-table :headers="headers" :items="showdataTable" class="elevation-0 packhai-border-table" hide-default-footer :items-per-page="10000">
              <template v-slot:item.id="{ item }">
                {{ FormatAgentTopup(item.id) }}
              </template>
              <template v-slot:item.transferDate="{ item }">
                {{ set_format_date_time(item.transferDate) }}
              </template>

              <template v-slot:item.shopBankAccountId="{ item }">
                <div class="my-2">
                  <span class="mr-1">
                    <v-avatar size="25px">
                      <img :src="item.bankLogo"/>
                    </v-avatar>
                  </span>
                  <span>{{ item.bankName }}</span><br>
                  <span>{{ item.accountNumber }}</span><br>
                  <span>{{ item.accountName }}</span>
                </div>
              </template>
              <template v-slot:item.fileUrl="{ item }">
                <v-row>
                  <v-col cols="12" align="center">
                    <v-img style="margin:5px;"
                        v-if="item.fileUrl != null "
                        :src="item.fileUrl"
                        aspect-ratio="1"
                        width="50"
                        height="50"
                        @click="image_click(item.fileUrl)"
                    ></v-img>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item.Amount="{ item }">
                {{ format_price( item.amount ) }}
              </template>

              <template v-slot:item.ApproveOrReject="{ item }">
                <div v-if="item.approveDateTime != null">
                  <span>{{use_language.checked_by}} {{ ' '+item.aproveName+' ' }}</span><br>
                  <span >{{use_language.date}} {{ ' '+set_format_date_time(item.approveDateTime)+' ' }} </span>
                </div>
                <div v-else-if="item.approveDateTime == null && item.rejectName != null">
                  <span>{{use_language.rejected_by}} {{ ' '+item.rejectName+' ' }}</span><br>
                  <span >{{use_language.date}} {{ ' '+set_format_date_time(item.rejectDateTime)+' ' }}  </span>
                </div>

              </template>

              <template v-slot:item.statusName="{ item }">
                <v-row >
                  <v-col cols="12" align="left" style="margin-left: 15px;">
                    <span v-if="item.statusId == TopupStatusDict['Rejected']"><v-icon size="10" color="red">mdi-checkbox-blank-circle</v-icon> {{use_language.reject}}</span>
                    <span v-else-if="item.statusId == TopupStatusDict['WaitCheck']"><v-icon size="10" color="yellow">mdi-checkbox-blank-circle</v-icon> {{use_language.pending_review}}</span>
                    <span v-else-if="item.statusId == TopupStatusDict['Checked']"><v-icon size="10" color="success">mdi-checkbox-blank-circle</v-icon> {{use_language.verified}}</span>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item.delete="{ item }">
                <v-btn icon color="danger" @click="detete_topup(item.id, item.fileUrl)" v-if="item.statusId == TopupStatusDict['WaitCheck']"><v-icon size="18" >fa-trash-alt</v-icon></v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-card class="hidden-sm-and-up mx-0 my-5" style="border-radius: 0px;" elevation="0" v-for="(item, index) in showdataTable" :key="index">
          <v-row class="pa-1 mx-0 pb-5">
            <v-col cols="8" align="left">
              <span v-if="item.statusId == TopupStatusDict['Rejected']"><v-icon size="10" color="red">mdi-checkbox-blank-circle</v-icon> {{use_language.reject}}</span>
              <span v-else-if="item.statusId == TopupStatusDict['WaitCheck']"><v-icon size="10" color="yellow">mdi-checkbox-blank-circle</v-icon> {{use_language.pending_review}}</span>
              <span v-else-if="item.statusId == TopupStatusDict['Checked']"><v-icon size="10" color="success">mdi-checkbox-blank-circle</v-icon> {{use_language.verified}}</span>
            </v-col>
            <v-col cols="4" align="right" v-if="item.fileUrl != null"><a @click="image_click(item.fileUrl)">{{use_language.see_slip}}</a></v-col>
          </v-row>
          <v-divider/>
          <v-row class="pa-1 mx-0">
            <v-col cols="12">
              <v-row class="mx-0 px-0 pt-3">
                <v-col class="mx-0 px-0" cols="12" align="left">
                  {{ FormatAgentTopup(item.id) }}
                </v-col>
              </v-row>
              <v-row class="mx-0 px-0">
                <v-col class="mx-0 px-0 py-10" cols="12" align="center">
                  <span><h1>฿ {{ format_price(item.amount) }}</h1></span>
                </v-col>
              </v-row>
              <v-row class="mx-0 px-0">
                <v-col class="mx-0 px-0" cols="4" align="left">
                  {{use_language.bank}} :
                </v-col>
                <v-col class="mx-0 px-0" cols="8" align="right">
                  <span class="mr-1">
                    <v-avatar size="25px">
                      <img :src="item.bankLogo"/>
                    </v-avatar>
                  </span>
                  <span>{{ item.bankName }}</span><br>
                  <span>{{ item.accountNumber }}</span><br>
                  <span>{{ item.accountName }}</span>
                </v-col>
              </v-row>
              <v-row class="mx-0 px-0">
                <v-col class="mx-0 px-0" cols="4" align="left">
                  {{use_language.transfer_date}} :
                </v-col>
                <v-col class="mx-0 px-0" cols="8" align="right">
                  {{ set_format_date_time(item.transferDate) }}
                </v-col>
              </v-row>
              <v-row class="mx-0 px-0">
                <v-col class="mx-0 px-0" cols="4" align="left">
                  {{use_language.notification_date}} :
                </v-col>
                <v-col class="mx-0 px-0" cols="8" align="right">
                  {{ set_format_date_time(item.createDatetime) }}
                </v-col>
              </v-row>
              <v-row class="mx-0 px-0">
                <v-col class="mx-0 px-0" cols="4" align="left">
                  {{use_language.note}} :
                </v-col>
                <v-col class="mx-0 px-0" cols="8" align="right">
                  {{ item.remark }}
                </v-col>
              </v-row>
              <v-row class="mx-0 px-0" v-if="item.statusId == TopupStatusDict['Checked']">
                <v-col class="mx-0 px-0" cols="4" align="left">
                  <span>{{use_language.checked_by}} : </span>
                </v-col>
                <v-col class="mx-0 px-0" cols="8" align="right">
                  <span>{{ item.aproveName }}</span>
                </v-col>
              </v-row>
              <v-row class="mx-0 px-0" v-if="item.statusId == TopupStatusDict['Checked']">
                <v-col class="mx-0 px-0" cols="4" align="left">
                  <span>{{use_language.check_date}} : </span>
                </v-col>
                <v-col class="mx-0 px-0" cols="8" align="right">
                  <span>{{ set_format_date_time(item.approveDateTime) }}</span>
                </v-col>
              </v-row>
              <v-row class="mx-0 px-0" v-if="item.statusId == TopupStatusDict['Rejected']">
                <v-col class="mx-0 px-0" cols="4" align="left">
                  <span>{{use_language.rejected_by}} : </span>
                </v-col>
                <v-col class="mx-0 px-0" cols="8" align="right">
                  <span>{{ item.rejectName }}</span>
                </v-col>
              </v-row>
              <v-row class="mx-0 px-0" v-if="item.statusId == TopupStatusDict['Rejected']">
                <v-col class="mx-0 px-0" cols="4" align="left">
                  <span>{{use_language.rejected_date}} : </span>
                </v-col>
                <v-col class="mx-0 px-0" cols="8" align="right">
                  <span>{{ set_format_date_time(item.rejectDateTime) }}</span>
                </v-col>
              </v-row>
              <v-row class="mx-0 px-0">
                <v-col class="mx-0 px-0" cols="4" align="left">
                  <span>{{use_language.reference_no}} : </span>
                </v-col>
                <v-col class="mx-0 px-0" cols="8" align="right">
                  <span>{{ item.referenceNo }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>

      </v-card-text>
    </v-card>

    <v-dialog v-model="topup_dialog" scrollable :max-width="size_percent_for_mobile" persistent >
      <v-card class="my-0">
        <v-card-title >
          <span>{{use_language.transfer_notice}}</span>
          <v-spacer></v-spacer>
          <v-btn icon  @click="topup_dialog = false, clear_data(),  data_and_time_onload(), resetValidForm()"><v-icon color="danger">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row  row>
              <v-col class="pb-0" cols="12" md="2">{{use_language.bank}} :</v-col>
              <v-col class="pb-0" cols="12" md="10">
                <v-select
                  ref="ShopBankAccountID"
                  :items="bank_list"
                  v-model="ShopBankAccountID"
                  item-key="fullBankName"
                  item-value="id"
                  style="max-width: 500px;"
                  hide-details
                  dense
                  outlined
                >
                  <template slot="selection" slot-scope="data">
                    <v-col cols="1" class='px-0' v-if="data.item.logo != null">
                      <v-avatar size="25px" >
                        <img :src="data.item.logo"/>
                      </v-avatar>
                    </v-col>
                    <v-col cols="11" class='mx-0'>
                      {{ data.item.fullBankName }}
                    </v-col>
                  </template>
                  <template slot="item" slot-scope="data">
                    <v-avatar size="25px" v-if="data.item.logo != null">
                      <img  :src="data.item.logo" />
                    </v-avatar>
                    <v-col class='ml-3'>
                      {{ data.item.fullBankName }}
                    </v-col>
                  </template>
                </v-select>
                <span v-if="this.BankValid == true && this.ShopBankAccountID == null" style="color: red;">{{ this.BankValidText }}</span>
              </v-col>
            </v-row>
            <v-row  row>
              <v-col class="pb-0" cols="12" md="2">{{use_language.transfer_amount_}} :</v-col>
              <v-col class="pb-0" cols="12" md="10">
                <v-text-field
                  ref="Amount"
                  :value="Amount"
                  @change="value => Amount = value"
                  autocomplete="off"
                  outlined
                  type="number"
                  :rules="AmountRules"
                  required
                  hide-details
                  dense
                  style="width: 250px"
                ></v-text-field>
                <span v-if="this.AmountValid == true && (this.Amount == null || this.Amount == '') " style="color: red;">{{ this.AmountValidText }}</span>
              </v-col>
            </v-row>
            <v-row  row>
              <v-col class="pb-0" cols="12" md="2">{{use_language.date}} :</v-col>
              <v-col class="pb-0" cols="12" md="10">
                <v-menu
                    ref="menu_date_transfer"
                    v-model="menu_date_transfer"
                    :close-on-content-click="false"
                    :return-value.sync="date_transfer"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                          :value="computed_date_transfer"
                          @change="value => computed_date_transfer = value"
                          autocomplete="off"
                          prepend-inner-icon="mdi-calendar"
                          ref="dateRangeText"
                          outlined readonly
                          v-on="on"
                          style="width: 250px"
                          hide-details
                          dense
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date_transfer" no-title scrollable :allowed-dates="allowedDates">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="date_transfer = null">{{use_language.reset}}</v-btn>
                      <v-btn text color="primary" @click="menu_date_transfer = false">{{use_language.cancel_1}}</v-btn>
                      <v-btn text color="primary" @click="$refs.menu_date_transfer.save(date_transfer)">{{use_language.ok}}</v-btn>
                    </v-date-picker>
                  </v-menu>
              </v-col>
            </v-row>
            <v-row  row>
              <v-col class="pb-0" cols="12" md="2">{{use_language.approximate_time}} :</v-col>
              <v-col class="pb-0" cols="12" md="10">
                <v-menu
                  ref="time_picker"
                  v-model="time_picker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="time"
                      @change="value => time = value"
                      autocomplete="off"
                      prepend-inner-icon="mdi-clock"
                      outlined readonly
                      style="width: 250px"
                      v-on="on"
                      hide-details
                      dense
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="time_picker"
                    v-model="time"
                    format ="24hr"
                    full-width
                    @click:minute="$refs.time_picker.save(time)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row  row>
              <v-col class="pb-0" cols="12" md="2">{{use_language.attach_file}} :</v-col>
              <v-col class="pb-0" cols="12" md="10">
                <v-row row>
                  <v-col class="pb-0" cols="12">
                    <v-btn onclick="document.getElementById('fileInput').click()" outlined color="primary">{{use_language.attach_file}}</v-btn>
                    <v-btn v-if="myFile != null" icon color="danger" @click="myFile = null"><v-icon>mdi-close</v-icon></v-btn>
                    <v-file-input id="fileInput" v-model="myFile" accept="image/*" @change="set_data_file()" style="display: none;" />
                  </v-col>
                </v-row>
                <v-row row>
                  <v-col class="pb-0" cols="6" md="2">
                    <v-img class="mt-3 title mb-1" v-if="this.imglogoPre != null && this.myFile != null" :src="imglogoPre" ></v-img>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row  row>
              <v-col class="pb-0" cols="12" md="2">{{use_language.note}} :</v-col>
              <v-col class="pb-0" cols="12" md="10">
                <v-textarea
                  ref="Remark"
                  v-model="Remark"
                  outlined
                  name="input-7-4"
                  style="max-width: 500px;"
                  hide-details
                  dense
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider/>
        <v-card-actions >
          <v-col class="py-1" cols="12" align="right">
            <v-btn color="danger" dark @click="createTopupOrder()">{{use_language.payment_notification}}</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_photo" width="600">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
                <v-img :src="dialog_photo_link" aspect-ratio="1.3" contain></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import Loading from '@/website/components/Loading'
  import axios from 'axios'
  import Swal from 'sweetalert2'
  import { shopService_dotnet, agentService_dotnet } from '@/website/global'
  import { format_number, format_order_id, format_price, FormatAgentTopup, set_format_date_time,get_languages } from '@/website/global_function'
 
  import { agent_language } from "@/website/language/agent_language";

  export default {
    components: {
        Loading
    },
    data: () => ({
      header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
      page_loading: true,
      loading: false,

      use_language: null,
      set_language: null,



      TopupStatusDict: {
        'WaitCheck': 1,
        'Checked': 2,
        'Rejected': 3,
      },

      topupDialog: false,
      transferMoney: '',
      transferDate: '',
      transferHour: new Date().getHours(),
      transferMin: new Date().getMinutes(),
      showPicker: false,

      search_menu_date_from: false,
      search_date_from: null,
      search_menu_date_to: false,
      search_date_to: null,


      menu_date_transfer: false,
      date_transfer: null,

      // time picker
      time: null,
      time_picker: false,

      shop_id: null,
      Amount: null,
      TransferDate: null,
      ShopBankAccountID: null,
      File_url: null,
      Remark: null,

      // dialog_photo
      dialog_photo: false,
      dialog_photo_link: null,

      // ธนาคาร
      bank_list: [],

      // upload file
      myFile: null,
      myFile_insert : null,
      path_name: null,
      path_name_del: null,
      imglogoPre: null,

      // dialog topup
      topup_dialog: false,
      size_percent_for_mobile: '45%',

      hour: [{text: '1', value: 1}, {text: '2', value: 2}, {text: '3', value: 3}],
      minit: [{text: '01', value: 1}, {text: '02', value: 2}, {text: '03', value: 3}],

      item1: [{text: 'test1', value: 1}, {text: 'test2', value: 2}, {text: 'test3', value: 3}],

      headers:[
        { text: 'เลขที่รายการ', value: 'id','width': '125px', 'align': 'center' ,sortable: false },
        { text: 'ชำระเวลา', align: 'left', sortable: false, value: 'transferDate', width:'170px' },
        { text: 'ช่องทาง', align: 'left', sortable: false, value: 'shopBankAccountId',  width:'300px' },
        { text: 'สลิป', align: 'center', value: 'fileUrl', sortable: false },
        { text: 'ยอด(บาท)', align: 'right', value: 'amount', sortable: false,  width:'120px' },
        { text: 'หมายเหตุ', align: 'left', value: 'remark', sortable: false, width:'300px' },
        { text: 'ตรวจสอบ/ปฏิเสธ', value: 'ApproveOrReject', sortable: false, 'width': '300px', align: 'left' },
        { text: 'สถานะ', align: 'center', value: 'statusName', sortable: false,  width:'150px' },
        { text: 'ลบ', align: 'center', value: 'delete', sortable: false },
      ],
      showdataTable: [],

      // validate
      valid: true,
      AmountRules: [
        v => !!v || 'กรุณากรอก ยอดที่โอน',
      ],

      AmountValid: false,
      AmountValidText: false,
      BankValid: false,
      BankValidText: false,

      data_shop: [],

    }),
    computed: {
      computed_search_date_from() {
        return this.formatDates(this.search_date_from)
      },

      computed_search_date_to() {
        return this.formatDates(this.search_date_to)
      },

      computed_date_transfer() {
        return this.formatDateTransfer(this.date_transfer)
      },
    },
    watch: {
    },
    async created() {

      ///เก็บภาษามาไว้ใน use_language
         this.use_language = await this.get_languages(localStorage.getItem("set_language"));



      await this.get_data_shop()
      await this.get_bank_accounts()
      await this.search_topup_history()


      // await this.get_bank_account()
      // await this.search_topup_history()
      if(this.bank_list.length != 0) { this.ShopBankAccountID = this.bank_list[0]['id'] }
      this.data_and_time_onload ()
      this.page_loading = false

    },
    methods: {
      format_number, format_order_id, format_price, FormatAgentTopup, set_format_date_time,get_languages,
      async  set_data_file() {
         if(this.myFile != null){
           if(this.myFile['type']=="image/png" || this.myFile['type']=="image/jpeg"){
             var url = URL.createObjectURL(this.myFile)
             this.imglogoPre = url
             var nowDate = new Date().toISOString()
             var s3_url = 'https://packhaistorage.s3-ap-southeast-1.amazonaws.com/Shop/'
             this.myFile_insert = s3_url + 'Shop' + localStorage.getItem('shop_id') + '_slip_' + nowDate
             this.path_name = 'Shop' + localStorage.getItem('shop_id') + '_slip_' + nowDate
           }


           var fileToBase64 = null
           const toBase64 = file => new Promise((resolve, reject) => {
               const reader = new FileReader()
               reader.readAsDataURL(file)
               reader.onload = () => resolve(reader.result)
               reader.onerror = error => reject(error)
           })

           if (this.myFile != null) {
             fileToBase64 = await toBase64(this.myFile)
           } else {
             fileToBase64 = null
           }

           this.File_url = fileToBase64

         }


      },

      async get_data_shop () {
        let response = await axios.post(agentService_dotnet+'AgentFinance/agent-get-data-shop',{
           'shopID': localStorage.getItem('shop_id')
         } , { headers: this.header_token})
         this.data_shop = response.data
      },

      async get_bank_accounts () {
        let response = await axios.post(agentService_dotnet+'AgentFinance/agent-get-vshop-bank-list',{
           "shopID": this.data_shop.masterShopId
         } , { headers: this.header_token})
         this.bank_list = response.data
         this.bank_list.splice(0, 0, {
              id: null,
              fullBankName: '--- กรุณาเลือกธนาคาร ---',
              bankNameTH: "--- กรุณาเลือกธนาคาร ---",
              logo: null,
         });
      },


      validate () {
        this.$refs.form.validate()
      },

      resetValidForm () {
        this.$refs.form.resetValidation()
        this.AmountValid = false
        this.AmountValidText = null
        this.BankValid = false
        this.BankValidText = null
      },

      allowedDates: val => val <= new Date().toISOString().substr(0, 10),

      formatDate (date) {

        if (date.length == 0) return null

        if (date.length == 1) {
          const [year, month, day] = date[0].split('-')
          return `${day}-${month}-${year}`
        } else {
          const [year_from, month_from, day_from] = date[0].split('-')
          const [year_to, month_to, day_to] = date[1].split('-')
          if (date[0] < date[1]) {
            return `${day_from}-${month_from}-${year_from} ~ ${day_to}-${month_to}-${year_to}`
          } else {
            return `${day_to}-${month_to}-${year_to} ~ ${day_from}-${month_from}-${year_from}`
          }
        }
      },

      formatDates (date) {
        if (date == null){
          return null
        } else {
          const [year, month, day] = date.split('-')
          return `${day}-${month}-${year}`
        }
      },

      formatDateTransfer (date) {

        if (date == null) return null

        if (date != null) {
          const [year, month, day] = date.split('-')
          return `${day}-${month}-${year}`
        }
      },

      edit_date_format_for_dotnet ( val ){
        const _date = new Date(val)
        var informDatetime_tmp = new Date( _date.getTime() + 7 * (60 * 60 * 1000) ).toISOString()
        return informDatetime_tmp
      },

      data_and_time_onload () {
        this.date_transfer = new Date().toISOString().substr(0,10)
        this.time = Number(new Date().toISOString().substr(11,2))+7 +':'+new Date().toISOString().substr(14,2)
      },

      open_dialog_topup (isFullscreen) {
        this.topup_dialog = true
        this.isFullscreen = isFullscreen
        if(isFullscreen == true){
          this.size_percent_for_mobile = '95%'
        }else {
          this.size_percent_for_mobile = '45%'
        }
      },

      async createTopupOrder() {
        if (this.ShopBankAccountID == null) {
          this.BankValid = true
          this.BankValidText = 'กรุณาเลือกธนาคาร !!'
          this.$refs.ShopBankAccountID.focus()
        } else if (this.Amount == null || this.$refs.form.validate() == false) {
          this.AmountValid = true
          this.AmountValidText = 'กรุณากรอกยอดที่โอน !!'
          this.$refs.form.validate()
          this.$refs.Amount.focus()
        } else if (this.date_transfer == null) {
          console.log('กรุณากรอก วันที่');
        } else if (this.time == null) {
          console.log('กรุณากรอก เวลา');
        } else {
          this.TransferDate = this.date_transfer + ' ' + this.time + ':00'
          // this.loading = true

          let response = await axios.post(agentService_dotnet+'AgentFinance/agent-shop-insert-topup',{
              "masterShopID": this.data_shop.masterShopId,
              "shopId": localStorage.getItem('shop_id'),
              "amount": this.Amount,
              "transferDate":  this.edit_date_format_for_dotnet(new Date(this.TransferDate).toISOString()),
              "shopBankAccountId": this.ShopBankAccountID,
              "fileUrl": this.File_url,
              "remark": "this.Remark"
           } , { headers: this.header_token})

           this.clear_data()
           this.data_and_time_onload()
           this.search_date_from = null
           this.search_date_to = null
           await this.search_topup_history()
           this.topup_dialog = false
           Swal.fire({
             toast: true,
             timer: 1500,
             showConfirmButton: false,
             position: 'top',
             type: 'success',
             title: 'ส่งแจ้งเติมเงินเรียบร้อย'
           })
           this.resetValidForm()
           this.loading = false
           this.topupDialog = false

        }

      },

      async search_topup_history () {

        var search_date_from = this.search_date_from
        var search_date_to = this.search_date_to

        if(search_date_from != null) {
          search_date_from = this.edit_date_format_for_dotnet(new Date(search_date_from).toISOString())
        }

        if(search_date_to != null) {
          search_date_to = this.edit_date_format_for_dotnet(new Date(search_date_to).toISOString())
        }

        this.loading = true
        let response = await axios.post(agentService_dotnet+'AgentFinance/agent-get-shop-topup-list',{
           "shopId": localStorage.getItem('shop_id'),
           "masterShopId": this.data_shop.masterShopId,
           "dataFrom": search_date_from ,
           "dateTo": search_date_to ,
           "statusId": null,
           "isAscending": true,
           "isMaster": false,
         } , { headers: this.header_token})

         if(response.data.topup_list != null){
           this.showdataTable = response.data.topup_list
         } else {
           this.showdataTable = []
         }

         if(response.data.date_return != null){
           this.search_date_from = response.data.date_return[0]
           this.search_date_to = response.data.date_return[1]
         } else {
           var nowDate = new Date()
           this.search_date_to = nowDate.toISOString().substr(0, 10)
           nowDate.setDate(nowDate.getDate()-7)
           this.search_date_from = nowDate.toISOString().substr(0, 10)
         }

         this.loading = false
      },


      SearchingClearData () {

        var nowDate = new Date()
        this.search_date_to = nowDate.toISOString().substr(0, 10)
        nowDate.setDate(nowDate.getDate()-7)
        this.search_date_from = nowDate.toISOString().substr(0, 10)
      },

      clear_data () {
        this.Amount = null
        this.date_transfer = null
        this.time = null
        this.TransferDate = null
        this.ShopBankAccountID = this.bank_list[0]['id']
        this.File_url = null
        this.myFile_insert = null
        this.Remark = null
        this.path_name = null
        this.myFile_insert = null
        this.myFile = null
      },

      detete_topup (topup_id, File_url) {
          if(File_url != null){
            this.path_name_del = File_url.substr(60)
          }
          Swal.fire({
            position: "top",
            type: 'warning',
            title: 'ต้องการลบ ใช่หรือไม่!!',
            // text: this.myKeyword.textD1[this.language]+' '+ name +' '+ this.myKeyword.textD2[this.language],
            confirmButtonText: 'ยืนยัน',
            cancelButtonText: 'ยกเลิก',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            allowOutsideClick: false
          }).then(async(result) => {
            if (result.value) {
              this.loading = true
              let response = await axios.post(agentService_dotnet+'AgentFinance/agent-shop-delete-topup',{
                  "topupID": topup_id
               } , { headers: this.header_token})
               Swal.fire({
                 toast: true,
                 timer: 1500,
                 showConfirmButton: false,
                 position: 'top',
                 type: 'success',
                 title: 'ลบเรียบร้อย'
               })
               await this.search_topup_history()
            }
          })

      },

      image_click(photo_link) {
          this.dialog_photo_link = photo_link
          this.dialog_photo = true
      },
    }
  }
</script>
<style>
  .packhai-border-table{
    /* border:1px solid #e9edf4; */
    border-collapse: collapse;
    border-spacing: 0px;
    border-right: 1px solid #e9edf4;
    border-bottom: 1px solid #e9edf4;
    border-top: 1px solid #e9edf4;
  }
  .packhai-border-table thead{
    color:white;
  }
  .packhai-border-table thead tr th{
    color: black !important;
    font-weight: bold;
    font-size: 14px;
  }
  .packhai-border-table tr {
    /* border-collapse: collapse; */
    /* border: 1px solid #e9edf4; */
  }
  .packhai-border-table th {
    /* border-collapse: collapse; */
      border-left: 1px solid #e9edf4;
  }
  .packhai-border-table tr td{
    border-left: 1px solid #e9edf4;
  }

  /* จอเล็กไม่มี margin */
    .padding-mobile-display {
      padding-left: 17.5px !important;
      padding-right: 17.5px !important;
    }

    .padding-right-pc-0 {
      padding-right: 0px !important;
      padding-left: 0px !important;
    }

    @media (max-width: 599px) {
      .padding-mobile-display {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        background: #f0f0f0;
      }

      .background-mobile {
        background: #FFF;
      }

      .padding-right-pc-0 {
        padding-right: 15px !important;
        padding-left: 15px !important;
      }
    }


</style>
