import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.page_loading)?_c('div',[_c('loading',{attrs:{"page_loading":_vm.page_loading}})],1):_c('div',[_c('loading',{attrs:{"loading":_vm.loading}}),_c(VCard,{staticClass:"v-card-bottom-30",attrs:{"elevation":"1"}},[_c(VCardTitle,{staticStyle:{"height":"60px"}},[_c('h4',[_vm._v("COD WALLET")]),_vm._v(" "),_c(VIcon,[_vm._v("mdi-chevron-right")]),_vm._v(" "),_c('span',{staticClass:"font-weight-regular body-1"},[_vm._v(" ข้อมูล COD ")]),_c(VSpacer),_c('vue-excel-xlsx',{attrs:{"data":_vm.datalist,"columns":_vm.excel_wallet_detail_header,"filename":'รายงานรายละเอียด cod wallet',"sheetname":'รายงานรายละเอียด cod wallet'}},[_c(VBtn,{attrs:{"color":"success","outlined":""}},[_vm._v("Export to Excel")])],1)],1)],1),_c(VCard,{staticClass:"elevation-0 v-card-bottom-30"},[_c(VCardTitle,[_c('font',{staticClass:"hidden-xs-only"},[_vm._v("ข้อมูล "+_vm._s(_vm.codWalletID)+" (เวลาออกใบเสร็จ "+_vm._s(_vm.codWalletDate)+")")]),_c('font',{staticClass:"hidden-sm-and-up"},[_vm._v("ข้อมูล "+_vm._s(_vm.codWalletID)),_c('br'),_vm._v("(เวลาออกใบเสร็จ "+_vm._s(_vm.codWalletDate)+")")])],1),_c(VCardText,[_c(VDataTable,{staticClass:"elevation-0 packhai-border-table hidden-xs-only",attrs:{"headers":_vm.headers,"items":_vm.datalist,"hide-default-footer":"","items-per-page":10000,"item-key":"orderID"},scopedSlots:_vm._u([{key:"item.orderID",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{staticStyle:{"text-decoration":"underline"},attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.detail_page(item.token)}}},[_vm._v(_vm._s(item.orderID))])]}},{key:"item.expressCompanyLogo",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"align":"center"}},[_c(VImg,{staticClass:"grey lighten-2",staticStyle:{"margin":"5px"},attrs:{"src":item.expressCompanyLogo,"max-width":"50","max-height":"50"}})],1)]}}])}),_c('div',[_c(VSimpleTable,{staticClass:"hidden-sm-and-up packhai-border-table",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",staticStyle:{"min-width":"100px"}},[_vm._v(" ออเดอร์ ")]),_c('th',{staticClass:"text-left",staticStyle:{"min-width":"170px"}},[_vm._v(" ส่งให้ขนส่ง ")]),_c('th',{staticClass:"text-center",staticStyle:{"min-width":"100px"}},[_vm._v(" ขนส่ง ")]),_c('th',{staticClass:"text-left",staticStyle:{"min-width":"100px"}},[_vm._v(" เลขแทรค ")]),_c('th',{staticClass:"text-left",staticStyle:{"min-width":"250px"}},[_vm._v(" ชื่อผู้รับ ")]),_c('th',{staticClass:"text-right",staticStyle:{"min-width":"120px"}},[_vm._v(" ยอด COD ")])])]),_c('tbody',_vm._l((_vm.datalist),function(item,index){return _c('tr',{key:index},[_c('td',{staticClass:"text-left"},[_c(VBtn,{staticStyle:{"text-decoration":"underline"},attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.detail_page(item.token)}}},[_vm._v(_vm._s(item.orderID))])],1),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.sendToExpressDatetime))]),_c('td',{staticClass:"text-center"},[_c(VImg,{staticClass:"grey lighten-2",staticStyle:{"margin":"5px"},attrs:{"src":item.expressCompanyLogo,"max-width":"50","max-height":"50"}})],1),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.trackingNo))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.receiverName))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(item.codAmount))])])}),0)]},proxy:true}])}),_c('div',{staticClass:"my-2",attrs:{"align":"right"}},[_vm._v(" รวม "+_vm._s(this.sun_CODAmount)+" บาท ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }