<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <v-card elevation="1" class="v-card-bottom-30 mb-10">
      <v-card-title  style="height: 60px;">
        <h4>{{use_language.finance}}</h4> <v-icon>mdi-chevron-right</v-icon><span class="font-weight-regular body-1">{{use_language.list_of_branches_to_transfer_money_to_maste}}</span>
      </v-card-title>
    </v-card>

    <v-card class="v-card-bottom-30  mb-10">
      <v-card-title>
        <v-icon size="20" color="primary">fa-sliders-h</v-icon><span class="my-3 ml-3">{{use_language.searchList}}</span>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="1" class="pt-2">{{use_language.status}} : </v-col>
          <v-col cols="12" md="4" class="pt-2">
            <v-radio-group v-model="radioStatus" row dense>
              <v-radio
                label="ทั้งหมด"
                value="null"
              >
              <template v-slot:label> <span>{{use_language.all_}}</span> </template> 
              </v-radio>
              <v-radio
                label="รอตรวจสอบ"
                :value="TopupStatusDict['WaitCheck']"
              >
              <template v-slot:label> <span>{{use_language.pending_review}}</span> </template> 
              </v-radio>
              <v-radio
                label="ตรวจสอบแล้ว"
                :value="TopupStatusDict['Checked']"
              >
              <template v-slot:label> <span>{{use_language.verified}}</span> </template> 
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row v-show="radioStatus != TopupStatusDict['WaitCheck']">
          <v-col cols="12" md="1" class="pt-2">{{use_language.date}} : </v-col>
          <v-col cols="12" md="4" lg="2" class="pt-2 mb-2" style="display: flex;">
            <!-- <span class="pt-3 mr-3">เริ่ม :</span> -->
            <v-menu
              ref="menuStartDate"
              v-model="menuStartDate"
              :close-on-content-click="false"
              :return-value.sync="startDate"
              transition="scale-transition"
              offset-y
              min-width="290px"
              max-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  outlined
                  :value="startDate"
                  @change="value => startDate = value"
                  autocomplete="off"
                  label="วันที่เริ่มต้น"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-on="on"
                  hide-details
                  dense
                  style="max-width: 700px;"
                >
                  <template v-slot:label> <span>{{use_language.start_date}}</span> </template> 
                </v-text-field>
              </template>
              <v-date-picker v-model="startDate" @input="menuStartDate = false, $refs.menuStartDate.save(startDate)" no-title :allowed-dates="allowedDates" class="my-0">
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4" lg="2" class="pt-2 mb-2" style="display: flex;">
            <!-- <span  class="pt-3 mr-3">ถึง :</span> -->
            <v-menu
              ref="menuEndDate"
              v-model="menuEndDate"
              :close-on-content-click="false"
              :return-value.sync="endDate"
              transition="scale-transition"
              offset-y
              min-width="200px"
              max-width="300px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  outlined
                  :value="endDate"
                  @change="value => endDate = value"
                  autocomplete="off"
                  label="วันที่สิ้นสุด"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-on="on"
                  hide-details
                  dense
                  style="max-width: 700px;"
                >
                    <template v-slot:label> <span>{{use_language.end_date}}</span> </template> 
                </v-text-field>
              </template>
              <v-date-picker v-model="endDate" @input="menuEndDate = false, $refs.menuEndDate.save(endDate)" no-title :allowed-dates="allowedDates" class="my-0">
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="1" class="pt-2">สาขา : </v-col>
          <v-col cols="12" md="4" class="pt-2">
            <v-combobox
              v-model="shop_id_by_name"
              :items="shop_id_by_name_items"
              item-text="name"
              item-value="id"
              outlined
              style="min-width: 200px; max-width: 700px;"
              hide-details
              dense
            ></v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="1" class="pt-2">{{use_language.transfer_amount}} : </v-col>
          <v-col cols="12" md="4" class="pt-2" style="display: flex;">
            <v-text-field
              :value="transferAmount"
              @change="value => transferAmount = value"
              autocomplete="off"
              type="number"
              outlined
              hide-details
              dense
              style="min-width: 200px; max-width: 700px;"
            ></v-text-field>
            <!-- <span  class="pt-3 ml-3">บาท</span> -->
          </v-col>
        </v-row>
        <v-row >
          <v-col cols="0" md="1" class="pt-2">
          </v-col>
          <v-col cols="12" md="11" class="pt-2">
            <v-btn :elevation="1" @click="Searching()" color="info" class="mr-3 mt-1"><v-icon>mdi-magnify</v-icon>{{use_language.search}}</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="v-card-margin-bottom-30">
      <v-card-title >
        <v-icon size="20" color="success">fa-receipt</v-icon><span class="my-3 ml-3">{{use_language.money_transfer}}</span>
        <v-spacer/>
        <v-btn color="success" outlined v-show="requestTopup.table.length > 0" @click="export_to_excel()" >{{use_language.export_excel}}</v-btn>
        <vue-excel-xlsx
            v-show="false"
            id="export_click"
            :data="requestTopup.table"
            :columns="excel_topup_header"
             v-bind:filename='transfer_report'
             v-bind:sheetname='transfer_report'
            >
            {{use_language.export_excel}}
        </vue-excel-xlsx>
      </v-card-title>
      <v-divider/>
      <v-card-text class="padding-mobile-display">
        <!-- แสดงจอใหญ่ -->
        <v-row class="hidden-xs-only mx-0 px-0">
          <v-col cols="12" class="mx-0 px-0">
            <v-data-table
              :headers="headers"
              :key="requestTopup.key_table"
              :items="requestTopup.table"
              :footer-props="footer_props"
              item-key="id"
              hide-default-footer :items-per-page="10000"
              class="packhai-border-table packhai-table"
              >
              <template v-slot:item.id="{ item }">
                {{ FormatAgentTopup(item.id) }}
              </template>
              <template v-slot:item.transferDate="{ item }">
                {{ set_format_date_time(item.transferDate) }}
              </template>
              <template v-slot:item.createDatetime="{ item }">
                {{ set_format_date_time(item.createDatetime) }}
              </template>

              <template v-slot:item.fileUrl="{ item }">
                <v-row>
                  <v-col cols="12" align="center">
                    <v-img style="margin:5px;"
                        v-if="item.fileUrl != null"
                        :src="item.fileUrl"
                        aspect-ratio="1"
                        width="50"
                        height="50"
                        @click="image_click(item.fileUrl)"
                    ></v-img>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item.bankName="{ item }">
                <div class="my-2">
                  <span class="mr-1">
                    <v-avatar size="25px">
                      <img :src="item.bankLogo"/>
                    </v-avatar>
                  </span>
                  <span>{{ item.bankName }}</span><br>
                  <span>{{ item.accountNumber }}</span><br>
                  <span>{{ item.accountName }}</span>
                </div>
              </template>
              <template v-slot:item.ApproveOrReject="{ item }">
                <div v-if="item.statusId == 2">
                  <span>{{use_language.checked_by}} {{ ' '+item.aproveName+' ' }}</span><br>
                  <span >{{use_language.date}} {{ ' '+set_format_date_time(item.approveDateTime)+' ' }} </span>
                </div>
                <div v-else-if="item.statusId == 3">
                  <span>{{use_language.rejected_by}} {{ ' '+item.rejectName+' ' }}</span><br>
                  <span >{{use_language.date}} {{ ' '+set_format_date_time(item.rejectDateTime)+' ' }}  </span>
                </div>

              </template>
              <template v-slot:item.statusId="{ item }">
                <v-row >
                  <v-col cols="12" align="left" style="margin-left: 15px;">
                    <span v-if="item.statusId == TopupStatusDict['Rejected']"><v-icon size="10" color="red">mdi-checkbox-blank-circle</v-icon> {{use_language.reject}}</span>
                    <span v-else-if="item.statusId == TopupStatusDict['WaitCheck']"><v-icon size="10" color="yellow">mdi-checkbox-blank-circle</v-icon> {{use_language.pending_review}}</span>
                    <span v-else-if="item.statusId == TopupStatusDict['Checked']"><v-icon size="10" color="success">mdi-checkbox-blank-circle</v-icon> {{use_language.verified}}</span>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item.action="{ item }">
                <div v-if="item.statusId == TopupStatusDict['WaitCheck']">
                  <v-btn class="mx-2" outlined color="success" @click="aprove_alert(item, false)">{{use_language.checked_balance}}</v-btn>
                  <v-btn class="mx-2" outlined color="error" @click="check_reject(item)">{{use_language.reject}}</v-btn>
                </div>
                <div v-else>
                  <v-btn class="mx-2" disabled outlined  >{{use_language.checked_balance}}</v-btn>
                  <v-btn class="mx-2" disabled outlined  >{{use_language.reject}}</v-btn>
                </div>

              </template>
              <template v-slot:item.amount="{ item }">
                {{ formatMoney(item.amount) }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row class="mx-0 mt-2 background-mobile hidden-xs-only">
          <v-col class="mx-0" cols="0" md="6">
          </v-col>
          <v-col class="mx-0 px-0" cols="12" md="6">
            <v-row class="mx-0 py-1 my-1">
              <v-col class="py-1 mx-0 padding-right-pc-0" cols="7" md="9" align="right">
                <span><v-icon class="mr-1" size="10" color="red">mdi-checkbox-blank-circle</v-icon><b>{{use_language.reject}}</b> :</span>
              </v-col>
              <v-col class="py-1 mx-0 padding-right-pc-0" cols="5" md="3" align="right">
                <span>{{ formatMoney(sum_list['refuse']) }}</span><span class="mx-2">{{use_language.bath}}</span>
              </v-col>
            </v-row>
            <v-row class="mx-0 py-1 my-1">
              <v-col class="py-1 mx-0 padding-right-pc-0" cols="7"  md="9" align="right">
                <span><v-icon class="mr-1" size="10" color="yellow">mdi-checkbox-blank-circle</v-icon><b>{{use_language.pending_review}}</b> :</span>
              </v-col>
              <v-col class="py-1 mx-0 padding-right-pc-0" cols="5"  md="3" align="right">
                <span>{{ formatMoney(sum_list['waitCheck']) }}</span><span class="mx-2">{{use_language.bath}}</span>
              </v-col>
            </v-row>
            <v-row class="mx-0 py-1 my-1">
              <v-col class="py-1 mx-0 padding-right-pc-0" cols="7" md="9" align="right">
                <span><v-icon class="mr-1" size="10" color="success">mdi-checkbox-blank-circle</v-icon><b>{{use_language.verified}}</b> :</span>
              </v-col>
              <v-col class="py-1 mx-0 padding-right-pc-0" cols="5" md="3" align="right">
                <span>{{ formatMoney(sum_list['checked']) }}</span><span class="mx-2">{{use_language.bath}}</span>
              </v-col>
            </v-row>
            <v-row class="mx-0 py-1 my-1">
              <v-col class="py-1 mx-0 padding-right-pc-0" cols="7" md="9" align="right">
                <span><b>{{use_language.total_all}}</b> :</span>
              </v-col>
              <v-col class="py-1 mx-0 padding-right-pc-0" cols="5" md="3" align="right">
                <span>{{ formatMoney(sum_list['sumAmount']) }}</span><span class="mx-2">{{use_language.bath}}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- แสดงจอมือถือ -->
    <v-card class="hidden-sm-and-up mx-0 my-5 " style="border-radius: 0px;" elevation="0" v-for="(item, index) in requestTopup.table" :key="index">
      <v-row class="pa-1 mx-0 pb-3">
        <v-col cols="8" align="left">
          <span v-if="item.statusId == TopupStatusDict['Rejected']"><v-icon size="10" color="red">mdi-checkbox-blank-circle</v-icon> {{use_language.reject}}</span>
          <span v-else-if="item.statusId == TopupStatusDict['WaitCheck']"><v-icon size="10" color="yellow">mdi-checkbox-blank-circle</v-icon> {{use_language.pending_review}}</span>
          <span v-else-if="item.statusId == TopupStatusDict['Checked']"><v-icon size="10" color="success">mdi-checkbox-blank-circle</v-icon> {{use_language.verified}}</span>
        </v-col>
        <v-col cols="4" align="right" v-if="item.fileUrl != null"><a @click="image_click(item.fileUrl)">{{use_language.see_slip}}</a></v-col>
      </v-row>
      <v-divider/>
      <v-row class="pa-1  mx-0">
        <v-col cols="12">
          <v-row class="mx-0 px-0 ">
            <v-col class="mx-0 px-0" cols="12" align="left">
              {{ item.name }}
            </v-col>
          </v-row>
          <v-row class="mx-0 px-0 ">
            <v-col class="mx-0 px-0 py-10" cols="12" align="center">
              <span><h1>฿ {{ formatMoney(item.amount) }}</h1></span>
            </v-col>
          </v-row>
          <v-row class="mx-0 px-0 ">
            <v-col class="mx-0 px-0" cols="4" align="left">
              {{use_language.item_number}} :
            </v-col>
            <v-col class="mx-0 px-0" cols="8" align="right">
              {{ FormatAgentTopup(item.id) }}
            </v-col>
          </v-row>
          <v-row class="mx-0 px-0 ">
            <v-col class="mx-0 px-0" cols="4" align="left">
              {{use_language.bank}} :
            </v-col>
            <v-col class="mx-0 px-0" cols="8" align="right">
              <span class="mr-1">
                <v-avatar size="25px">
                  <img :src="item.bankLogo"/>
                </v-avatar>
              </span>
              <span>{{ item.bankName }}</span><br>
              <span>{{ item.accountNumber }}</span><br>
              <span>{{ item.accountName }}</span>
            </v-col>
          </v-row>
          <v-row class="mx-0 px-0 ">
            <v-col class="mx-0 px-0" cols="4" align="left">
              {{use_language.transfer_date}} :
            </v-col>
            <v-col class="mx-0 px-0" cols="8" align="right">
              {{ set_format_date_time(item.transferDate) }}
            </v-col>
          </v-row>
          <v-row class="mx-0 px-0 ">
            <v-col class="mx-0 px-0" cols="4" align="left">
              {{use_language.notification_date}} :
            </v-col>
            <v-col class="mx-0 px-0" cols="8" align="right">
              {{ set_format_date_time(item.createDatetime) }}
            </v-col>
          </v-row>
          <v-row class="mx-0 px-0 ">
            <v-col class="mx-0 px-0" cols="4" align="left">
              {{use_language.note}} :
            </v-col>
            <v-col class="mx-0 px-0" cols="8" align="right">
              {{ item.remark }}
            </v-col>
          </v-row>
          <v-row class="mx-0 px-0 " v-if="item.statusId == TopupStatusDict['Checked']">
            <v-col class="mx-0 px-0" cols="4" align="left">
              <span>{{use_language.checked_by}} : </span>
            </v-col>
            <v-col class="mx-0 px-0" cols="8" align="right">
              <span>{{ item.aproveName }}</span>
            </v-col>
          </v-row>
          <v-row class="mx-0 px-0 " v-if="item.statusId == TopupStatusDict['Checked']">
            <v-col class="mx-0 px-0" cols="4" align="left">
              <span>{{use_language.check_date}} : </span>
            </v-col>
            <v-col class="mx-0 px-0" cols="8" align="right">
              <span>{{ set_format_date_time(item.approveDateTime) }}</span>
            </v-col>
          </v-row>
          <v-row class="mx-0 px-0 " v-if="item.statusId == TopupStatusDict['Rejected']">
            <v-col class="mx-0 px-0" cols="4" align="left">
              <span>{{use_language.rejected_by}} : </span>
            </v-col>
            <v-col class="mx-0 px-0" cols="8" align="right">
              <span>{{ item.rejectName }}</span>
            </v-col>
          </v-row>
          <v-row class="mx-0 px-0 " v-if="item.statusId == TopupStatusDict['Rejected']">
            <v-col class="mx-0 px-0" cols="4" align="left">
              <span>{{use_language.rejected_date}} : </span>
            </v-col>
            <v-col class="mx-0 px-0" cols="8" align="right">
              <span>{{ set_format_date_time(item.rejectDateTime) }}</span>
            </v-col>
          </v-row>
          <v-row class="mx-0 px-0 ">
            <v-col class="mx-0 px-0" cols="4" align="left">
              <span>{{use_language.reference_no}}: </span>
            </v-col>
            <v-col class="mx-0 px-0" cols="8" align="right">
              <span>{{ item.referenceNo }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider/>
      <v-row  v-show="item.statusId == TopupStatusDict['WaitCheck']" align="center">
        <v-col class="mt-3" cols="6" align="center" style="border-right : 1px solid rgba(0, 0, 0, 0.12); height: 50px;"><v-btn text width="100%" color="success" @click="aprove_alert(item, true)" >{{use_language.checked_balance}}</v-btn></v-col>
        <v-col class="mt-3" cols="6" align="center" style="height: 50px;" ><v-btn text width="100%" color="error" @click="check_reject(item)" >{{use_language.reject}} </v-btn></v-col>
      </v-row>
    </v-card>
    <v-row class="mx-0 mt-2 background-mobile hidden-sm-and-up " style="background: #fff">
      <v-col class="mx-0" cols="0" md="6">
      </v-col>
      <v-col class="mx-0 px-0" cols="12" md="6">
        <v-row class="mx-0 py-1 my-1">
          <v-col class="py-1 mx-0 padding-right-pc-0" cols="7" md="9" align="right">
            <span><v-icon class="mr-1" size="10" color="red">mdi-checkbox-blank-circle</v-icon><b>{{use_language.reject}}</b> :</span>
          </v-col>
          <v-col class="py-1 mx-0 padding-right-pc-0" cols="5" md="3" align="right">
            <span>{{ formatMoney(sum_list['refuse']) }}</span><span class="mx-2">{{use_language.bath}}</span>
          </v-col>
        </v-row>
        <v-row class="mx-0 py-1 my-1">
          <v-col class="py-1 mx-0 padding-right-pc-0" cols="7"  md="9" align="right">
            <span><v-icon class="mr-1" size="10" color="yellow">mdi-checkbox-blank-circle</v-icon><b>{{use_language.pending_review}}</b> :</span>
          </v-col>
          <v-col class="py-1 mx-0 padding-right-pc-0" cols="5"  md="3" align="right">
            <span>{{ formatMoney(sum_list['waitCheck']) }}</span><span class="mx-2">{{use_language.bath}}</span>
          </v-col>
        </v-row>
        <v-row class="mx-0 py-1 my-1">
          <v-col class="py-1 mx-0 padding-right-pc-0" cols="7" md="9" align="right">
            <span><v-icon class="mr-1" size="10" color="success">mdi-checkbox-blank-circle</v-icon><b>{{use_language.verified}}</b> :</span>
          </v-col>
          <v-col class="py-1 mx-0 padding-right-pc-0" cols="5" md="3" align="right">
            <span>{{ formatMoney(sum_list['checked']) }}</span><span class="mx-2">{{use_language.bath}}</span>
          </v-col>
        </v-row>
        <v-row class="mx-0 py-1 my-1">
          <v-col class="py-1 mx-0 padding-right-pc-0" cols="7" md="9" align="right">
            <span><b>รวมทั้งหมด</b> :</span>
          </v-col>
          <v-col class="py-1 mx-0 padding-right-pc-0" cols="5" md="3" align="right">
            <span>{{ formatMoney(sum_list['sumAmount']) }}</span><span class="mx-2">{{use_language.bath}}</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>



    <v-dialog v-model="aprove_dialog" scrollable :max-width="size_percent_for_mobile" persistent >
      <v-card class="my-0">
        <v-card-title >
          <span>{{use_language.transfer_details}}</span>
          <v-spacer></v-spacer>
          <v-btn icon  @click="aprove_dialog = false, ClearDataDialog()"><v-icon color="danger">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-row row class="pt-2">
            <v-col  cols="12" md="12">
              <v-row row >
                <v-col  cols="12" md="3">
                <h4>  {{use_language.bank}} :</h4>
                </v-col>
                <v-col  cols="12" md="9">
                  <v-select
                    ref="BanksID"
                    :items="BankList"
                    v-model="BanksID"
                    item-text="bankNameTH"
                    item-value="id"
                    dense
                    hide-details
                    outlined
                  >
                    <template slot="selection" slot-scope="data">
                      <v-col cols="1" class='px-0' v-if="data.item.logo != null">
                        <v-avatar size="25px" >
                          <img :src="data.item.logo"/>
                        </v-avatar>
                      </v-col>
                      <v-col cols="11" class='mx-0'>
                        {{ data.item.bankNameTH }}
                      </v-col>
                    </template>
                    <template slot="item" slot-scope="data">
                      <v-avatar size="25px" v-if="data.item.logo != null">
                        <img  :src="data.item.logo" />
                      </v-avatar>
                      <v-col class='ml-3'>
                        {{ data.item.bankNameTH }}
                      </v-col>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row row class="pt-2">
            <v-col  cols="12" md="12">
              <v-row row >
                <v-col  cols="12" md="3">
                <h4>  {{use_language.transfer_date}} :</h4>
                </v-col>
                <v-col  cols="12" md="9">
                  <v-menu
                      ref="menu_date_transfer"
                      v-model="menu_date_transfer"
                      :close-on-content-click="false"
                      :return-value.sync="date_transfer"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                            :value="computed_date_transfer"
                            @change="value => computed_date_transfer = value"
                            autocomplete="off"
                            prepend-inner-icon="mdi-calendar"
                            ref="dateRangeText"
                            outlined readonly
                            v-on="on"
                            hide-details
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="date_transfer" no-title scrollable :allowed-dates="allowedDates">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="date_transfer = null">{{use_language.reset}}</v-btn>
                        <v-btn text color="primary" @click="menu_date_transfer = false">{{use_language.cancel_1}}</v-btn>
                        <v-btn text color="primary" @click="$refs.menu_date_transfer.save(date_transfer)">{{use_language.ok}}</v-btn>
                      </v-date-picker>
                    </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row row class="pt-2">
            <v-col  cols="12" md="12">
              <v-row row >
                <v-col  cols="12" md="3">
                  <h4>{{use_language.time}} :</h4>
                </v-col>
                <v-col  cols="12" md="9">
                  <v-menu
                    ref="time_picker"
                    v-model="time_picker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time"
                    transition="scale-transition"
                    min-width="290px"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="time"
                        @change="value => time = value"
                        autocomplete="off"
                        prepend-inner-icon="mdi-clock"
                        outlined readonly
                        v-on="on"
                        hide-details
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="time_picker"
                      v-model="time"
                      format ="24hr"
                      full-width
                      @click:minute="$refs.time_picker.save(time)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row row class="pt-2">
            <v-col  cols="12" md="12">
              <v-row row >
                <v-col  cols="12" md="3">
                  <h4>{{use_language.total_}} :</h4>
                </v-col>
                <v-col  cols="12" md="9">
                  <v-text-field
                    :value="approve_amount"
                    @change="value => approve_amount = value"
                    autocomplete="off"
                    ref="approve_amount"
                    type="number"
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row row class="pt-2">
            <v-col  cols="12" md="12">
              <v-row row >
                <v-col  cols="12" md="3">
                <h4> {{use_language.reference_no}} :</h4>
                </v-col>
                <v-col  cols="12" md="9">
                  <v-text-field
                    :value="approve_reference"
                    @change="value => approve_reference = value"
                    autocomplete="off"
                    ref="approve_reference"
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="pt-2">
            <v-col  cols="12" md="12">
              <v-row row >
                <v-col class="pb-0 my-2 hidden-xs-only" cols="12" md="12" align="right">
                  <v-btn dark color="primary" @click="check_aprove(data_aprove)" class="mr-1"><v-icon left >mdi-content-save</v-icon> {{use_language.balance_confirmation}}</v-btn>
                </v-col>
                <v-col class="pb-0 my-2 hidden-sm-and-up" cols="12" md="12" align="center">
                  <v-btn dark color="primary" @click="check_aprove(data_aprove)" class="mr-1"><v-icon left >mdi-content-save</v-icon> {{use_language.balance_confirmation}}</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_photo" width="800">
      <v-card class="pa-2" style="background: #f0f0f0;">
        <v-card-text>
          <v-row>
            <v-col cols="12">
                <v-img :src="dialog_photo_link" aspect-ratio="1.3" contain></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import Loading from '@/website/components/Loading'
import axios from 'axios'
import Swal from 'sweetalert2'
import { agentService_dotnet } from '@/website/global'
import { formatMoney, isNumberWNoDot, isNumberWithDot, FormatAgentTopup, set_format_date_time,get_languages} from '@/website/global_function'
import {  header_token } from '@/website/backend/token'
 
 
 export default {
    components: {
      Loading
    },

    computed: {
      computed_search_sent_date() {
        if(this.date_range[0] != null) {
          return this.formatDate(this.date_range)
        } else {
          return null
        }
      },
      computed_date_transfer() {
        return this.formatDateTransfer(this.date_transfer)
      },
    },

    watch: {
      radioStatus: function (val) {
        if (val != 1) {
          this.headers = this.requestTopup.headers2
        }else {
          this.headers = this.requestTopup.headers
        }
      },
    },

    data: () => ({
      page_loading: true,
      loading: false,

      use_language: null,
      set_language: null,

      TopupStatusDict: {
        'WaitCheck': 1,
        'Checked': 2,
        'Rejected': 3,
      },

      navigete: [],

      dialog_photo: false,
      dialog_photo_link: null,

      footer_props: {
         'items-per-page-options': [50],
         'items-per-page-text': null,
         'disable-items-per-page': true
      },
      headers: null,
      requestTopup: {
        'headers': [
            { text: 'จัดการ', align: 'center', value: 'action', 'width': '280px', sortable: false },
            { text: 'เลขที่รายการ', value: 'id','width': '125px', 'align': 'center' ,sortable: false },
            { text: 'วันที่โอน', value: 'transferDate','width': '165px' ,sortable: false },
            { text: 'ชื่อร้าน', value: 'shopName', 'width': '250px', sortable: false },
            { text: 'ธนาคาร', value: 'bankName', 'width': '250px', sortable: false },
            { text: 'สลิป', align: 'center', value: 'fileUrl', sortable: false },
            { text: 'จำนวนเงิน', value: 'amount', sortable: false, 'width': '130px', align: 'right' },
            { text: 'หมายเหตุ', value: 'remark', sortable: false },
            { text: 'วันที่แจ้งชำระ', value: 'createDatetime','width': '165px' ,sortable: false },
            { text: 'สถานะ', value: 'statusId', sortable: false, 'width': '160px', align: 'center' },
            // { text: 'ตรวจสอบ/ปฏิเสธ', value: 'ApproveOrReject', sortable: false, 'width': '300px', align: 'left' },
          ],
          'headers2': [
              { text: 'จัดการ', align: 'center', value: 'action', 'width': '280px', sortable: false },
              { text: 'เลขที่รายการ', value: 'id','width': '125px', 'align': 'center' ,sortable: false },
              { text: 'วันที่โอน', value: 'transferDate','width': '165px' ,sortable: false },
              { text: 'ชื่อร้าน', value: 'shopName', 'width': '250px', sortable: false },
              { text: 'ธนาคาร', value: 'bankName', 'width': '250px', sortable: false },
              { text: 'สลิป', align: 'center', value: 'fileUrl', sortable: false },
              { text: 'จำนวนเงิน', value: 'amount', sortable: false, 'width': '130px', align: 'right' },
              { text: 'หมายเหตุ', value: 'remark', sortable: false },
              { text: 'วันที่แจ้งชำระ', value: 'createDatetime','width': '165px' ,sortable: false },
              { text: 'สถานะ', value: 'statusId', sortable: false, 'width': '160px', align: 'center' },
              { text: 'Reference No', value: 'referenceNo', sortable: false, 'width': '160px', align: 'center' },
              { text: 'ตรวจสอบ/ปฏิเสธ', value: 'ApproveOrReject', sortable: false, 'width': '300px', align: 'left' },
            ],
        'table': [],
        'key_table': 0
      },

      // Searching
      radioStatus: null,
      transferAmount: null,
      shop_id_by_name: null,
      shop_id_by_name_items: null,
      sum_list: null,

      searchDataList : null,
      startDate: null,
      // startDate: new Date().toISOString().substr(0, 10),
      menuStartDate: false,

      endDate: null,
      // endDate: new Date().toISOString().substr(0, 10),
      menuEndDate: false,

      // dialog aprove_alert
      size_percent_for_mobile: '45%',
      aprove_dialog: false,
      isFullscreen : false,
      approve_transferDate: null,
      approve_amount: null,
      approve_reference: null,
      data_aprove: null,
      BanksID: null,
      BankList : null,

      // date
      menu_date_transfer: false,
      date_transfer: null,

      // time picker
      time: null,
      time_picker: false,

      // excel
      excel_topup_header : [
        { label: "เลขที่รายการ", field: "id", width: 15, dataFormat: FormatAgentTopup },
        { label: "วันที่โอน", field: "transferDate", width: 20 , dataFormat: set_format_date_time },
        { label: "ชื่อร้าน", field: "shopName", width: 30 },
        { label: "ธนาคาร", field: "fullDataBank", width: 30 },
        { label: "สลิป", field: "fileUrl", width: 35 },
        { label: "จำนวนเงิน", field: "amount", width: 10 },
        { label: "หมายเหตุ", field: "remark", width: 20 },
        { label: "วันที่แจ้งชำระ", field: "createDatetime", width: 20 , dataFormat: set_format_date_time},
        { label: "สถานะ", field: "statusName", width: 15 },
        { label: "เลขที่อ้างอิง", field: "referenceNo", width: 25 },
        { label: "ตรวจสอบ/ปฏิเสธ", field: "approveOrReject", width: 40 },
      ],

    }),

    async created() {

        //เก็บภาษามาไว้ใน use_language
         this.use_language = await this.get_languages(localStorage.getItem("set_language"));



      // document.title = this.$router.history.current.meta.title
      this.radioStatus = this.TopupStatusDict['WaitCheck']
      this.navigete = JSON.parse(localStorage.getItem('navigete'))
      // this.shop_id_by_name = {'text': '-- สาขาทั้งหมด --', 'value': null}
      // await this.setData()
      await this.get_request()
      // await this.getFirstDateAndToDate()
      // this.shop_id_by_name = this.shop_id_by_name_items[0]

      await this.get_bank_accounts()
      await this.agent_get_shop_list()
      this.page_loading = false
    },
    methods: {
      formatMoney,
      isNumberWNoDot,
      isNumberWithDot,
      FormatAgentTopup,
      set_format_date_time,
      get_languages,

      allowedDates: val => val <= new Date().toISOString().substr(0, 10),

      formatDate (date) {
        if (date.length == 0) return null

        if (date.length == 1) {
          const [year, month, day] = date[0].split('-')
          return `${day}-${month}-${year}`
        } else {
          const [year_from, month_from, day_from] = date[0].split('-')
          const [year_to, month_to, day_to] = date[1].split('-')
          if (date[0] < date[1]) {
            return `${day_from}-${month_from}-${year_from} ~ ${day_to}-${month_to}-${year_to}`
          } else {
            return `${day_to}-${month_to}-${year_to} ~ ${day_from}-${month_from}-${year_from}`
          }
        }
      },

      formatDateTransfer (date) {

        if (date == null) return null

        if (date != null) {
          const [year, month, day] = date.split('-')
          return `${day}-${month}-${year}`
        }
      },

      formatDates (date) {
        if (date == null){
          return null
        } else {
          const [year, month, day] = date.split('-')
          return `${day}-${month}-${year}`
        }
      },

      image_click(photo_link) {
          this.dialog_photo_link = photo_link
          this.dialog_photo = true
      },

      edit_date_format_for_dotnet ( val ){
        const _date = new Date(val)
        var informDatetime_tmp = new Date( _date.getTime() + 7 * (60 * 60 * 1000) ).toISOString()
        return informDatetime_tmp
      },

      setData () {
        if(this.radioStatus != this.TopupStatusDict['WaitCheck']){
          if(this.startDate != null && this.endDate != null){
            this.date_range = {"startDate": this.startDate, "endDate": this.endDate}
          }
        } else {
          this.date_range = null
        }
        // if(this.shop_id_by_name== null || this.shop_id_by_name == ''){
        //   this.shop_id_by_name = {'text': '-- ร้านค้าทั้งหมด --', 'value': null}
        // }

        if(this.transferAmount== null || this.transferAmount == ''){
          this.transferAmount =  null
        }

        this.searchDataList = {
          "radioStatus": this.radioStatus,
          "date_range": this.date_range,
          "transferAmount": this.transferAmount,
          "branch_id": this.shop_id_by_name
        }
      },

      async Searching () {
        // await this.setData()
        await this.get_request()
      },

      async get_bank_accounts () {
        let response = await axios.post(agentService_dotnet+'AgentFinance/agent-get-vshop-bank-list',{
           "shopID": localStorage.getItem('shop_id')
         } , { headers: header_token})
         this.BankList = response.data
         this.BankList.splice(0, 0, {
              id: null,
              fullBankName: '--- กรุณาเลือกธนาคาร ---',
              bankNameTH: "--- กรุณาเลือกธนาคาร ---",
              logo: null,
         });
      },

      async agent_get_shop_list() {
        let response = await axios.post(agentService_dotnet+'AgentFinance/agent-get-shop-list',{
           "masterShopId": localStorage.getItem('shop_id')
         } , { headers: header_token})
         this.shop_id_by_name = { "id": null, "name": "-- ร้านค้าหมด --", "masterShopId": null, "uplineId":null }
         this.shop_id_by_name_items = response.data
         this.shop_id_by_name_items.splice(0, 0, { "id": null, "name": "-- ร้านค้าหมด --", "masterShopId": null, "uplineId":null });

      },

      async get_request() {

        var startDate = this.startDate
        var endDate = this.endDate

        if(startDate != null) {
          startDate = this.edit_date_format_for_dotnet(new Date(startDate).toISOString())
        }

        if(endDate != null) {
          endDate = this.edit_date_format_for_dotnet(new Date(endDate).toISOString())
        }

        var shop_id_by_name = this.shop_id_by_name
        if(shop_id_by_name != null) {
          shop_id_by_name = shop_id_by_name.id
        }

        var radioStatus = this.radioStatus
        if(radioStatus == 'null'){
          radioStatus = null
        }

        if(this.transferAmount == '' ) {
          this.transferAmount = null
        }


        this.loading = true
        let response = await axios.post(agentService_dotnet+'AgentFinance/agent-get-shop-topup-list',{
           "shopId": shop_id_by_name,
           "masterShopId": localStorage.getItem('shop_id'),
           "dataFrom": startDate ,
           "dateTo": endDate ,
           "statusId": radioStatus,
           "amount": this.transferAmount,
           "isAscending": true,
           "isMaster" : true,
           "isSumStatus" : true
         } , { headers: header_token})
         if(response.data.topup_list != null){
           // this.showdataTable = response.data.topup_list
           this.requestTopup['table'] = response.data.topup_list
           this.sum_list = response.data.sum_status
         } else {
           this.requestTopup['table'] = []
           this.sum_list = {
              "checked": 0,
              "refuse": 0,
              "sumAmount": 0,
              "waitCheck": 0
           }
         }

         if(response.data.date_return != null){
           this.startDate = response.data.date_return[0]
           this.endDate = response.data.date_return[1]
         } else {
           var nowDate = new Date()
           this.endDate = nowDate.toISOString().substr(0, 10)
           nowDate.setDate(nowDate.getDate()-7)
           this.startDate = nowDate.toISOString().substr(0, 10)
         }

         this.loading = false
      },

      aprove_alert(item, isFullscreen) {
        this.aprove_dialog = true
        this.isFullscreen = isFullscreen
        if(this.isFullscreen == true){
          this.size_percent_for_mobile ='90%'
        }else {
          this.size_percent_for_mobile ='45%'
        }
        this.data_aprove = item

        // this.approve_transferDate = item['TransferDate']
        this.approve_amount = item['amount']
        this.approve_reference = item['referenceNo']
        this.BanksID = item['shopBankAccountId']

        var datetime =  item['transferDate']
        this.time = datetime.substring(11, 16)
        var dd = datetime.substring(8, 10)
        var mm = datetime.substring(5, 7)
        var yy = datetime.substring(0, 4)
        this.date_transfer = yy+'-'+mm+'-'+dd
      },

      ClearDataDialog () {
        // this.approve_transferDate = null
        this.approve_amount = null
        this.BanksID = null
        this.approve_reference = null
        this.time = null
        this.date_transfer = null
      },

      check_aprove(item) {

        var dataDialog = {
          'BanksID': this.BanksID,
          'approve_transferDate': this.date_transfer +' '+this.time+':00',
          'Amount': this.approve_amount,
          'ReferenceNo': this.approve_reference,
        }

        this.approve(item, dataDialog)
      },

      async approve(item, dataDialog) {
        this.loading = true
        let response = await axios.post(agentService_dotnet+'AgentFinance/agent-shop-approved-topup',{
           "topupID": item.id,
           "staffID": localStorage.getItem('staff_id'),
           "masterShopID": localStorage.getItem('shop_id'),
           "shopID": item.shopId,
           "amount": dataDialog.Amount,
           "referenceNo": dataDialog.ReferenceNo,
           "transferDate": this.edit_date_format_for_dotnet(new Date(dataDialog.approve_transferDate).toISOString()),
           "shopBankAccountId": dataDialog.BanksID
         } , { headers: header_token})

        this.aprove_dialog = false
        this.get_request()
        this.loading = false
      },

      check_reject(item) {
        Swal.fire({
          position: "top",
          icon: 'warning',
          title: 'ปฏิเสธยอด ' + item['amount'] + ' จากร้าน ' + item['shopName']  + ' ใช่หรือไม่!!',
          // text: 'ปฏิเสธ ใช่หรือไม่!!',
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          allowOutsideClick: false

        }).then((result) => {
          if (result.value) {
            this.reject(item)
          }
        })
      },

      async reject(item) {
        this.loading = true
        let response = await axios.post(agentService_dotnet+'AgentFinance/agent-shop-reject-topup',{
            "topupID": item.id,
            "staffID": localStorage.getItem('staff_id'),
            "masterShopID": localStorage.getItem('shop_id'),
            "shopID": item.shopId
         } , { headers: header_token})
         this.get_request()
         this.loading = false
      },

      async export_to_excel () {

        for (var i = 0; i < this.requestTopup.table.length; i++) {
          var data_tmp = this.requestTopup.table[i]
          var string_tmp = ""
           this.requestTopup.table[i]['fullDataBank'] = data_tmp.bankName + " " +data_tmp.accountNumber + " " + data_tmp.accountName

           if(data_tmp.statusId == 2) {
             string_tmp = "ตรวจสอบโดย " + data_tmp.aproveName + " วันที่ " + set_format_date_time(data_tmp.approveDateTime)
           } else if(data_tmp.statusId == 3) {
             string_tmp = "ปฎิเสธโดย " + data_tmp.rejectName + " วันที่ " + set_format_date_time(data_tmp.rejectDateTime)
           }

           this.requestTopup.table[i]['approveOrReject'] = string_tmp

        }

        // click ปุ่ม export excel
        document.getElementById("export_click").click()
      },

    }
  }
</script>
