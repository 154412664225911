<template>
  <div v-if="page_loading">
      <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />
    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title style="height: 60px;">
        <h4>{{use_language.finance_1}}</h4> <v-icon>mdi-chevron-right</v-icon> <span class="font-weight-regular body-1" > <a href="wallet" style="text-decoration: none; color: black ;">{{use_language.balance_list}}e</a>  </span><v-icon>mdi-chevron-right</v-icon>  <span class="font-weight-regular body-1"> {{use_language.invoice_information}} </span>

        <v-spacer/>

        <vue-excel-xlsx
            v-if="datalist.length > 0 && ch_InvoiceGroupID != null"
            :data="datalist_for_excel"
            :columns="excel_detail_invoice_list_header"
            v-bind:filename='invoice_details_report'
            v-bind:sheetname='invoice_details_report'
            >
            <v-btn color="success" outlined >{{use_language.export_excel}}</v-btn>
        </vue-excel-xlsx>
      </v-card-title>
    </v-card>
    <v-card class="elevation-0 v-card-bottom-30">
      <v-card-title>
        <font v-if="this.datalist.length>0">{{use_language.invoice_information}} : {{ sh_invoiceNo }}</font>
       <!-- <font class="hidden-xs-only" v-if="this.datalist.length>0">ข้อมูลใบแจ้งหนี้ : {{ sh_invoiceNo }} (เวลาออกใบเสร็จ {{this.datalist[0]['IV_CreatedDatetime']}})</font>
       <font class="hidden-sm-and-up" v-if="this.datalist.length>0">ข้อมูลใบแจ้งหนี้ : {{ sh_invoiceNo }}<br>(เวลาออกใบเสร็จ {{this.datalist[0]['IV_CreatedDatetime']}})</font> -->
      </v-card-title>
      <v-card-text>
        <!-- PC -->
        <!-- hide-default-footer
        :items-per-page="20000" -->
        <div style="overflow-x : auto;" v-if="ch_InvoiceGroupID != null">
          <v-data-table
            :headers="headers"
            key="id"
            :items="datalist"
            item-key="orderID"
            class="elevation-0 packhai-border-table"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus'
            }"
            :items-per-page="15"
            :mobile-breakpoint="0"
            >
            <template v-slot:item.num="{ item }">
              <font v-if="item['orderID'] != 'รวมยอด'">{{ datalist.indexOf(item) +1 }}</font>
            </template>
            <template v-slot:item.orderID="{ item }">
              <!-- <v-btn color="primary" text @click="detail_page(item.token)">{{ item['orderID'] }}</v-btn> -->
              <a href="#" v-if="item['orderID'] != 'รวมยอด'" @click="detail_page(item.token)"> {{ item['orderID'] }}</a>
              <span v-else>{{ item['orderID'] }}</span>
            </template>
            <template v-slot:item.expressCompanyLogo="{ item }">
              <v-row class="pa-0 ma-0" align="center">
                <v-col class="pa-0 ma-0"  align="center">
                  <v-img :src="item.expressCompanyLogo == null ? '' : item.expressCompanyLogo" width="50" height="auto" ></v-img>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
          <v-row justify="end" v-if="data_group_detail != null">
              <v-col lg="5">
                  <v-row class="py-0">
                      <v-col>
                          {{use_language.freight}}
                      </v-col>
                      <v-col cols="4" lg="3" align="right">
                          <span>{{ data_group_detail.deliveryPrice }}</span>
                      </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row class="py-0">
                      <v-col>
                          {{use_language.cod_service_fee}}
                      </v-col>
                      <v-col cols="4" lg="3" align="right">
                          <span>{{ data_group_detail.codCharged }}</span>
                      </v-col>
                  </v-row>
                  <!-- <v-divider></v-divider> -->
                  <v-row class="py-0">
                      <v-col>
                          {{use_language.vat}}
                      </v-col>
                      <v-col cols="4" lg="3" align="right">
                          <span>{{ data_group_detail.codVatAmount }}</span>
                      </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row class="py-0">
                      <v-col>
                          {{use_language.other_service_charges}}
                      </v-col>
                      <v-col cols="4" lg="3" align="right">
                          <span>{{ data_group_detail.servicePrice }}</span>
                      </v-col>
                  </v-row>
                  <!-- <v-divider></v-divider> -->
                  <v-row class="py-0">
                      <v-col>
                          {{use_language.vat}}
                      </v-col>
                      <v-col cols="4" lg="3" align="right">
                          <span>{{ data_group_detail.serviceVatAmount }}</span>
                      </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <!-- <v-row class="py-0">
                      <v-col>
                          ส่วนลด
                      </v-col>
                      <v-col cols="4" lg="3" align="right">
                          <span>{{ data_group_detail.discount }}</span>
                      </v-col>
                  </v-row> -->
                  <v-row class="py-0 font-weight-bold">
                      <v-col>
                          {{use_language.total_amount_}}
                      </v-col>
                      <v-col cols="4" lg="3" align="right">
                          <span>{{ data_group_detail.totalPrice }}</span>
                      </v-col>
                  </v-row>
                  <v-divider></v-divider>
              </v-col>
          </v-row>
        </div>
        <div class="overflow-y-auto" v-else>
          <v-data-table
            :headers="headers_item"
            :items="item_invoice_list"
            hide-default-footer :items-per-page="10000"
            item-key="id"
            class="elevation-0 packhai-border-table"
            :mobile-breakpoint="0"
            >
            <template v-slot:item.totalPrice="{ item }">
              <font>{{ format_price(item.quantity * item.unitPrice)  }}</font>
            </template>
          </v-data-table>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import Loading from '@/website/components/Loading'
  import axios from 'axios'
  import { reportService_dotnet } from '@/website/global'
  import { format_number, format_order_id, format_price, FormatInvoice ,get_languages} from '@/website/global_function'
  import { header_token } from '@/website/backend/token'
  import { finance_language } from "@/website/language/finance_language";

  
  export default {
    components: {
        Loading
    },
    data: () => ({
      page_loading: true,
      loading: false,

      use_language: null,
      set_language: null,


      headers: [
          { text: '', align: 'center', value: 'num', sortable: false },
          { text: 'ออเดอร์', align: 'center', value: 'orderID', sortable: false },
          { text: 'เลขแทรค', align: 'center', value: 'trackingNo', sortable: false },
          { text: 'วันที่สร้าง', align: 'center', value: 'createdDatetime', sortable: false },
          { text: 'วันที่แพ็ค', align: 'center', value: 'packedDate', sortable: false },
          { text: 'ขนส่ง', align: 'center', value: 'expressCompanyLogo', sortable: false },
          // { text: 'ขนส่ง', align: 'center', value: 'expressCompanyName', sortable: false },
          { text: 'ค่าส่ง', align: 'right', value: 'deliveryPrice', sortable: false },
          { text: 'ค่าพื้นที่ห่างไกล', align: 'right', value: 'remotePrice', sortable: false },
          { text: 'ค่า COD', align: 'right', value: 'codPrice', sortable: false },
          { text: 'ค่ากล่อง', align: 'right', value: 'boxPrice', sortable: false },
          { text: 'ค่าแพค', align: 'right', value: 'packPrice', sortable: false },
          { text: 'ค่าบับเบิล', align: 'right', value: 'bubblePrice', sortable: false },
          { text: 'ส่วนลด', align: 'right', value: 'discount', sortable: false },
          { text: 'ค่าเชื่อมต่อ Vrich', align: 'right', value: 'vrichFee', sortable: false },
          { text: 'ค่าอื่นๆ', align: 'right', value: 'otherServicePrice', sortable: false },
          { text: 'ราคารวม', align: 'right', value: 'totalPrice', sortable: false },
        ],
        datalist: [],
        datalist_for_excel:[],
        data_group_detail: null,
        sh_invoiceNo: null,
        sh_invoiceDate : null,
        ch_InvoiceGroupID: null,
        item_invoice_list: [],
        headers_item: [
          { text: 'รายละเอียด', align: 'left', value: 'description', sortable: false },
          { text: 'จำนวน', align: 'right', value: 'quantity', sortable: false },
          { text: 'ราคาต่อหน่วย', align: 'right', value: 'unitPrice', sortable: false },
          { text: 'รวม', align: 'right', value: 'totalPrice', sortable: false },
        ],

        excel_detail_invoice_list_header: [
          { label: "ออเดอร์", field: "orderID", width: 15},
          { label: "เลขแทรค", field: "trackingNo", width: 25 },
          { label: "วันที่สร้าง", field: "createdDatetime", width: 25 },
          { label: "วันที่แพ็ค", field: "packedDate", width: 25 },
          { label: "ขนส่ง", field: "expressCompanyName", width: 25 },
          { label: "น้ำหนัก", field: "boxWeight", width: 15 },
          { label: "กว้าง", field: "boxWidth", width: 15 },
          { label: "ยาว", field: "boxLong", width: 15 },
          { label: "สูง", field: "boxHigh", width: 15 },
          { label: "เลขอ้างอิง", field: "referenceNo", width: 30 },
          { label: "ค่าส่ง", field: "deliveryPrice", width: 15 },
          { label: "ค่าพื้นที่ห่างไกล", field: "remotePrice",  width: 15 },
          { label: "ค่า COD", field: "codPrice", width: 15 },
          { label: "ค่ากล่อง", field: 'boxPrice', width: 15 },
          { label: "ค่าแพค", field: "packPrice", width: 15 },
          { label:  "ค่าบับเบิล", field: "bubblePrice", width: 15 },
          { label: "ส่วนลด", field: "discount", width: 15},
          { label: "ค่าเชื่อมต่อ Vrich", field: "vrichFee", width: 15},
          { label:'ค่าอื่นๆ', field: "otherServicePrice", width: 15},
          { label: "ราคารวม", field: "totalPrice", width: 15},
        ],

    }),
    async created() {

       //เก็บภาษามาไว้ใน use_language
         this.use_language = await this.get_languages(localStorage.getItem("set_language"));


      this.inv = null
      this.inv_group = null
      if(this.$route.query.inv === 'undefined'){
        this.inv = null
      } else if ( this.$route.query.inv == 'null') {
        this.inv = null
      } else if ( this.$route.query.inv == null) {
        this.inv = null
      } else if (this.$route.query.inv == '') {
        this.inv = null
      } else {
        this.inv = this.$route.query.inv
      }


      if(this.$route.query.group === 'undefined'){
        this.inv_group = null
      } else if ( this.$route.query.group == 'null') {
        this.inv_group = null
      } else if ( this.$route.query.group == null) {
        this.inv_group = null
      } else if (this.$route.query.group == '') {
        this.inv_group = null
      } else {
        this.inv_group = this.$route.query.group
      }


      await this.get_invoice()
      // await this.get_invoice()
      this.page_loading = false

    },
    methods: {
      format_number, format_order_id, format_price, FormatInvoice,get_languages,
      detail_page (token) {
        window.open('order-detail?token='+token, '_blank');
      },
      async get_invoice () {


        if(this.inv_group != null) {
          let res = await axios.post(reportService_dotnet+'Invoice/get-invoice-befor-delete-invoice', {
            "invoiceID": this.inv == null ? 0 : this.inv,
            "invoiceGroupID": this.inv_group
          },
          { headers: header_token})
          var invopice_title = ''

          if(res.data.length > 0){
            for (var i = 0; i < res.data.length; i++) {

              if(i == res.data.length -1) {
                invopice_title += res.data[i]
              } else {
                invopice_title += res.data[i] + ', '
              }
            }
          }

          this.sh_invoiceNo = invopice_title
          // this.sh_invoiceDate = row.invoiceDate
        } else {
          this.sh_invoiceNo = null
        }

        if( this.inv_group != null){
          let res1 = await axios.post(reportService_dotnet+'Invoice/group-detail1', {
            "id": this.inv_group
          },
          { headers: header_token})
          if(res1.data == ''){
            this.datalist = []
            this.datalist_for_excel = []
          } else {

            if(res1.data.status == 'error'){
              this.datalist = []
              this.datalist_for_excel = []
              await this.$swal({
                  position: "top",
                  type: "info",
                  text: "ไม่พบใบแจ้งหนี้ !!",
              })
              this.loading = false
              return
            } else {
              this.datalist = []
              this.datalist = res1.data.itemList
              this.datalist_for_excel = [...res1.data.itemList]
            }

          }

          this.item_invoice_list = res1.data.itemListGroupIsNull
          this.ch_InvoiceGroupID = res1.data.invoiceGroupID
          this.data_group_detail = res1.data

          this.datalist_for_excel.push({"vrichFee": "ค่าขนส่ง" , "totalPrice": this.data_group_detail.deliveryPrice})
          this.datalist_for_excel.push({"vrichFee": "ค่าบริการ COD" , "totalPrice": this.data_group_detail.codCharged})
          this.datalist_for_excel.push({"vrichFee": "ภาษีมูลค่าเพิ่ม" , "totalPrice": this.data_group_detail.codVatAmount})
          this.datalist_for_excel.push({"vrichFee": "ค่าบริการอื่นๆ" , "totalPrice": this.data_group_detail.servicePrice})
          this.datalist_for_excel.push({"vrichFee": "ภาษีมูลค่าเพิ่ม" , "totalPrice": this.data_group_detail.serviceVatAmount})
          // this.datalist_for_excel.push({"vrichFee": "ส่วนลด" , "totalPrice": this.data_group_detail.discount})
          this.datalist_for_excel.push({"vrichFee": "รวมยอดทั้งหมด" , "totalPrice": this.data_group_detail.totalPrice})

        } else {
          let res2 = await axios.post(reportService_dotnet+'Invoice/group-detail', {
            "id": this.inv
          },
          { headers: header_token})
          if(res2.data == ''){
            this.datalist = []
            this.datalist_for_excel = []
          } else {
            this.datalist = []
            this.datalist_for_excel = []
            // this.datalist = res2.data.itemList
            this.datalist_for_excel = [...res2.data.itemListGroupIsNull]
          }
          this.item_invoice_list = res2.data.itemListGroupIsNull
          this.ch_InvoiceGroupID = res2.data.invoiceGroupID
          this.data_group_detail = res2.data

          // this.datalist_for_excel.push({"discount": "ค่าขนส่ง" , "totalPrice": this.data_group_detail.deliveryPrice})
          // this.datalist_for_excel.push({"discount": "ค่าบริการ COD" , "totalPrice": this.data_group_detail.codCharged})
          // this.datalist_for_excel.push({"discount": "ภาษีมูลค่าเพิ่ม" , "totalPrice": this.data_group_detail.codVatAmount})
          // this.datalist_for_excel.push({"discount": "ค่าบริการอื่นๆ" , "totalPrice": this.data_group_detail.servicePrice})
          // this.datalist_for_excel.push({"discount": "ภาษีมูลค่าเพิ่ม" , "totalPrice": this.data_group_detail.serviceVatAmount})
          // this.datalist_for_excel.push({"discount": "รวมยอดทั้งหมด" , "totalPrice": this.data_group_detail.totalPrice})
        }
      },

    }
  }
</script>
<style>
  .packhai-border-table{
    /* border:1px solid #e9edf4; */
    border-collapse: collapse;
    border-spacing: 0px;
    border-right: 1px solid #e9edf4;
    border-bottom: 1px solid #e9edf4;
    border-top: 1px solid #e9edf4;
  }
  .packhai-border-table thead{
    color:white;
  }
  .packhai-border-table thead tr th{
    color: black !important;
    font-weight: bold;
    font-size: 14px;
  }
  .packhai-border-table tr {
    /* border-collapse: collapse; */
    /* border: 1px solid #e9edf4; */
  }
  .packhai-border-table th {
    /* border-collapse: collapse; */
      border-left: 1px solid #e9edf4;
  }
  .packhai-border-table tr td{
    border-left: 1px solid #e9edf4;
  }


</style>
