<template>
  <div v-if="page_loading">
      <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />
    <v-card elevation="1" class="v-card-bottom-30 hidden-xs-only">
      <v-card-title  style="height: 60px;">
        <h4>{{use_language.finance}}</h4> <v-icon>mdi-chevron-right</v-icon><span class="font-weight-regular body-1">{{use_language.balance_list}}</span>
      </v-card-title>
    </v-card>
    <v-card class="v-card-bottom-30">
      <v-card-title>
        <span v-if="balance > 0">{{use_language.balance_remaining_money}} : <font class="ml-3 text-success"> {{balance}}</font></span>
        <span v-else>{{use_language.balance_remaining_money}} : <font class="ml-3 text-danger" > {{balance}}</font></span>
        <v-spacer/>
        <v-btn color="danger" dark to="/notify_transfer">{{use_language.payment_notification}}</v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="2">
            <v-menu
                ref="search_menu_date_from"
                v-model="search_menu_date_from"
                :close-on-content-click="false"
                :return-value.sync="search_date_from"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      :value="computed_search_date_from"
                      @change="value => computed_search_date_from = value"
                      autocomplete="off"
                      label="เริ่ม"
                      outlined
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      hide-details
                      v-on="on"
                  >
                  <template v-slot:label> <span>{{use_language.start_}}</span> </template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="search_date_from" @input="search_menu_date_from = false, $refs.search_menu_date_from.save(search_date_from)" no-title :allowed-dates="allowedDates" class="my-0">
                </v-date-picker>
              </v-menu>
          </v-col>
          <v-col cols="12" md="2">
            <v-menu
                ref="search_menu_date_to"
                v-model="search_menu_date_to"
                :close-on-content-click="false"
                :return-value.sync="search_date_to"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      :value="computed_search_date_to"
                      @change="value => computed_search_date_to = value"
                      autocomplete="off"
                      label="สิ้นสุด"
                      outlined
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      hide-details
                      v-on="on"
                  >
               <template v-slot:label> <span>{{use_language.end}}</span> </template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="search_date_to" @input="search_menu_date_to = false, $refs.search_menu_date_to.save(search_date_to)" no-title :allowed-dates="allowedDates" class="my-0">
                </v-date-picker>
              </v-menu>
          </v-col>
          <v-col cols="12" md="6">
            <v-btn color="primary" @click="getHistoryBalance(), descriptionIndex = null">{{use_language.search}}</v-btn>
          </v-col>

          <v-col cols="12" md="2" align="right" class="hidden-xs-only">
            <!-- <v-btn color="success" outlined @click="Export_to_excel()">Export to Excel</v-btn> -->
            <vue-excel-xlsx
                v-if="topupHistory.table.length"
                :data="topupHistory.table"
                :columns="excel_topupHistory_header"
                v-bind:filename='use_language.balance_list_report_'
                v-bind:sheetname='use_language.balance_list_report'
                >
                <v-btn color="success" outlined >{{use_language.export_excel}}</v-btn>
            </vue-excel-xlsx>
          </v-col>

          <v-col cols="12" >
            <!-- แสดงจอ PC -->
            <div class="hidden-xs-only">
              <v-data-table :headers="headers" :items="topupHistory.table" hide-default-footer :items-per-page="10000" class="elevation-0 packhai-border-table" >
                <template v-slot:item.description="{ item }">
                  <v-row v-if="item.description != null">
                    <v-col cols="12" align="left" v-if="item.income != null">
                      <span>{{ item.description }}</span>
                    </v-col>
                    <v-col cols="12" align="left" style="padding-left: 50px;" v-else>
                      {{item.description}}
                      <!-- <span style="color: red" v-if="item.invoiceReference != null">( อ้างอิงเลขที่ {{ item.invoiceReference }} )</span> -->
                     </v-col>
                  </v-row>
                </template>

                <template v-slot:item.createdDateTime="{ item }">
                  {{ set_format_date_time(item.createdDateTime) }}<br>
                </template>
                <template v-slot:item.income="{ item }">
                  <font v-if="item.income != null" class="text-success"><b>+ {{format_price(item.income)}}</b></font>
                </template>
                <template v-slot:item.expense="{ item }">
                  <font v-if="item.expense != null" class="text-danger"><b>- {{format_price(item.expense)}}</b></font>
                </template>
                <template v-slot:item.balance="{ item }">
                  <font><b>{{ format_price(item.balance) }}</b></font>
                </template>
                <template v-slot:item.seeMore="{ item }">
                  <span v-if="item.invoiceID != null || item.invoiceGroupID != null">
                    <v-btn @click="seeMore_Invoice_List(item)"  icon><v-icon color="primary">mdi-clipboard-text</v-icon></v-btn>
                  </span>
                </template>
                <template v-slot:item.Print="{ item }">
                    <!-- <v-btn v-if="(item.invoiceID != null || item.invoiceGroupID != null ) && item.invoiceReference != null" icon @click="dialog_print_open(item)"> -->
                    <v-btn v-if=" isAllowPrintInvoice == true && (item.invoiceID != null  || item.invoiceReference != null)" icon @click="dialog_print_open(item)">
                      <v-icon color="success">mdi-printer</v-icon>
                    </v-btn>
                </template>
              </v-data-table>
            </div>
            <!-- แสดงจอมือถือ -->
            <div class="hidden-sm-and-up">
              <v-divider/>
              <v-card elevation="0" class="my-1" v-for="(item, index) in topupHistory.table" :key="index" >
                <v-row>
                  <v-col class="pb-0" cols="4" align="left"> <b>{{item.createdDateTime.substring(0, 10)}}</b> </v-col>
                  <v-col class="pb-0" cols="4" align="right">
                    <span v-show="item.income != null" class="text-success"><b>+ {{format_price(item.income)}}</b></span>
                    <span v-show="item.expense != null" class="text-danger"><b>- {{format_price(item.expense)}}</b></span>
                  </v-col>
                  <v-col class="pb-0" cols="4" align="right">= {{ format_price(item.balance) }}</v-col>
                </v-row>
                <v-row>
                  <v-col class="pb-0" cols="6" align="left">{{item.createdDateTime.substring(11, 16)}}</v-col>
                  <v-col class="pt-0" cols="6" align="right">
                    <v-btn icon @click="showDescription(index, item.id)" v-if="descriptionIndex != index" ><v-icon>fa-angle-down</v-icon></v-btn>
                    <v-btn icon @click="showDescription(index, item.id)" v-else="" ><v-icon>fa-angle-up</v-icon></v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="descriptionIndex == index ">
                  <v-col class="pt-0" cols="9">
                    <span>{{ item.description }}</span>
                  </v-col>
                  <v-col class="pt-0" cols="3" align="center">
                    <span v-if="item.invoiceID != null || item.invoiceGroupID != null">
                      <v-btn class="pb-5" @click="seeMore_Invoice_List(item)"  icon> <b><a href="#" style="color: #00BFFF; text-decoration: none;">{{use_language.info}} {{'>>'}} </a></b>  </v-btn>
                    </span>
                  </v-col>
                </v-row>
                <v-divider/>
              </v-card>
            </div>

          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog_select_print" width="500">
      <v-card elevation="1">
        <v-card-title class="pr-1">
          {{use_language.print}} {{ title_print }}
          <v-spacer/>
          <v-btn icon @click="dialog_select_print=false"><v-icon class="fn-25">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pl-7 pb-0">
          <div class="py-3">
            <v-radio-group
              v-model="select_print_type"
              row
            >
              <v-radio
                label="ใบแจ้งหนี้"
                :value="1"
              >
                    <template v-slot:label> <span>{{use_language.invoice_}}</span> </template>
              </v-radio>
              <!-- <v-radio
                label="ใบเสร็จรับเงิน / ใบกำกับภาษี"
                :value="2"
              ></v-radio> -->
            </v-radio-group>
          </div>
          <div class="pt-3 pb-5">
            <v-btn outlined color="info" @click="PrintPreview(item_for_print)">
              {{use_language.submit}}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import Loading from '@/website/components/Loading'
  import axios from 'axios'
  import { shopService_dotnet } from '@/website/global'
  import { format_number, format_order_id, format_price, set_format_date_time,get_languages} from '@/website/global_function'
   


  export default {
    components: {
        Loading
    },
    data: () => ({
      header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
      page_loading: true,
      loading: false,

      use_language: null,
      set_language: null,


      topupDialog: false,
      transferMoney: '',
      transferDate: '',
      transferHour: new Date().getHours(),
      transferMin: new Date().getMinutes(),
      showPicker: false,
      bankAccount: {
        'selected': null,
        'list': []
      },
      search_menu_date_from: false,
      search_date_from: null,

      search_menu_date_to: false,
      search_date_to: null,

      balance: 0,
      filterSelect: 1,
      filterData: [
        { 'label': 'ทั้งหมด', 'value': 1 },
        { 'label': 'เติมเงิน', 'value': 2 },
        { 'label': 'ใช้ไป', 'value': 3 }
      ],
      topupHistory: {
        'headersAll': [
          // { text: 'ลำดับ', align: 'center', value: 'no', sortable: false },
          { text: 'วันที่', align: 'center', value: 'createdDateTime', sortable: false, width: '10%' },
          { text: 'รายละเอียด', align: 'left', value: 'description', sortable: false, width: '25%' },
          { text: 'เข้า', align: 'right', value: 'income', sortable: false, width: '10%' },
          { text: 'ออก', align: 'right', value: 'expense', sortable: false, width: '10%' },
          { text: 'คงเหลือ', align: 'right', value: 'balance', sortable: false, width: '10%' },
          { text: 'รายละเอียด', align: 'center', value: 'seeMore', sortable: false, width: '5%' },
          { text: 'ปริ้น', align: 'center', value: 'Print', sortable: false, width: '5%'},
        ],
        'table': [],
        'keyTable': 0
      },
      to_excel_data: [],
      footer_props: {
         'items-per-page-options': [50],
         'items-per-page-text': null,
         'disable-items-per-page': true
      },

      descriptionIndex: null,

      title_print: null,
      dialog_select_print: false,
      select_print_type: 1,
      item_for_print: null,

      //excel
      excel_topupHistory_header: [
        { label: "วันที่", field: "createdDateTime", width: 20 , dataFormat: set_format_date_time},
        { label: "รายละเอียด", field: "description", width: 35 },
        { label: "เข้า", field: "income", width: 15 },
        { label: "ออก", field: "expense",  width: 15 },
        { label: "คงเหลือ", field: "balance", width: 15 },
      ],
    }),
    computed: {
      headers: function() {
        if (this.filterSelect==1){
          return this.topupHistory['headersAll']
        } else {
          return this.topupHistory['headersAll']
        }
      },

      computed_search_date_from() {
        return this.formatDates(this.search_date_from)
      },
      computed_search_date_to() {
        return this.formatDates(this.search_date_to)
      },
    },
    watch: {
      filterSelect: async function() {
        await this.getHistoryBalance()
      }
    },
    async created() {

      //เก็บภาษามาไว้ใน use_language
         this.use_language = await this.get_languages(localStorage.getItem("set_language"));



      // await this.getBalance()
      await this.get_data_shop()
      await this.getHistoryBalance()
      this.page_loading = false
    },
    methods: {
      format_number, format_order_id, format_price, set_format_date_time,get_languages,
      allowedDates: val => val <= new Date().toISOString().substr(0, 10),

      formatDate (date) {
        if (date.length == 0) return null

        if (date.length == 1) {
          const [year, month, day] = date[0].split('-')
          return `${day}-${month}-${year}`
        } else {
          const [year_from, month_from, day_from] = date[0].split('-')
          const [year_to, month_to, day_to] = date[1].split('-')
          if (date[0] < date[1]) {
            return `${day_from}-${month_from}-${year_from} ~ ${day_to}-${month_to}-${year_to}`
          } else {
            return `${day_to}-${month_to}-${year_to} ~ ${day_from}-${month_from}-${year_from}`
          }
        }
      },

      formatDates (date) {
        if (date == null){
          return null
        } else {
          const [year, month, day] = date.split('-')
          return `${day}-${month}-${year}`
        }
      },

      showDescription (index, ID) {
        if(this.descriptionIndex == index){
          this.descriptionIndex = null
        }else {
          this.descriptionIndex = index
        }

      },

      async get_data_shop () {
        let response = await axios.post(shopService_dotnet+'Shop/get-shop', {
          "shopID": localStorage.getItem('shop_id')
        },
        { headers: this.header_token})
        this.isAllowPrintInvoice = response.data.isAllowPrintInvoice
      },

      async getHistoryBalance() {
        this.loading = false
        let res1 = await axios.post(shopService_dotnet+'Balance/get-shop-balance-statement-list', {
          "shopID":  localStorage.getItem('shop_id'),
          "dateFrom": this.search_date_from,
          "dateTo": this.search_date_to
        },
        { headers: this.header_token})

        this.balance = res1.data.balanceFormatted
        this.topupHistory['table'] = res1.data.statements
        this.to_excel_data = res1.data.statements
        this.topupHistory['keyTable']++
        this.search_date_from = res1.data.dateFrom
        this.search_date_to = res1.data.dateTo
      },

      seeMore_Invoice_List (item) {
        // localStorage.setItem('invoiceID', invoiceID)
        var invoiceID = item.invoiceID != null ? item.invoiceID.toString() : null
        var invoiceGroupID = item.invoiceGroupID != null ? item.invoiceGroupID.toString() : null
        window.open('/view_detail_invoice?inv=' + invoiceID + '&group=' + invoiceGroupID , '_blank');
      },

      dialog_print_open (item) {
        this.item_for_print = item
        this.title_print = null
        this.title_print = item.description
        this.dialog_select_print = true
      },

      async PrintPreview(item){
        var invoiceID = item.invoiceID != null ? item.invoiceID.toString() : null
        var invoiceGroupID_tmp = item.invoiceGroupID
        if (invoiceGroupID_tmp != null){
          invoiceGroupID_tmp = invoiceGroupID_tmp.toString()
        }
        window.open('/print-invoice?inv=' + invoiceID + "&group=" + invoiceGroupID_tmp + '&type='+this.select_print_type.toString());
        this.select_print_type = 1
        
      },
    }
  }
</script>
<style>
  .packhai-border-table{
    /* border:1px solid #e9edf4; */
    border-collapse: collapse;
    border-spacing: 0px;
    border-right: 1px solid #e9edf4;
    border-bottom: 1px solid #e9edf4;
    border-top: 1px solid #e9edf4;
  }
  .packhai-border-table thead{
    color:white;
  }
  .packhai-border-table thead tr th{
    color: black !important;
    font-weight: bold;
    font-size: 14px;
  }
  .packhai-border-table tr {
    /* border-collapse: collapse; */
    /* border: 1px solid #e9edf4; */
  }
  .packhai-border-table th {
    /* border-collapse: collapse; */
      border-left: 1px solid #e9edf4;
  }
  .packhai-border-table tr td{
    border-left: 1px solid #e9edf4;
  }


</style>
