<template>
  <div v-if="page_loading">
      <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />
    <v-card elevation="1" class="v-card-bottom-30 hidden-xs-only">
      <v-card-title  style="height: 60px;">
        <h4>{{use_language.finance}}</h4> <v-icon>mdi-chevron-right</v-icon><span class="font-weight-regular body-1">{{use_language.invoice_group}}</span>
      </v-card-title>
    </v-card>

    <v-card class="v-card-margin-bottom-30">
      <v-card-title>
        <v-icon size="20" color="primary">fa-sliders-h</v-icon><span class="my-3 ml-3">{{use_language.searchList}}</span>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <div>
          <v-row>
            <v-col cols="12" md="2" lg="1" class=" pb-2">
              <span><b> {{use_language.select_date}} : </b></span>
            </v-col>
            <v-col cols="12" md="3" lg="2" class="  pb-2 ">
              <v-menu
                  ref="search_menu_date_from"
                  v-model="search_menu_date_from"
                  :close-on-content-click="false"
                  :return-value.sync="search_date_from"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        :value="computed_search_date_from"
                        @change="value => computed_search_date_from = value"
                        autocomplete="off"
                        label="เริ่ม"
                        outlined
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        hide-details
                        v-on="on"
                    >
                      <template v-slot:label> <span>{{use_language.start_}}</span> </template> 
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="search_date_from" @input="search_menu_date_from = false, $refs.search_menu_date_from.save(search_date_from)" no-title :allowed-dates="allowedDates" class="my-0">
                  </v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12"   md="3" lg="2" class="  pb-2 ">
              <v-menu
                  ref="search_menu_date_to"
                  v-model="search_menu_date_to"
                  :close-on-content-click="false"
                  :return-value.sync="search_date_to"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        :value="computed_search_date_to"
                        @change="value => computed_search_date_to = value"
                        autocomplete="off"
                        label="สิ้นสุด"
                        outlined
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        hide-details
                        v-on="on"
                    >
                                          <template v-slot:label> <span>{{use_language.end}}</span> </template> 
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="search_date_to" @input="search_menu_date_to = false, $refs.search_menu_date_to.save(search_date_to)" no-title :allowed-dates="allowedDates" class="my-0">
                  </v-date-picker>
                </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2" lg="1" class="pb-2 ">
            </v-col>
            <v-col cols="12"  md="6" lg="4"   class="pb-2">
              <v-btn dark color="error" @click="getInvoice()" class="fn-14"><v-icon>mdi-magnify</v-icon>{{use_language.search}}</v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>

    <v-card class="v-card-bottom-30">
      <v-card-title>
        {{use_language.invoice_list}}
        <v-spacer/>
        <v-btn v-show="invoice.table.length != 0" size="1" color="success" dark class="mr-1"><v-icon >mdi-file-excel</v-icon>
          <vue-excel-xlsx
              :data="invoice.table"
              :columns="excel_invoice_list_header"
               v-bind:filename='invoice_report'
               v-bind:sheetname='invoice_report'
              >
              {{use_language.export_excel}}
          </vue-excel-xlsx>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row class="mt-2 mx-0">
          <v-col cols="12" class=" px-0">
            <!-- PC -->
            <v-data-table
              :headers="invoice.headers"
              :key="invoice.key_table"
              :items="invoice.table"
              :footer-props="invoice.footer_props"
              item-key="id"
              class="elevation-0 packhai-border-table"
              :mobile-breakpoint="0"
              >
              <template v-slot:item.invoiceNo="{ item }">
                <a @click="openListOrderInvoice(item), shopDisibled = true, branchDisibled = true">{{ item.invoiceNo }}</a>
              </template>


              <template v-slot:item.createdDatetime="{ item }">
                {{ set_format_date_time(item.createdDatetime) }}
              </template>

              <template v-slot:item.calculatedPrice="{ item }">
                {{ format_price(item.calculatedPrice) }}
              </template>
              <template v-slot:item.vatAmount="{ item }">
                {{ format_price(item.vatAmount) }}
              </template>
              <template v-slot:item.totalPrice="{ item }">
                {{ format_price(item.totalPrice) }}
              </template>
              <template v-slot:item.totalItemPrice="{ item }">
                {{ format_price(item.totalItemPrice) }}
              </template>

              <template v-slot:item.invoiceDate="{ item }">
                <span v-if="item.invoiceDate != null">{{ set_format_date_time(item.invoiceDate).substring(0, 10) }}</span>
              </template>

              <template v-slot:item.vatType="{ item }">
                {{ format_vat_type(item.vatType) }}
              </template>

              <template v-slot:item.CancelInvoice="{ item }">
                <v-btn icon><v-icon size="18" color="red" @click="check_delete(item)">fa-trash-alt</v-icon></v-btn>
                <!-- <v-btn icon @click="CancelInvoiceConfirm(item.id)"><v-icon color="red" size="18" >fa-trash-alt</v-icon></v-btn> -->
              </template>

              <template v-slot:item.Print="{ item }">
                  <!-- <v-btn icon><v-icon>mdi-printer</v-icon></v-btn> -->
                  <v-btn icon @click="dialog_print_open(item)"><v-icon color="success">mdi-printer</v-icon></v-btn>
              </template>
            </v-data-table>

          </v-col>
        </v-row>
      </v-card-text>
    </v-card>


    <v-dialog v-model="listOrderInvoiceDialog" width="80%" >
      <v-card elevation="1">
        <v-card-title>
          <font >{{use_language.invoice_information}} : {{listOrderInvoice.invoiceNo}}
          <br class="hidden-sm-and-up">  ({{use_language.receipt_issuing_time}} {{ set_format_date_time(listOrderInvoice.invoiceDate) }})</font>
          <v-btn icon v-if="ch_InvoiceGroupID != null">
            <vue-excel-xlsx
                :data="data_group_detailt_for_excel"
                :columns="excel_detail_invoice_list_header"
                 v-bind:filename='invoice_data_report'
                 v-bind:sheetname='invoice_data_report'
                >
                <v-icon color="success">mdi-file-excel</v-icon>
            </vue-excel-xlsx>
          </v-btn>


          <v-spacer/>
          <v-btn icon @click="listOrderInvoiceDialog=false, invoice_id_row =false"><v-icon class="fn-25">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text style="height: 640px; background: #f0f0f0;" class="overflow-y-auto">
          <!-- ที่อยู่ Branch -->
        <v-card style="min-height : 600px">
            <div class="overflow-y-auto">
              <v-data-table
                :headers="headers_item"
                :items="item_invoice_list"
                hide-default-footer :items-per-page="10000"
                item-key="id"
                class="elevation-0 packhai-border-table"
                :mobile-breakpoint="0"
                >
                <template v-slot:item.totalPrice="{ item }">
                  <font>{{ format_price(item.quantity * item.unitPrice)  }}</font>
                </template>

              </v-data-table>
            </div>
            <v-row justify="end">
              <v-col lg="5">

                <v-divider></v-divider>
                <v-row class="py-1 px-0">
                    <v-col>
                        {{use_language.total}}
                    </v-col>
                    <v-col cols="4" lg="3" align="right">
                        <span v-if="detail_totalItemPrice != null">{{ format_price(detail_totalItemPrice.toFixed(2)) }}</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="py-1 px-0">
                    <v-col>
                      {{use_language.amount_calculated_for_tax}}
                    </v-col>
                    <v-col cols="4" lg="3" align="right">
                        <span v-if="detail_calculatedPrice != null">{{ format_price(detail_calculatedPrice.toFixed(2)) }}</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                  <v-row class="py-1 px-0">
                      <v-col>
                      {{use_language.vat}}
                      <span v-if="detail_vatType == '1'">(No VAT)</span>
                      <span v-if="detail_vatType == '2'">(VAT Included)</span>
                      <span v-if="detail_vatType == '3'">(VAT Excluded)</span>
                      </v-col>
                      <v-col cols="4" lg="3" align="right">
                          <span v-if="detail_vatAmount != null">{{ format_price(detail_vatAmount.toFixed(2)) }}</span>
                      </v-col>
                  </v-row>
                  <v-divider></v-divider>
                    <v-row class="py-1 px-0">
                      <v-col>
                      {{use_language.grand_total}}
                      </v-col>
                      <v-col cols="4" lg="3" align="right">
                          <span v-if="detail_totalPrice != null">{{ format_price(detail_totalPrice.toFixed(2)) }}</span>
                      </v-col>
                  </v-row>
                  <v-divider></v-divider>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_select_print" width="500">
      <v-card elevation="1">
        <v-card-title class="pr-1">
          {{use_language.print}} {{ title_print }}
          <v-spacer/>
          <v-btn icon @click="dialog_select_print=false"><v-icon class="fn-25">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pl-7 pb-0">
          <div class="py-3">
            <v-radio-group
              v-model="select_print_type"
              row
            >
              <v-radio
                label="ใบแจ้งหนี้"
                :value="1"
              >
                  <template v-slot:label> <span>{{use_language.invoice_}}</span> </template> 
              </v-radio>
              <v-radio
                label="ใบเสร็จรับเงิน / ใบกำกับภาษี"
                :value="2"
              >
                <template v-slot:label> <span>{{use_language.receipt_tax_invoice}}</span> </template> 
              </v-radio>
            </v-radio-group>
          </div>
          <div class="pt-3 pb-5">
            <v-btn outlined color="info" @click="PrintPreview(item_for_print)">
              {{use_language.submit}}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import Loading from '@/website/components/Loading'
  import axios from 'axios'
  // import Swal from 'sweetalert2'
  import { reportService_dotnet } from '@/website/global'
  import { format_number, format_order_id, format_price, set_format_date_time, format_vat_type,get_languages } from '@/website/global_function'
  import { header_token } from '@/website/backend/token'

  
  export default {
    components: {
        Loading
    },
    data: () => ({
      page_loading: true,
      loading: false,

      use_language: null,
      set_language: null,

      search_menu_date_from: false,
      search_date_from: null,

      search_menu_date_to: false,
      search_date_to: null,

      data_group_detail: null,
      data_group_detailt_for_excel: [],

      // param check
      detail_invoiceGroupID : null,
      detail_totalItemPrice : null ,
      detail_calculatedPrice : null ,
      detail_vatType : null ,
      detail_vatAmount : null ,
      detail_totalPrice : null ,

      invoice: {
        'datetime_search': [new Date(new Date().setDate(new Date().getDate()-1)).toISOString().substr(0, 10),new Date(new Date().setDate(new Date().getDate())).toISOString().substr(0, 10)],
        'showDatetime': false,
        'headers': [
          { text: 'Invoice No', align: 'center', sortable: false, value: 'invoiceNo', width : "150px"},
          { text: 'วันที่สร้าง', align: 'center', sortable: false, value: 'createdDatetime', width : "150px"},
          { text: 'หมายเลขกลุ่ม', align: 'center', sortable: false, value: 'groupName', width : "150px"},
          { text: 'วันที่ใบแจ้งหนี้', align: 'center', sortable: false, value: 'invoiceDate', width : "150px"},
          { text: 'สร้างโดย', align: 'center', sortable: false, value: 'createdByName', width : "200px"},
          { text: 'ร้านค้า', align: 'left', sortable: false, value: 'shopName', width : "200px"},
          { text: 'ภาษี', align: 'center', sortable: false, value: 'vatType', width : "150px"},
          { text: 'ยอดรวม', align: 'right', sortable: false, value: 'totalItemPrice', width : "100px"},
          { text: 'ยอดที่คำนวนภาษี', align: 'right', sortable: false, value: 'calculatedPrice', width : "150px"},
          { text: 'ภาษีมูลค่าเพิ่ม', align: 'right', sortable: false, value: 'vatAmount', width : "150px"},
          { text: 'ยอดรวมทั้งหมด', align: 'right', sortable: false, value: 'totalPrice', width : "150px"},
          // { text: 'ยกเลิก', align: 'center', sortable: false, value: 'CancelInvoice', width : "100px"},
          // { text: 'ปริ้น', align: 'center', sortable: false, value: 'Print', width : "100px"},
        ],
        'footer_props': {
           'items-per-page-options': [50],
           'items-per-page-text': null,
           'disable-items-per-page': true
        },
        'key_table': 0,
        'table': []
      },
      listOrderInvoiceDialog: false,

      listOrderInvoice: {
        'headers': [
            { text: '', align: 'center', value: 'num', sortable: false },
            { text: 'ออเดอร์', align: 'center', value: 'orderID', sortable: false },
            { text: 'เลขแทรค', align: 'center', value: 'trackingNo', sortable: false },
            { text: 'วันที่สร้าง', align: 'center', value: 'createdDatetime', sortable: false },
            { text: 'วันที่แพ็ค', align: 'center', value: 'packedDate', sortable: false },
            { text: 'ขนส่ง', align: 'center', value: 'expressCompanyLogo', sortable: false },
            // { text: 'ขนส่ง', align: 'center', value: 'expressCompanyName', sortable: false },
            { text: 'ค่าส่ง', align: 'right', value: 'deliveryPrice', sortable: false },
            { text: 'ค่าพื้นที่ห่างไกล', align: 'right', value: 'remotePrice', sortable: false },
            { text: 'ค่า COD', align: 'right', value: 'codPrice', sortable: false },
            { text: 'ค่ากล่อง', align: 'right', value: 'boxPrice', sortable: false },
            { text: 'ค่าแพค', align: 'right', value: 'packPrice', sortable: false },
            { text: 'ค่าบับเบิล', align: 'right', value: 'bubblePrice', sortable: false },
            { text: 'ส่วนลด', value: 'discount', sortable: false, align: 'right' },
            { text: 'ค่าเชื่อมต่อ Vrich', value: 'vrichFee', sortable: false, align: 'right' },
            { text: 'ราคารวม', align: 'right', value: 'totalPrice', sortable: false },
          ],
        'key_table': 0,
        'table': [],
        'invoiceId': ''
      },

      ch_InvoiceGroupID: null,
      item_invoice_list: [],
      headers_item: [
          { text: 'รายละเอียด', align: 'left', value: 'description', sortable: false },
          { text: 'จำนวน', align: 'right', value: 'quantity', sortable: false },
          { text: 'ราคาต่อหน่วย', align: 'right', value: 'unitPrice', sortable: false },
          { text: 'รวม', align: 'right', value: 'totalPrice', sortable: false },
        ],


        title_print: null,
        dialog_select_print: false,
        select_print_type: 1,
        item_for_print: null,


        excel_invoice_list_header : [
            { label: "Invoice No", field: "invoiceNo", width: 15},
            { label: "วันที่สร้าง", field: "createdDatetime", width: 20 ,dataFormat: set_format_date_time},
            { label: "วันที่ใบแจ้งหนี้", field: "invoiceDate", width: 20 ,dataFormat: set_format_date_time},
            { label: "หมายเลขกลุ่ม", field: "groupName",  width: 30 },
            { label: "สร้างโดย", field: "createdByName",  width: 30 },  //dataFormat: format_number,
            { label: "ร้านค้า", field: "shopName", width: 40 },
            { label: "ภาษี", field: "vatType", width: 15, dataFormat: format_vat_type },
            { label: "ยอดรวม", field: "totalItemPrice", width: 15 },
            { label: "ยอดที่คำนวนภาษี", field: "calculatedPrice", width: 15},
            { label: "ภาษีมูลค่าเพิ่ม", field: "vatAmount", width: 15},
            { label: "ยอดรวมทั้งหมด", field: "totalPrice", width: 15},
        ],

    }),
    computed: {
      computed_search_date_from() {
        return this.formatDates(this.search_date_from)
      },
      computed_search_date_to() {
        return this.formatDates(this.search_date_to)
      },
    },
    watch: {
      // filterSelect: async function() {
      //   await this.getHistoryBalance()
      // }
    },
    async created() {

        //เก็บภาษามาไว้ใน use_language
         this.use_language = await this.get_languages(localStorage.getItem("set_language"));



      // await this.getBalance()
      // await this.getHistoryBalance()
      await this.getInvoice()
      this.page_loading = false
    },
    methods: {
      format_number, format_order_id, format_price, set_format_date_time, format_vat_type,get_languages,
      allowedDates: val => val <= new Date().toISOString().substr(0, 10),
      //
      // formatDate (date) {
      //   if (date.length == 0) return null
      //
      //   if (date.length == 1) {
      //     const [year, month, day] = date[0].split('-')
      //     return `${day}-${month}-${year}`
      //   } else {
      //     const [year_from, month_from, day_from] = date[0].split('-')
      //     const [year_to, month_to, day_to] = date[1].split('-')
      //     if (date[0] < date[1]) {
      //       return `${day_from}-${month_from}-${year_from} ~ ${day_to}-${month_to}-${year_to}`
      //     } else {
      //       return `${day_to}-${month_to}-${year_to} ~ ${day_from}-${month_from}-${year_from}`
      //     }
      //   }
      // },
      //
      formatDates (date) {
        if (date == null){
          return null
        } else {
          const [year, month, day] = date.split('-')
          return `${day}-${month}-${year}`
        }
      },

      async getInvoice() {

        this.loading = true

        let res1 = await axios.post(reportService_dotnet+'Invoice/invoice-list', {
          "branchID": localStorage.getItem('branch_id'),
          "shopID": localStorage.getItem('shop_id') ,
          "dateFrom": this.search_date_from,
          "dateTo": this.search_date_to,
          "isGroupName": true,
          "skip": 0,
          "take": 1000000
        },
        { headers: header_token})
          const dateFrom_tmp = res1.data.dateFrom.split("-")
          const dateTo_tmp = res1.data.dateTo.split("-")
          this.search_date_from = dateFrom_tmp[2] + '-' + dateFrom_tmp[1] + '-' + dateFrom_tmp[0]
          this.search_date_to = dateTo_tmp[2] + '-' + dateTo_tmp[1] + '-' + dateTo_tmp[0]
          // this.selectInvoiceDate = dateTo_tmp[2] + '-' + dateTo_tmp[1] + '-' + dateTo_tmp[0]
          this.invoice['table'] = []
          this.invoice['table'] = res1.data.invoiceList
        this.invoice['showDatetime'] = false
        this.loading = false
      },


      async openListOrderInvoice(row){
        this.detail_vatType = row.vatType
        this.detail_vatAmount = row.vatAmount
        this.detail_totalPrice = row.totalPrice
        this.detail_totalItemPrice = row.totalItemPrice
        this.detail_calculatedPrice = row.calculatedPrice

        this.detail_invoiceGroupID = row.invoiceGroupID

        this.invoice_id_row = row
        this.loading = true
        let res1 = await axios.post(reportService_dotnet + 'Invoice/group-detail', {
          "id": row.id
        },
        { headers: header_token})
        this.listOrderInvoice['table'] = []
        this.listOrderInvoice['table'] = res1.data.itemList
        this.listOrderInvoice['invoiceId'] = row.id
        this.listOrderInvoice['invoiceNo'] = row.invoiceNo
        this.listOrderInvoice['invoiceDate'] = row.invoiceDate

        this.item_invoice_list = res1.data.itemListGroupIsNull
        this.ch_InvoiceGroupID = res1.data.invoiceGroupID
        this.data_group_detail = res1.data
        this.data_group_detailt_for_excel = []

        this.loading = false
        // console.log(this.listOrderInvoice);
        this.listOrderInvoiceDialog = true


      },

      dialog_print_open (item) {
        this.item_for_print = item
        this.title_print = null
        this.title_print = item.description
        this.dialog_select_print = true
      },

      async PrintPreview(item){
        var invoiceID = item.invoiceID != null ? item.invoiceID.toString() : null
        var invoiceGroupID_tmp = item.invoiceGroupID
        if (invoiceGroupID_tmp != null){
          invoiceGroupID_tmp = invoiceGroupID_tmp.toString()
        }
        window.open('/print-invoice?inv=' + invoiceID + "&group=" + invoiceGroupID_tmp + '&type='+this.select_print_type.toString() + '&groupname=' + item.groupName);
        this.select_print_type = 1

      },
    }
  }
</script>
<style>
  .packhai-border-table{
    /* border:1px solid #e9edf4; */
    border-collapse: collapse;
    border-spacing: 0px;
    border-right: 1px solid #e9edf4;
    border-bottom: 1px solid #e9edf4;
    border-top: 1px solid #e9edf4;
  }
  .packhai-border-table thead{
    color:white;
  }
  .packhai-border-table thead tr th{
    color: black !important;
    font-weight: bold;
    font-size: 14px;
  }
  .packhai-border-table tr {
    /* border-collapse: collapse; */
    /* border: 1px solid #e9edf4; */
  }
  .packhai-border-table th {
    /* border-collapse: collapse; */
      border-left: 1px solid #e9edf4;
  }
  .packhai-border-table tr td{
    border-left: 1px solid #e9edf4;
  }


</style>
