<template>
    <div v-if="page_loading">
        <loading :page_loading="page_loading" />
    </div>
    <div v-else>
      <loading :loading="loading" />

      <v-card class="v-card-bottom-30">
        <v-card-title class="py-5">
          <h4>{{use_language.home_page}}</h4> <v-icon>mdi-chevron-right</v-icon><span class="font-weight-regular body-1">{{use_language.best_seller}}</span>
          <v-spacer/>
        </v-card-title>
      </v-card>

      <v-card class="v-card-bottom-30">
        <v-card-title>
         <v-icon size="20" color="primary">fa-sliders-h</v-icon><span class="py-2 ml-3">{{use_language.search}}</span>
         <v-spacer/>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="2" lg="1" xl="1">
              <span>{{use_language.date}} : </span>
            </v-col>
            <v-col cols="12" md="2">
              <v-menu
                  ref="search_menu_date_from"
                  v-model="search_menu_date_from"
                  :close-on-content-click="false"
                  :return-value.sync="search_date_from"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        :value="computed_search_date_from"
                        @change="value => computed_search_date_from = value"
                        autocomplete="off"
                        label="เริ่ม"
                        outlined
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        hide-details
                        dense
                        v-on="on"
                    >
                     <template v-slot:label> <span>{{use_language.start_}}</span> </template> 
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="search_date_from" @input="search_menu_date_from = false, $refs.search_menu_date_from.save(search_date_from)" no-title :allowed-dates="allowedDates" class="my-0">
                  </v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="2">
              <v-menu
                  ref="search_menu_date_to"
                  v-model="search_menu_date_to"
                  :close-on-content-click="false"
                  :return-value.sync="search_date_to"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        :value="computed_search_date_to"
                        @change="value => computed_search_date_to = value"
                        autocomplete="off"
                        label="สิ้นสุด"
                        outlined
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        hide-details
                        dense
                        v-on="on"
                    >
                          <template v-slot:label> <span>{{use_language.end}}</span> </template> 
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="search_date_to" @input="search_menu_date_to = false, $refs.search_menu_date_to.save(search_date_to)" no-title :allowed-dates="allowedDates" class="my-0">
                  </v-date-picker>
                </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2" lg="1" xl="1">
              <span>{{use_language.according_to_product}} : </span>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="sh_select_product_list"
                :items="data_product_list"
                item-text="text"
                item-value="value"
                outlined
                dense
                chips
                small-chips
                label="--ทั้งหมด --"
                multiple
                hide-details
              >
                 <template v-slot:no-data> <span>{{use_language.all}}</span> </template> 

              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2" lg="1" xl="1">
              <span>{{use_language.salesperson}} : </span>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="sh_select_salse_list"
                :items="data_salse_list"
                item-text="text"
                item-value="value"
                outlined
                dense
                chips
                small-chips
                label="--ทั้งหมด --"
                multiple
                hide-details
              >
         <template v-slot:no-data> <span>{{use_language.all}}</span> </template> 
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2" lg="1" xl="1">
              <span>{{use_language.channel}} : </span>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="sh_select_chanal_list"
                :items="data_chanal_list"
                item-text="text"
                item-value="value"
                outlined
                dense
                chips
                small-chips
                label="--ทั้งหมด --"
                multiple
                hide-details
              >
   <template v-slot:no-data> <span>{{use_language.all}}</span> </template> 
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2" lg="1" xl="1">
              <span>{{use_language.treasury}} : </span>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="sh_select_warehouse_list"
                :items="data_warehouse_list"
                item-text="text"
                item-value="value"
                outlined
                dense
                chips
                small-chips
                label="--ทั้งหมด --"
                multiple
                hide-details
              >
                 <template v-slot:no-data> <span>{{use_language.all}}</span> </template> 
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2" lg="1" xl="1">
              <span>{{use_language.province}} : </span>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="sh_select_provine_list"
                :items="data_provine_list"
                item-text="text"
                item-value="value"
                outlined
                dense
                chips
                small-chips
                label="--ทั้งหมด --"
                multiple
                hide-details
              >                
               <template v-slot:no-data> <span>{{use_language.all}}</span> </template> 
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="this.sh_select_product_list.length != 0">
            <v-col cols="12" md="2" lg="1" xl="1">
              <span>{{use_language.grouped_by}} : </span>
            </v-col>
            <v-col cols="12" md="6">
              <v-radio-group v-model="sh_select_groupby_list" @change="set_groupName(sh_select_groupby_list)"

              >
                <v-radio
                  v-for="item in data_groupby_list"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value"
                  hide-details
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2" lg="1" xl="1">
            </v-col>
            <v-col cols="12" md="6">
              <v-btn dark color="primary" :loading="loading_btn" @click="search_data_best_sale()" class="mr-3">{{use_language.search}}</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- this.bar_graph_total_quantity_data = response.data.bar_graph_total_quantity -->
      <v-card v-if="bar_graph_total_quantity_data.length > 0" elevation="1" class="v-card-bottom-30">
        <v-card-title class="py-4">
          <v-icon size="20" color="success">fa-chart-area</v-icon> <span class="ml-3">{{use_language.ranking_best_sellers_by_number}}</span>
          <v-spacer/>
        </v-card-title>
        <v-divider/>
        <v-card-text style="min-height: 350px;">
          <GChart
            type="ColumnChart"
            :data="bar_graph_total_quantity_data"
            :options="bar_graph_total_quantity_option.chart"
            ref="gChart"
          />
        </v-card-text>
      </v-card>

      <!-- this.bar_graph_total_sale_data = response.data.bar_graph_total_sale -->
      <v-card v-if="bar_graph_total_sale_data.length > 0" elevation="1" class="v-card-bottom-30">
        <v-card-title class="py-4">
          <v-icon size="20" color="success">fa-chart-area</v-icon> <span class="ml-3">{{use_language.ranking_best_selling_products_by_sales}}</span>
          <v-spacer/>
        </v-card-title>
        <v-divider/>
        <v-card-text style="min-height: 350px;">
          <GChart
            type="ColumnChart"
            :data="bar_graph_total_sale_data"
            :options="bar_graph_total_quantity_option.chart"
            ref="gChart"
          />
        </v-card-text>
      </v-card>

      <!-- this.bar_graph_group_data = response.data.bar_graph_group -->
      <v-card v-if="bar_graph_group_data.length > 0" elevation="1" class="v-card-bottom-30">
        <v-card-title class="py-4">
          <v-icon size="20" color="success">fa-chart-area</v-icon> <span class="ml-3">{{use_language.compare_best_sellers_group_by}} {{ groupName }}</span>
          <v-spacer/>
        </v-card-title>
        <v-divider/>
        <v-card-text style="min-height: 350px;">
          <GChart
            type="ColumnChart"
            :data="bar_graph_group_data"
            :options="bar_graph_group_option.chart"
            ref="gChart"
          />
        </v-card-text>
      </v-card>


      <!-- this.graph_total_quantity_data = response.data.graph_total_quantity
      this.graph_total_quantity_by_month_data = response.data.graph_total_quantity_by_month -->
      <v-card v-if="graph_total_quantity_data.length > 0" elevation="1" class="v-card-bottom-30">
        <v-card-title class="py-4">
          <v-icon size="20" color="success">fa-chart-area</v-icon> <span class="ml-3">{{use_language.compare_best_sellers_by_number_of_pieces}}</span>
          <v-select
            class="ml-1"
            v-model="select_day_or_year"
            :items="data_day_or_year_list"
            dense
            hide-details
            outlined
            style="max-width: 130px!important; font-size: 16.5px !important;"
            color="false"
          ></v-select>
          <v-spacer/>
        </v-card-title>
        <v-divider/>
        <v-card-text style="min-height: 350px;">
          <GChart
            v-if="select_day_or_year == '1'"
            type="LineChart"
            :data="graph_total_quantity_data"
            :options="graph_total_quantity_option.chart"
            max-width="100%"
          />

          <GChart
            v-if="select_day_or_year == '2'"
            type="LineChart"
            :data="graph_total_quantity_by_month_data"
            :options="graph_total_quantity_by_month_option.chart"
            max-width="100%"
          />
        </v-card-text>
      </v-card>

      <!-- this.graph_total_sale_data = response.data.graph_total_sale
      this.graph_total_sale_by_month_data = response.data.graph_total_sale_by_month -->

      <v-card v-if="graph_total_sale_data.length > 0" elevation="1" class="v-card-bottom-30">
        <v-card-title class="py-4">
          <v-icon size="20" color="success">fa-chart-area</v-icon> <span class="ml-3">{{use_language.compare_best_sellers_by_sales}}</span>
          <v-select
            class="ml-1"
            v-model="select_day_or_year2"
            :items="data_day_or_year_list"
            dense
            hide-details
            outlined
            style="max-width: 130px!important; font-size: 16.5px !important;"
            color="false"
          ></v-select>
          <v-spacer/>
        </v-card-title>
        <v-divider/>
        <v-card-text style="min-height: 350px;">
          <GChart
            v-if="select_day_or_year2 == '1'"
            type="LineChart"
            :data="graph_total_sale_data"
            :options="graph_total_sale_option.chart"
            max-width="100%"
          />

          <GChart
            v-if="select_day_or_year2 == '2'"
            type="LineChart"
            :data="graph_total_sale_by_month_data"
            :options="graph_total_sale_by_month_option.chart"
            max-width="100%"
          />
        </v-card-text>
      </v-card>


      <v-card class="v-card-bottom-30">
        <v-card-title>
         <v-icon size="20" color="success">fa-receipt</v-icon><span class="py-2 ml-3">{{use_language.detail_information}}</span>
         <v-spacer/>
         <vue-excel-xlsx
             v-if="productReportTable_list.length != 0"
             :data="productReportTable_list"
             :columns="excel_headers"
             :filename="'รายงานรายละเอียดข้อมูล'"
             :sheetname="'รายงานรายละเอียดข้อมูล'"
             >
            <v-btn  size="1" color="success" dark class="mr-1"><v-icon >mdi-file-excel</v-icon>{{use_language.export_excel}}</v-btn>
         </vue-excel-xlsx>

        </v-card-title>
        <v-divider/>
        <v-card-text class="padding-mobile-display">

          <div class="padding-mobile-display" style="height: 500px; overflow-y: auto;">
            <v-data-table :headers="productReportTable_headers" :items="productReportTable_list" class="elevation-0 packhai-border-table" hide-default-footer :items-per-page="10000" mobile-breakpoint="0">
              <template v-slot:item.no="{ item }">
                <span v-if="item.channelName != ''">{{ productReportTable_list.indexOf(item) + 1 }}</span>
                <!-- {{ check_loaded(productReportTable_list.indexOf(item) + 1) }} -->
              </template>
            </v-data-table>
          </div>

        </v-card-text>
      </v-card>

      <!-- <div >

        <v-card class="pa-0 ma-0"  style="height : 537px" align="center" justify="center">

          <v-container class="pa-0 ma-0" fill-height fluid>
            <v-row class="pa-0 ma-0" align="center" justify="center">
                <v-col class="pa-0 ma-0">

                  <span style="color: #fbc02d ;">loading ..</span>
                  <v-progress-linear
                    class="mt-5"
                    style="max-width : 300px"
                    indeterminate
                    color="yellow darken-2"
                  ></v-progress-linear>
                </v-col>
            </v-row>
          </v-container>
        </v-card>
      </div> -->




    </div>
</template>

<script>
    import Loading from '@/website/components/Loading'
    import Swal from 'sweetalert2'
    import { GChart } from 'vue-google-charts'
    import axios from 'axios'
    import { reportService_dotnet } from '@/website/global'
    import { format_number, format_order_id, format_price,get_languages } from '@/website/global_function'
    import { header_token } from '@/website/backend/token'
    import { dashboard_language } from "@/website/language/dashboard_language";

    
    export default {
        components: {
            Loading,
            GChart,
            // 'barcode': VueBarcode
        },

        data: () => ({
            page_loading: true,
            loading: false,
            loading_btn: false,

            use_language: null,
            set_language: null,

            //search
            search_menu_date_from: false,
            search_date_from: null,
            search_menu_date_to: false,
            search_date_to: null,

            sh_select_product_list : [],
            data_product_list : [],

            sh_select_salse_list : [],
            data_salse_list : [],

            sh_select_chanal_list : [],
            data_chanal_list : [],

            sh_select_provine_list : [],
            data_provine_list : [],

            sh_select_warehouse_list : [],
            data_warehouse_list : [],

            sh_select_groupby_list : 1,
            data_groupby_list : [
              {"value" : 1 , "text": "พนักงานขาย"},
              {"value" : 2 , "text": "ช่องทางการขาย"},
              {"value" : 3 , "text": "จังหวัด"},
            ],

            select_day_or_year : 1,
            select_day_or_year2 : 1,
            groupName : "พนักงานขาย",
            data_day_or_year_list : [
              {"value" : 1 , "text": "ตามวันที่"},
              {"value" : 2 , "text": "ตามเดือน"},
            ],

            productReportTable_headers : [
              { text: 'ลำดับ',   align: 'center',   value: 'no', width: '5%', sortable: false },
              { text: 'สินค้า',   align: 'left', value: 'productName',  width: '455px', sortable: false },
              { text: 'ช่องทาง',   align: 'left',   value: 'channelName',  width: '155px', sortable: false },
              { text: 'จังหวัด',   align: 'left', value: 'province',  width: '155px', sortable: false },
              { text: 'วันที่',   align: 'center', value: 'reportDate',  width: '155px', sortable: false },
              { text: 'พนักงาน',   align: 'left', value: 'staffName',  width: '155px', sortable: false },
              { text: 'ส่วนลด',   align: 'right', value: 'totalDiscount',  width: '155px', sortable: false },
              { text: 'ยอดขาย',   align: 'right', value: 'totalPrice',  width: '155px', sortable: false },
              { text: 'จำนวน',   align: 'right', value: 'totalQuantity',  width: '155px', sortable: false },
              { text: 'ยอดขายทั้งหมด',   align: 'right', value: 'totalSale',  width: '155px', sortable: false },
              { text: 'Sku',   align: 'right', value: 'sku',  width: '155px', sortable: false },
              { text: 'Prop1',   align: 'right', value: 'prop1',  ผwidth: '155px', sortable: false },
              { text: 'Prop2',   align: 'right', value: 'prop2',  ผwidth: '155px', sortable: false },

            ],
            productReportTable_list : [],

            // chart
            // กราฟ จัดอันดับสินค้าขายดีตามจำนวน
            bar_graph_total_quantity_data:[],
            bar_graph_total_quantity_option: {
              chart: {
                curveType: 'function',
                lineWidth: 4,
                height: 450,
                width: '100%',
                intervals: { 'style': 'line' },
                legend: 'start'
              }
            },

            // กราฟ จัดอันดับสินค้าขายดีตามยอดขาย
            bar_graph_total_sale_data:[],
            bar_graph_total_sale_option: {
              chart: {
                curveType: 'function',
                lineWidth: 4,
                height: 450,
                width: '100%',
                intervals: { 'style': 'line' },
                legend: 'start'
              }
            },

            // กราฟ เปรียบเทียบสินค้าขายดีจัดกลุ่มตาม {v-select ที่เลือก}
            bar_graph_group_data:[],
            bar_graph_group_option: {
              chart: {
                curveType: 'function',
                lineWidth: 4,
                height: 450,
                width: '100%',
                intervals: { 'style': 'line' },
                legend: 'start'
              }
            },

            // กราฟ เปรียบเทียบสินค้าขายดีตามจำนวนชิ้น คู่กัน
            graph_total_quantity_data: [],
            graph_total_quantity_option: {
              chart: {
                width: '100%',
                height: 350,
                legend: { position: 'start' }
              }
            },

            graph_total_quantity_by_month_data: [],
            graph_total_quantity_by_month_option: {
              chart: {
                width: '100%',
                height: 350,
                legend: { position: 'start' }
              }
            },

            // กราฟ เปรียบเทียบสินค้าขายดีตามยอดขาย คู่กัน
            graph_total_sale_data: [],
            graph_total_sale_option: {
              chart: {
                width: '100%',
                height: 350,
                legend: { position: 'start' }
              }
            },

            graph_total_sale_by_month_data: [],
            graph_total_sale_by_month_option: {
              chart: {
                width: '100%',
                height: 350,
                legend: { position: 'start' }
              }
            },

            // excel
            excel_headers : [
              { label: 'สินค้า',   field: 'productName',  width: 45 },
              { label: 'ช่องทาง', field: 'channelName',  width: 45 },
              { label: 'จังหวัด',  field: 'province',  width: 20 },
              { label: 'วันที่',   field: 'reportDate',  width: 20 },
              { label: 'พนักงาน', field: 'staffName',  width: 20 },
              { label: 'ส่วนลด', field: 'totalDiscount',  width: 20 },
              { label: 'ยอดขาย', field: 'totalPrice',  width: 20 },
              { label: 'จำนวน', field: 'totalQuantity',  width: 20 },
              { label: 'ยอดขายทั้งหมด', field: 'totalSale',  width: 20 },
              { label: 'Sku', field: 'sku',  width: 20 },
              { label: 'Prop1', field: 'prop1',  width: 20 },
              { label: 'Prop2', field: 'prop2',  width: 20 },

            ],

        }),

        computed: {
          computed_search_date_from() {
            return this.formatDates(this.search_date_from)
          },

          computed_search_date_to() {
            return this.formatDates(this.search_date_to)
          },
        },

        async created() {

      //เก็บภาษามาไว้ใน use_language
         this.use_language = await this.get_languages(localStorage.getItem("set_language"));



            this.set_date()

            await this.get_data_for_search()
            this.get_data_best_sale_product_new()
            this.page_loading = false
        },

        methods: {
            format_number,
            format_order_id,
            format_price,
            get_languages,

            allowedDates: val => val <= new Date().toISOString().substr(0, 10),

            formatDates (date) {
              if (date == null){
                return null
              } else {
                const [year, month, day] = date.split('-')
                return `${day}-${month}-${year}`
              }
            },

            set_zero_date (num) {
              var num_tmp
              if (parseInt(num) >= 10) {
                num_tmp = num
              } else {
                num_tmp = '0' + num
              }
              return num_tmp
            },

            set_date () {
              var dateObj = new Date();
              var month = dateObj.getUTCMonth() + 1; //months from 1-12
              var day = dateObj.getUTCDate();
              var year = dateObj.getUTCFullYear();

              var firstDay = year + "-" + this.set_zero_date(month) + '-01'
              var nowdate = year + "-" + this.set_zero_date(month) + "-" + this.set_zero_date(day)

              this.search_date_from = firstDay
              this.search_date_to = nowdate
            },

            set_groupName (val){
              if(val == 1){
                this.groupName = "พนักงานขาย"
              } else if (val == 2) {
                this.groupName = "ช่องทางการขาย"
              } else if (val == 3) {
                this.groupName = "จังหวัด"
              }
            },

            // check_loaded (val){
            //   if(this.productReportTable_list.length > 0){
            //     if(this.productReportTable_list.length == val){
            //       console.log(val);
            //       console.log('success');
            //     }
            //   }
            // },

            async get_data_for_search () {

                try {
                  // this.loading = true
                  let response = await axios.post(reportService_dotnet+'Report/get-data-search-for-product-sale-report',{
                    "shopID": localStorage.getItem("shop_id")
                  } , { headers: header_token})

                  this.data_product_list = response.data.sh_product
                  this.data_salse_list = response.data.sh_salse
                  this.data_chanal_list = response.data.sh_chanal
                  this.data_provine_list = response.data.sh_province
                  this.data_warehouse_list = response.data.sh_warehouse
                  this.loading = false
                } catch (e) {
                  this.$swal({
                    type: 'warning',
                    title: 'เกิดข้อผิดพลาดบางอย่าง กรุณาติดต่อฝ่าย IT !!',
                    text: e,
                  })
                  // this.loading = false
                }


            },

            async get_data_best_sale_product_new () {

              // {
              //   "dateFrom": "2021-10-01",
              //   "dateTo": "2021-10-12",
              //   "shopID": 199,
              //   "staffShopList": [],
              //   "channelList": [],
              //   "provinceList": [],
              //   "warehouseList": [],
              //   "productMasterItemIDList": [67540, 67541, 67542, 67543, 67544],
              //   "groupByType": 1
              // }

              try {
                this.loading_btn = true

                let response = await axios.post(reportService_dotnet+'Report/get-product-sale-report',{
                  "dateFrom": this.search_date_from,
                  "dateTo": this.search_date_to,
                  "shopID": localStorage.getItem("shop_id"),
                  "staffShopList": this.sh_select_salse_list,
                  "channelList": this.sh_select_chanal_list,
                  "provinceList": this.sh_select_provine_list,
                  "warehouseList": this.sh_select_warehouse_list,
                  "productMasterItemIDList": this.sh_select_product_list,
                  "groupByType": this.sh_select_product_list.length == 0 ? 0 : this.sh_select_groupby_list
                } , { headers: header_token})

                this.bar_graph_total_quantity_data = []
                this.bar_graph_total_sale_data = []
                this.bar_graph_group_data = []
                this.graph_total_quantity_data = []
                this.graph_total_quantity_by_month_data = []
                this.graph_total_sale_data = []
                this.graph_total_sale_by_month_data = []
                this.productReportTable_list = []

                this.bar_graph_total_quantity_data = response.data.bar_graph_total_quantity
                this.bar_graph_total_sale_data = response.data.bar_graph_total_sale
                this.bar_graph_group_data = response.data.bar_graph_group
                this.graph_total_quantity_data = response.data.graph_total_quantity
                this.graph_total_quantity_by_month_data = response.data.graph_total_quantity_by_month
                this.graph_total_sale_data = response.data.graph_total_sale
                this.graph_total_sale_by_month_data = response.data.graph_total_sale_by_month
                this.productReportTable_list = response.data.productReportTable

                // เช็คหัวออกมาซ้ำ

                // if(  this.graph_total_quantity_data.length > 0){
                //     if(this.graph_total_quantity_data[0][0] == this.graph_total_quantity_data[0][1] )
                //     {
                //       this.graph_total_quantity_data.shift()
                //     }
                // }
                // if(this.graph_total_quantity_by_month_data.length > 0){
                //   if(this.graph_total_quantity_by_month_data[0][0] == this.graph_total_quantity_by_month_data[0][1] )
                //   {
                //     this.graph_total_quantity_by_month_data.shift()
                //   }
                // }
                // if(this.graph_total_sale_data.length > 0){
                //   if(this.graph_total_sale_data[0][0] == this.graph_total_sale_data[0][1] )
                //   {
                //     this.graph_total_sale_data.shift()
                //   }
                // }
                // if(this.graph_total_sale_by_month_data.length > 0){
                //   if(this.graph_total_sale_by_month_data[0][0] == this.graph_total_sale_by_month_data[0][1] )
                //   {
                //     this.graph_total_sale_by_month_data.shift()
                //   }
                // }

                this.loading_btn = false
              } catch (e) {
                this.$swal({
                  type: 'warning',
                  title: 'เกิดข้อผิดพลาดบางอย่าง กรุณาติดต่อฝ่าย IT !!',
                  text: e,
                })
                this.loading_btn = false
              }


            },


            search_data_best_sale () {
              this.get_data_best_sale_product_new()
            },
        }
    }
</script>
<style scoped>
    /* >>>.v-dialog {
        -webkit-box-shadow: none;
        box-shadow: none;
    } */

    >>>.bex48-logo {
        width: 12.533vw;
        height: 11.2vw;
        display: block;
        /* margin: 8.8vw auto 1.6vw; */
        margin: 1.6vw auto 1.6vw;
    }

    >>>.slide-fade-enter-active {
      transition: all .3s ease;
    }
    >>>.slide-fade-leave-active {
      transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    >>>.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
      transform: translateY(10px);
      opacity: 0;
    }

    .packhai-border-table{
      border:1px solid rgba(0, 0, 0, 0.12);
      border-radius:4px;
    }
    .packhai-border-table thead{
      color:white;
    }
    .packhai-border-table thead tr th{

      font-weight: bold;
      font-size: 1rem;
    }
    .return-order-table{
      width: 100%;
    }
    .padding-for-order-dialog{
      padding: 0px !important ;
    }
    >>>.margin-card-r-10 {
      margin-right: 0px;
    }
    >>>.margin-card-l-10 {
      margin-left: 0px;
    }
    @media screen and (min-width: 768px) {
      .return-order-table {
        width: 300px;
      }

      .padding-for-order-dialog{
        padding: 15px !important;
      }

      .margin-card-r-3 {
        margin-right: 10px;
      }
      .margin-card-l-3 {
        margin-left: 10px;
      }
    }

    .packhai-border-preview {
      border-top: solid 1px black;
      border-left: solid 1px black;
      border-right: solid 1px black;
      border-bottom: solid 1px black;
      border-radius: 10px ;
    }

    .px-15{
      padding-left: 15px;
      padding-right:  15px;
    }

    .fz-12{
      font-size: 12px !important;
    }

    .fz-14{
      font-size: 14px !important;
    }


</style>
