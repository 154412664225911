import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"scrollable":"","max-width":"60%","persistent":"","fullscreen":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_c('span',[_vm._v("วิธีการตั้งค่า สำหรับร้านค้าใหม่")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,{attrs:{"color":"danger"}},[_vm._v("mdi-close")])],1)],1),_c(VDivider),_c(VCardText,[_c('img',{staticClass:"img-responsive",attrs:{"src":require("@/assets/new_shop_step.jpg")}}),_c(VRow,{attrs:{"justify":"center"}},[_c(VBtn,{attrs:{"color":"#00aeef"},on:{"click":function($event){return _vm.nevigate()}}},[_c('span',{staticStyle:{"color":"#fff"}},[_vm._v("อ่านเพิ่มเติม")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }