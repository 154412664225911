<template>
    <div v-if="page_loading">
        <loading :page_loading="page_loading" />
    </div>
    <div v-else>

      <loading :loading="loading" />

      <v-card class="v-card-bottom-30 hidden-xs-only">
        <v-card-title class="py-5">
          <h4>{{use_language.feedback}}</h4>
          <v-spacer/>
        </v-card-title>
      </v-card>

      <v-card class="v-card-bottom-30">
        <v-card-title class="py-5">
          <v-icon class="mr-3" color="info">fa-comment-dots</v-icon> <span>{{use_language.packhai_feedback}}</span>
          <v-spacer/>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <span>{{use_language.packhai_feedback_detail}}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="8" >
              <v-textarea
                v-model="feedback"
                filled
                auto-grow
                color="info"
                rows="4"
                row-height="30"
                shaped
              >
             <template  v-slot:label> <span>{{use_language.packhai_feedback_text}}</span> </template> 

              </v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="8">
              <v-btn v-if="feedback != null && feedback != '' && feedback.length > 10" @click="create_shop_feedback()" color="info" dark>ส่งข้อเสนอแนะ</v-btn>
              <v-btn v-else dark>{{use_language.send_feedback}}</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

  </div>
</template>

<script>
    import Loading from '@/website/components/Loading'

    import axios from 'axios'
    import Swal from 'sweetalert2'
    import { shopService_dotnet, } from '@/website/global' 
    import { feedback_language } from "@/website/language/feedback_language";
    import { get_languages } from '@/website/global_function'

    export default {
        components: {
            Loading,
        },

        data: () => ({
          header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
            page_loading: true,
            loading: false,
            loadSucsess : false,
            use_language: null,
            set_language: null,
            feedback: null,

        }),

        computed: {
          // computed_search_date() {
          //   return this.formatDate(this.search_date_range)
          // },
        },

        async created() {


         //เก็บภาษามาไว้ใน use_language
         this.use_language = await this.get_languages(localStorage.getItem("set_language"));

          

          this.page_loading = false
          
        },

        methods: {
          get_languages,


            async create_shop_feedback(){

              let response = await axios.post(shopService_dotnet+'Shop/create-shop-feedback', {
                "shopID": localStorage.getItem("shop_id"),
                "feedback": this.feedback
              },{ headers: this.header_token})
              if(response.data == true){
                this.$swal({
                  toast: true,
                  timer: 1500,
                  showConfirmButton: false,
                  position: 'top',
                  type: 'success',
                  title: 'บันทึกความคิดเห็นเรียบร้อย !!'
                })
                this.feedback = null
              } else {
                this.$swal({
                  type: 'warning',
                  title: 'เกิดข้อผิดพลาดบางอย่าง',
                  text: 'กรุณาลองใหม่อีกครั้ง !!',
                })
              }
            },
       

     
      
      
   

        }
    }
</script>
<style scoped>
    /* >>>.v-dialog {
        -webkit-box-shadow: none;
        box-shadow: none;
    } */

    >>>.bex48-logo {
        width: 12.533vw;
        height: 11.2vw;
        display: block;
        /* margin: 8.8vw auto 1.6vw; */
        margin: 1.6vw auto 1.6vw;
    }

    >>>.slide-fade-enter-active {
      transition: all .3s ease;
    }
    >>>.slide-fade-leave-active {
      transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    >>>.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
      transform: translateY(10px);
      opacity: 0;
    }

    .packhai-border-table{
      border:1px solid rgba(0, 0, 0, 0.12);
      border-radius:4px;
    }
    .packhai-border-table thead{
      color:white;
    }
    .packhai-border-table thead tr th{

      font-weight: bold;
      font-size: 1rem;
    }
    .return-order-table{
      width: 100%;
    }
    .padding-for-order-dialog{
      padding: 0px !important ;
    }
    >>>.margin-card-r-10 {
      margin-right: 0px;
    }
    >>>.margin-card-l-10 {
      margin-left: 0px;
    }
    @media screen and (min-width: 768px) {
      .return-order-table {
        width: 300px;
      }

      .padding-for-order-dialog{
        padding: 15px !important;
      }

      .margin-card-r-3 {
        margin-right: 10px;
      }
      .margin-card-l-3 {
        margin-left: 10px;
      }
    }

    .packhai-border-preview {
      border-top: solid 1px black;
      border-left: solid 1px black;
      border-right: solid 1px black;
      border-bottom: solid 1px black;
      border-radius: 10px ;
    }

    .px-15{
      padding-left: 15px;
      padding-right:  15px;
    }

    .fz-12{
      font-size: 12px !important;
    }

    .fz-14{
      font-size: 14px !important;
    }


</style>
