<template>
    <div v-if="page_loading">
        <loading :page_loading="page_loading" />
    </div>
    <div v-else >
      <loading :loading="loading" />
    <!-- <div v-if="data_customer.length != 0"> -->

        <v-card elevation="1" class="v-card-bottom-30 hidden-xs-only">
            <v-card-title style="height: 60px;">
            <h4> โปรโมชั่น </h4>
            <!-- <v-icon>mdi-chevron-right</v-icon><span class="font-weight-regular body-1">ลูกค้า</span> -->
            <v-spacer/>
            </v-card-title>
        </v-card>

        <v-card class="v-card-bottom-30">
            <v-card-title>
            <v-icon color="primary">mdi-gift</v-icon>
            <span class="ml-2">โปรโมชั่น</span>
            <v-spacer/>
            <v-btn outlined color="primary" @click="nevigate()"><v-icon left>mdi-plus</v-icon> {{use_language.add}}</v-btn>
            </v-card-title>
            <v-divider/>

            <v-card-text>
             <v-row  class="pt-2  ">
              <v-col cols="12" md="2" lg="1" class="pt-3  " >
                  <h3 class="fn-14"> ชื่อโปรโมชั่น : </h3>
              </v-col>
              <v-col cols="12" md="4" lg="4" class="py-0  ">
                <v-text-field
                  v-model="namePromotion"
                  
                  outlined
                  dense
                  hide-details
                  autocomplete="off"
                />
              </v-col>
             </v-row>
             <v-row  class="pt-2  ">
              <v-col cols="12" md="2" lg="1" class="pt-3  ">
                  <h3 class="fn-14"> วันที่ : </h3>
              </v-col>
              <v-col cols="12" md="2" lg="2" class="py-0  ">
                        <v-menu
                          ref="startDateMenu"
                          v-model="startDateMenu"
                          :close-on-content-click="true" 
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              :value="formattedStartDate"
                              @change="value => startDatePromotion = value"
                              autocomplete="off"
                              label="เริ่มต้น"
                              outlined
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              dense
                              hide-details
                              v-on="on"
                              clearable
                              @click:clear="startDatePromotion = null"
                            >
                              <template v-slot:label>
                                <span>{{ use_language.start }}</span>
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="startDatePromotion"
                            @input="startDateMenu = false; updateFormattedStartDate()"
                            no-title
                            class="my-0"
                          ></v-date-picker>
                        </v-menu>
              </v-col>
              
              <v-col cols="12" md="2" lg="2"  class="py-0  ">
                <v-menu
                  ref="endDateMenu"
                  v-model="endDateMenu"
                  :close-on-content-click="true" 
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="formattedEndDate"
                      
                      @click="test()"
                      autocomplete="off"
                      label="สิ้นสุด"
                      outlined
                      dense 
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      hide-details
                      v-on="on"
                          clearable
                        @click:clear="endDatePromotion = null"
                    >
                      <template v-slot:label>
                        <span>{{ use_language.end }}</span>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="endDatePromotion"
                    @change="updateFormattedEndDate()"
                    no-title
                    class="my-0"
                  ></v-date-picker>
                </v-menu>

              

              </v-col>
             </v-row>
             <v-row class="pt-2  " >
              <v-col cols="12" md="2" lg="1" class="pt-3  ">
                  <h3 class="fn-14"> ประเภท : </h3>
              </v-col>
              <v-col cols="12" md="8" >
                <v-radio-group
                  v-model="PromotionEventType"
                  column
                  row
                >
                  <v-radio
                    label="ทั้งหมด"
                    value="0"
                  ></v-radio>
                  <v-radio
                    label="โปรโมชั่นที่ใกล้จะมาถึง"
                    value="1"
                  ></v-radio>
                  <v-radio
                    label="อยู่ในช่วงโปรโมชั่น"
                    value="2"
                  ></v-radio>
                  <v-radio
                    label="จบไปแล้ว"
                    value="3"
                  ></v-radio>
                  <v-radio
                    label="ไม่เปิดใช้งาน"
                    value="4"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="2" lg="1"  class="py-0  ">
                  
              </v-col>
              <v-col cols="12" md="3" lg="3" class="py-0  ">
                <v-btn @click="Searching()" color="primary"  >
                  {{ use_language.search }}
                </v-btn>
              
              </v-col>
            </v-row>
            <br>
            <v-row>
              <v-col cols="12">
              <v-data-table
                  :headers="datatable.header"
                  :items="datatable.dataset"
                  class="elevation-0 packhai-border-table"
                  hide-default-footer
                  :items-per-page="10000">
                  
                  <template v-slot:item.Isactive="{ item }">
                      <v-row justify="center">
                      <a @click="handleActiveUpdateClick(item)">
                          <v-switch  v-model="item.isActive" color="success" ></v-switch>
                      </a>
                      </v-row>
                  </template>
                  <template  v-slot:item.name="{ item }">
                    <div class="py-3">

                 
                      <a   style="color:black" @click="handleNavigateToeditClick(item )">  <span class="fn-13" style="font-weight: bold"  > {{ item.name }}</span></a><br>
                      <span  style="color: gray;"> {{ item.formattedDateStart }} -
                      {{ item.formattedDateEnd }}
                    
                      </span>
                    </div>
                  </template>
                  <template v-slot:item.isOverlap="{ item }">
                      <v-row justify="center">
                          <v-col cols="12"  >
                          <span v-if="item.isOverlapped"><v-icon size="10" color="success">mdi-checkbox-blank-circle</v-icon> ซ้อนทับได้</span>
                          <span v-else><v-icon size="10" color="red">mdi-checkbox-blank-circle</v-icon> ซ้อนทับไม่ได้<br/>ความสำคัญลำดับที่ : {{ item.priority }}</span>
                      </v-col>
                      </v-row>
                  </template>
                  <template v-slot:item.isPromotionType="{ item }">
                      <v-row>
                          <span v-if="item.promotionType === 2">จำนวน {{ item.minQuantity }} ชิ้น</span>
                          <span v-if="item.promotionType === 1">ยอดเงิน {{ item.minSpend }} บาท</span>
                      </v-row>
                  </template>
                  <template v-slot:item.edit="{ item }">
                      <a @click="handleNavigateToeditClick(item)" style="display:flex ;flex-direction: column;">
                          <v-icon color="orange darken-3" size="18" >fa-edit</v-icon>
                      </a>
                  </template>
                  <template v-slot:item.copy="{ item }">
                      <a @click="handleCopyPromotionsClick(item)" style="display:flex ;flex-direction: column;">
                          <v-icon color="orange darken-3" size="18" >fa-copy</v-icon>
                      </a>
                  </template>
                  <template v-slot:item.delete="{ item }">
                      <a  @click="handleDeletePromotionsClick(item)"
                      ><v-icon size="18" color="red darken-3">fa-trash-alt</v-icon></a
                      >
                  </template>
              </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2" lg="1" >
              </v-col>
              <v-col cols="8"  lg="10"  @click="ChangePage()">
                <v-pagination  v-if="pageLength>1 && datatable.dataset.length>0"
                  v-model="page"
                  :length="pageLength"
                  :total-visible="pageTotalVisible"
                  color="error"
                ></v-pagination>
              </v-col>
              <v-col cols="2"  class="text-right" lg="1" >
                <v-select  
                  v-model="limits"
                  label="จำนวนแสดง"
                  :items="[10,20,50,100,500,1000]"
                  @change="LimitChange()"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
  import Loading from '@/website/components/Loading'
  import axios from 'axios'
  import Swal from 'sweetalert2'
  import { shopService_dotnet  } from '@/website/global'
  import { isNumberWNoDot ,get_languages,formatMoney} from '@/website/global_function'
 

  export default {
    components: {
        Loading
    },
    data:() => ({
        startDate: null,
        dateStart: null,
        menuStartDate: false,
        page_loading: true,
        loading: false,
        use_language: null,
        set_language: null,
        shopID:null,
        staffId:null,
        namePromotion:'',
        startDateMenu: false,
        startDatePromotion: '',
        formattedStartDate: '',
        endDateMenu: false,
        endDatePromotion: '',
        formattedEndDate: '',
        header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
        datatable: {
            header : [
            { text: 'No',align: 'center',sortable: false,value: 'no', width: '60px'},
            { text: 'ชื่อโปรโมชั่น',align: 'left',sortable: false,value: 'name', width: '300px'},
            
            { text: 'ซ้อนทับโปรโมชั่น',align: 'center',sortable: false,value: 'isOverlap' , width: '150px'},
            { text: 'ประเภทโปรโมชั่น',align: 'left',sortable: false,value: 'isPromotionType', width: '150px'},
            { text: 'สร้างโดย',align: 'center',sortable: false,value: 'staffName', width: '120px'},
            { text: 'เปิดใช้งาน',align: 'center',sortable: false,value: 'Isactive', width: '90px'},
            { text: 'แก้ไข',align: 'center',sortable: false,value: 'edit', width: '70px'},
            { text: 'ลบ',align: 'center',sortable: false,value: 'delete', width: '70px'},
            { text: 'คัดลอก',align: 'center',sortable: false,value: 'copy', width: '70px'},
            ],
            dataset:[],
            
        },
        PromotionEventType:"0",
        // paginations
        page: 1,
        pageTemp: 1,
        pageLength: 1,
        pageTotalVisible: 9,
        limits: 20,
        offsets: 0,
    }),
    async created() {

    //เก็บภาษามาไว้ใน use_language
      this.use_language = await this.get_languages(localStorage.getItem("set_language"));
      this.shopID = parseInt(localStorage.getItem('shop_id'))
      this.staffId = parseInt(localStorage.getItem('staff_id'))

      await this.getPromotionList(  0,1 );
      this.page_loading= false

    },
    methods: {
      isNumberWNoDot,get_languages,
      formatMoney,
      updateFormattedStartDate() {
          this.formattedStartDate = this.formatDate(this.startDatePromotion);
      },
      updateFormattedEndDate() {
          this.formattedEndDate = this.formatDate(this.endDatePromotion); 
      },
      async Searching() {
        await this.getPromotionList(  0,1 );
      },
      SearchingClearData() {
        this.namePromotion = '';
        this.startDatePromotion = null;
        this.endDatePromotion = null;
        this.formattedStartDate = '';
        this.formattedEndDate = '';
      },
      async getPromotionList(skip,page){
        this.loading = true
        const params = new URLSearchParams();
        params.append('ShopID', this.shopID);
        params.append('Name', this.namePromotion);
        if (this.startDatePromotion) {
            params.append('StartDate', this.startDatePromotion);
        }

        if (this.endDatePromotion) {
            params.append('EndDate', this.endDatePromotion);
        }
        const requestUrl = shopService_dotnet + 'Promotion/get-promotion-list-v2';
        let response = await axios.post(requestUrl,
        {
          "ShopID": this.shopID,
          "Name":this.namePromotion,
          "StartDate":this.startDatePromotion,
          "EndDate":this.endDatePromotion,
          "PromotionEventType":parseInt(this.PromotionEventType),
          "isNeedResultCount":true,
          "skip":skip,
          "take":this.limits,
        },
        { headers: this.header_token })
        // let response = await axios.get(shopService_dotnet+'Promotion/get-promotion-list?ShopID='+this.shopID, 
        // { headers: this.header_token }) 
        if (response.data.status){
            const staff =  await this.getStaffShopList()
            this.datatable.dataset = response.data.promotionList.map(x => {
                const datetimeStart = new Date(x.startDate)
                const datetimeEnd = new Date(x.endDate)
                const start = this.formateDate(datetimeStart)
                const end = this.formateDate(datetimeEnd)
                const findstaff = staff.find(item => item.id === x.createdBy)
                return {
                    ...x,
                    formattedDateStart: start,
                    formattedDateEnd: end,
                    staffName: findstaff ? findstaff.name : null
                };
            })
            this.page = page
            this.pageTemp=page
            this.pageLength =  (Math.ceil(response.data.resultCount/this.limits))
            //  // วันปัจจุบัน
            //  let currentDate = new Date();
            // this.datatable.dataset = this.datatable.dataset.filter(x => {
            //         return new Date(x.endDate) > currentDate;
            //         })
            this.loading = false
        }else
        {
            console.error('API response error:', response.data);
        }
      },
      async getStaffShopList(){
        let response = await axios.post(shopService_dotnet+'StaffShop/get-staffshop-list', {
          shopId: this.shopID
        }, {headers: this.header_token})
        if (response.status ==200) {
          return response.data
        }
      },
      async handleActiveUpdateClick(item){
        let response = await axios.post(shopService_dotnet+'Promotion/update-isActive-promotion', {
            shopID:this.shopID,
            promotionID:item.id,
            isActive:item.isActive,
            staffId:this.staffId,
        },
      { headers: this.header_token})
        if (response.status === 200) {
          await this.getPromotionList()
        }
      },
      async handleDeletePromotionsClick(item)
      {
        Swal.fire({
          type: "question",
          title: "คุณแน่ใจว่าต้องการลบ?",
          text : "ต้องการลบโปรโมชั่น \""+ item.name+"\"",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          allowOutsideClick: false,
        }).then(async (result) => {
          if (result.value) {
            let response = await axios.post(shopService_dotnet+'Promotion/delete-promotion', {
                shopID:this.shopID,
                promotionID:item.id,
                staffId:this.staffId,
            },
            { headers: this.header_token })
            this.loading = false
            if (response.status === 200) {
              await this.getPromotionList()
            }
          }
        });
      },
      handleNavigateToeditClick(item)
      {
          window.open("promotion/update?id="+item.id); 
      },
      parseDate (date) {
      
        if (!date) return null
        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      async ChangePage() {
        if (this.pageLength!=1 && this.pageTemp!=this.page){
          this.offsets = (this.page*this.limits)-this.limits
          await this.getPromotionList(this.offsets,this.page)
          this.pageTemp = this.page
        }
      },
      async LimitChange(){
        await this.getPromotionList(  0,1 );
      },
      formateDate(date) {
          var year= date.getFullYear()
          var month= date.getMonth() + 1
          var day= date.getDate()
          var hours= date.getHours()
          var minutes= date.getMinutes().toString().padStart(2, '0')
          var second= date.getSeconds().toString().padStart(2, '0')
          const dateformat = `${day}/${month}/${year} ${hours}:${minutes}:${second}`
          return dateformat
      },
      formatDate (date) {
         
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      nevigate(){
        window.open("promotion/update" );  
      },
      async handleCopyPromotionsClick(item) {
        Swal.fire({
            type: "question",
            title: "คุณแน่ใจว่าต้องการคัดลอก?",
            text: "ต้องการคัดลอกโปรโมชั่น \"" + item.name + "\"",
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            allowOutsideClick: false,
        }).then(async (result) => {
          if (result.value) {
              let response = await axios.post(shopService_dotnet + 'Promotion/copy-promotion', {
                  shopId: this.shopID,
                  promotionId: item.id,
                  staffId: this.staffId,
              },
                  { headers: this.header_token })
              this.loading = false
              if (response.status === 200) {
                  await this.getPromotionList()
              }
          }
        });
      },
    },
  }
</script>
