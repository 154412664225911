<template>
    <div v-if="page_loading">
        <!-- <LoadingDialog :page_loading="page_loading" /> -->

        
    </div>
    <div v-else>

      <div v-if="loadSucsess != false">
        <!-- <LoadingDialog :loading="loading" /> -->

        <v-card class="v-card-bottom-30 hidden-xs-only">
          <v-card-title class="py-5">
            <h4>Dashboard</h4> <v-icon>mdi-chevron-right</v-icon><span class="font-weight-regular body-1">{{ use_language.navigat_title }}</span>
            <v-spacer/>

          </v-card-title>
        </v-card>

        <!-- ส่วนที่ 1  -->
        <v-row justify="start" align="center" class="my-7 margin-card-status">
          <v-col cols="12" sm="12" md="12" lg="4" align="left">
            <v-card  dark color="teal" style="height: 175px;">
              <v-card-title>
                <!-- ยอดขายวันนี้ -->
                {{ use_language.card1_head }}
              </v-card-title>
              <v-divider/>
              <v-card-text style="height : 135px;">
                <!-- <v-row >
                  <v-col cols="12" align="left" class="pa-0 ma-0">
                    <img src="@/assets/icon_jpg/i1.jpg"  style="width: 40px" />
                  </v-col>
                </v-row> -->
                <v-row >
                  <v-col cols="2" xl="1" align="center" class="pa-0 ma-0">
                    <img src="@/assets/icon_jpg/i1.jpg"  style="width: 40px" />
                  </v-col>
                  <v-col cols="10" xl="11" align="center" class="pt-8">
                    <h1> ฿ <span>{{sum_today_salse}}</span> {{ use_language.currency }}</h1>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="4" align="left">
            <v-card dark color="primary" style="height: 175px;" >
              <v-card-title>
                <!-- ยอดขาย อาทิตย์นี้ -->
                {{ use_language.card2_head }}
              </v-card-title>
              <v-divider/>
              <v-card-text style="height : 135px;">
                <!-- <v-row >
                  <v-col cols="12" align="left" class="pa-0 ma-0">
                    <img src="@/assets/icon_jpg/i2.jpg"  style="width: 40px" />
                  </v-col>
                </v-row> -->
                <v-row >
                  <v-col cols="2" xl="1" align="center" class="pa-0 ma-0">
                    <img src="@/assets/icon_jpg/i2.jpg"  style="width: 40px" />
                  </v-col>
                  <v-col cols="10" xl="11" align="center">
                    <h1> ฿ <span>{{sum_thisweek_salse}}</span> {{ use_language.currency }}</h1><br><br>
                    <h3>  {{ use_language.card2_title }}   ฿ {{ sum_lastweek_salse }} {{ use_language.currency }}</h3>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="4"  align="left">
            <v-card dark color="warning" style="height: 175px;" >
              <v-card-title>
                <!-- ยอดขายเดือนนี้ -->
                {{ use_language.card3_head }}
              </v-card-title>
              <v-divider/>
              <v-card-text style="height : 135px;">
                <!-- <v-row >
                  <v-col cols="12" align="left" class="pa-0 ma-0">
                    <img src="@/assets/icon_jpg/i3.jpg"  style="width: 40px" />
                  </v-col>
                </v-row> -->
                <v-row>
                  <v-col cols="2" xl="1" align="center" class="pa-0 ma-0">
                    <img src="@/assets/icon_jpg/i3.jpg"  style="width: 40px" />
                  </v-col>
                  <v-col cols="10" xl="11" align="center" class="pt-8">
                    <h1> ฿ <span>{{sum_this_month_salse}}</span> {{ use_language.currency }}</h1>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <!-- ส่วนที่ 2  -->

        <v-card elevation="1" class="v-card-bottom-30">
          <v-card-title class="py-4">
            <v-icon size="20" color="success">fa-chart-area</v-icon> <span class="ml-3">{{ use_language.graph_line1_head }}</span>
            <v-select
              class="ml-1"
              v-model="select_year_to_this_year_items"
              @change="get_monthly_sale(select_year_to_this_year_items)"
              :items="year_to_this_year_items"
              dense
              hide-details
              outlined
              style="max-width: 100px!important; font-size: 16.5px !important;"
              color="false"
            ></v-select>
            <v-spacer/>
          </v-card-title>
          <v-divider/>
          <v-card-text style="min-height: 350px;">
            <GChart
              type="LineChart"
              :data="chartData"
              :options="chartOptions"
              max-width="100%"
            />
          </v-card-text>
        </v-card>
 
        <v-row class="mx-0 px-0">
          <v-col class="px-0" cols="12" md="6">
            <v-card elevation="1" class="v-card-bottom-30 margin-card-r-3">
              <v-card-title class="py-4">
                <v-icon size="20" color="success">fa-chart-area</v-icon> <span class="ml-3">{{ use_language.graph_pie1_head }}</span>
                <v-spacer/>
              </v-card-title>
              <v-divider/>
              <v-card-text>
                <GChart
                  type="PieChart"
                  :settings="{ packages: ['corechart'] }"
                  :data="chartPieData_chanal_price"
                  :options="chartPieOptions_chanal_price.chart"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="px-0" cols="12" md="6">
            <v-card elevation="1" class="v-card-bottom-30 margin-card-l-3">
              <v-card-title class="py-4">
                <v-icon size="20" color="success">fa-chart-area</v-icon> <span class="ml-3">{{ use_language.graph_pie2_head }}</span>
                <v-spacer/>
              </v-card-title>
              <v-divider/>
              <v-card-text >
                <GChart
                  type="PieChart"
                  :settings="{ packages: ['corechart'] }"
                  :data="chartPieData_admin_price"
                  :options="chartPieOptions_admin_price.chart"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mx-0 px-0">
          <v-col class="px-0" cols="12" md="6">
            <v-card elevation="1" class="v-card-bottom-30 margin-card-r-3">
              <v-card-title class="py-4">
                <v-icon size="20" color="info darken-3">fa-chart-bar</v-icon> <span class="ml-3">{{ use_language.card_table1_head }} </span>
                <v-spacer/>
                <v-btn @click="to_report_page('admin')" outlined color="info">{{ use_language.button_more }}</v-btn>
              </v-card-title>
              <v-divider/>
              <v-card-text style="min-height: 400px; max-height: 400px; overflow-y : auto ;">
                <!-- pc salse -->
                <v-data-table
                  :headers="[
                    { text: use_language.no_1,   align: 'center',   value: 'no', width: '5%', sortable: false },
                    { text: use_language.saleman_name,   align: 'start',   value: 'staffName', width: '60%', sortable: false },
                    { text: use_language.order_count,   align: 'end', value: 'countOrder', width: '35%', sortable: false },
                    { text: use_language.total_price,   align: 'end', value: 'totalSale', width: '35%', sortable: false },
                  ]"
                  :items="today_admin_list"
                  item-key="name"
                  class="elevation-0 packhai-border-table"
                  hide-default-footer
                  mobile-breakpoint="0"
                  :items-per-page="1000000"
                >
                  <template v-slot:item.no="{ item }">
                    {{ item.no + 1 }}
                  </template>

                </v-data-table>
              </v-card-text>
              <!-- <v-card-actions>
                <v-spacer/>
                <v-btn text @click="to_report_page('admin')" color="info">{{ use_language.button_more }} >>></v-btn>
              </v-card-actions> -->
            </v-card>
          </v-col>
          <v-col class="px-0" cols="12" md="6">
            <v-card elevation="1" class="v-card-bottom-30 margin-card-l-3">
              <v-card-title class="py-4">
                <v-icon size="20" color="info darken-3">fa-chart-bar</v-icon>
                <span class="ml-3" >{{ use_language.card_table2_head }} </span>
                <v-spacer/>
                <v-btn @click="to_report_page('product')" outlined color="info">{{ use_language.button_more }}</v-btn>
              </v-card-title>
              <v-divider/>
              <v-card-text style="min-height: 400px; max-height: 400px; overflow-y : auto ;" >
                <!-- pc product -->
                <v-data-table
                  :headers="[
                    { text: use_language.no_1,   align: 'center',   value: 'no', width: '5%', sortable: false },
                    { text: use_language.product_name,   align: 'start',   value: 'productName',  width: '45%', sortable: false },
                    { text: use_language.order_count,   align: 'end', value: 'totalQuantity',  width: '25%', sortable: false },
                    { text: use_language.total_price,   align: 'end', value: 'totalSale',  width: '25%', sortable: false },
                  ]"
                  :items="today_product_list"
                  item-key="name"
                  class="elevation-0 packhai-border-table"
                  hide-default-footer
                  mobile-breakpoint="0"
                  :items-per-page="1000000"
                >
                  <template v-slot:item.no="{ item }">
                    {{ item.no + 1 }}
                  </template>
                </v-data-table>
              </v-card-text>
              <!-- <v-card-actions>
                <v-spacer/>
                <v-btn text @click="to_report_page('product')" color="info" >{{ use_language.button_more }} >>></v-btn>
              </v-card-actions> -->
            </v-card>
          </v-col>
        </v-row>


        <v-row class="mx-0 px-0">
          <v-col class="px-0" cols="12" md="6">
            <v-card elevation="1" class="v-card-bottom-30 margin-card-r-3">
              <v-card-title class="py-4">
                <v-icon size="20" color="primary darken-3">fa-chart-bar</v-icon> <span class="ml-3">{{ use_language.card_table3_head }} </span>
                <v-spacer/>
                <v-btn @click="to_report_page('admin')" outlined color="info">{{ use_language.button_more }}</v-btn>
              </v-card-title>
              <v-divider/>
              <v-card-text style="min-height: 400px; max-height: 400px; overflow-y : auto ;">
                <!-- pc salse -->
                <v-data-table
                  :headers="[
                    { text: use_language.no_1,   align: 'center',   value: 'no', width: '5%', sortable: false },
                    { text: use_language.saleman_name,   align: 'start',   value: 'staffName', width: '60%', sortable: false },
                    { text: use_language.order_count,   align: 'end', value: 'countOrder', width: '35%', sortable: false },
                    { text: use_language.total_price,   align: 'end', value: 'totalSale', width: '35%', sortable: false },
                  ]"
                  :items="rs_admin_salse"
                  item-key="name"
                  class="elevation-0 packhai-border-table"
                  hide-default-footer
                  mobile-breakpoint="0"
                  :items-per-page="1000000"
                >
                  <template v-slot:item.no="{ item }">
                    {{ item.no + 1 }}
                  </template>

                </v-data-table>
              </v-card-text>
              <!-- <v-card-actions>
                <v-spacer/>
                <v-btn text @click="to_report_page('admin')" color="info">{{ use_language.button_more }} >>></v-btn>
              </v-card-actions> -->
            </v-card>
          </v-col>
          <v-col class="px-0" cols="12" md="6">
            <v-card elevation="1" class="v-card-bottom-30 margin-card-l-3">
              <v-card-title class="py-4">
                <v-icon size="20" color="primary darken-3">fa-chart-bar</v-icon>
                <span class="ml-3" >{{ use_language.card_table4_head }} </span>
                <v-spacer/>
                <v-btn @click="to_report_page('product')" outlined color="info">{{ use_language.button_more }}</v-btn>
              </v-card-title>
              <v-divider/>
              <v-card-text style="min-height: 400px; max-height: 400px; overflow-y : auto ;" >
                <!-- pc product -->
                <v-data-table
                  :headers="[
                    { text: use_language.no_1,   align: 'center',   value: 'no', width: '5%', sortable: false },
                    { text: use_language.product_name,   align: 'start',   value: 'productName',  width: '45%', sortable: false },
                    { text: use_language.order_count,   align: 'end', value: 'totalQuantity',  width: '25%', sortable: false },
                    { text: use_language.total_price,   align: 'end', value: 'totalSale',  width: '25%', sortable: false },
                  ]"
                  :items="rs_products_salse"
                  item-key="name"
                  class="elevation-0 packhai-border-table"
                  hide-default-footer
                  mobile-breakpoint="0"
                  :items-per-page="1000000"
                >
                  <template v-slot:item.no="{ item }">
                    {{ item.no + 1 }}
                  </template>
                </v-data-table>
              </v-card-text>
              <!-- <v-card-actions>
                <v-spacer/>
                <v-btn text @click="to_report_page('product')" color="info" >{{ use_language.button_more }} >>></v-btn>
              </v-card-actions> -->
            </v-card>
          </v-col>
        </v-row>

      </div>

      <div v-else style="max-height : 600px; padding-top: 300px;" class="px-5" align="center">
        <span style="color: orange">Loading</span><br><br>
        <v-progress-circular
          indeterminate
          color="yellow darken-2"
        ></v-progress-circular>
      </div>
 
      <v-dialog
        transition="dialog-top-transition"
        max-width="800"
        v-model="dialog_promotion"
      >
        <template v-slot:default="dialog">
          <v-card>
            <div class="text-h2 pa-12" style="font-family: 'Kanit' !important, sans-serif !important;"><b>โปรโมชั่นวันนี้!</b> </div>
            <v-card-text style="height: 500px" class="overflow-y-auto">

              <div v-for="item in data_promotion_list" :key="item.id">
                <v-row>
                  <v-col cols="12" md="5">
                    <v-card class="pa-2">
                      <v-img
                        :src="item.image"
                        max-height="125"
                      ></v-img>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="7">
                    <v-card style="height: 141px" class="pa-5 overflow-y-auto">
                      <span style="font-size: 18px; font-family: 'Kanit', sans-serif; font-weight:bold;">{{ item.title }}</span><br>
                      <span style="font-size: 14px; font-family: 'Kanit', sans-serif;">{{ item.info }}</span>
                      <div style="font-size: 14px; font-family: 'Kanit', sans-serif;">
                       <v-btn text color="primary" class="px-0" @click="to_url(item)">อ่านเพิ่มเติม</v-btn>
                     </div>
                    </v-card>
                  </v-col>
                </v-row>
                <v-divider/>
              </div>

            </v-card-text>
            <v-card-actions class="justify-end">
              <!-- <v-btn
                text
                @click="dialog.value = false"
              >Close</v-btn> -->
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      
      <v-dialog
        transition="dialog-top-transition"
        max-width="800"
        v-model="dialog_changepassword"
      > 
          <v-card>
            <v-card-title>
              กรุณาเปลี่ยนรหัสผ่าน
            </v-card-title>
          <v-divider></v-divider>
            <v-card-text style="height: 500px" class="overflow-y-auto">
             
              <v-row>
                <v-col cols="12" class="pt-1 pb-2"> 
                  คุณยังไม่ได้เปลี่ยนรหัสผ่านภายใน 90 วันที่ผ่านมา กรุณาเปลี่ยนรหัสผ่าน
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="12" md="3" class="pt-1 pb-2">
                  <span class="fn-14">  รหัสผ่านเก่า : </span>
                </v-col>
                <v-col cols="12" md="9" class="pt-1 pb-2">
                  <v-text-field
             
                    ref="staffShoppassword"
                   
                    autocomplete="off"
                    outlined
                    dense hide-details
                    :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show_password ? 'text' : 'password'"
                    @click:append="show_password = !show_password"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3" class="pt-1 pb-2">
                  <span class="fn-14">  {{use_language.password}} : </span>
                </v-col>
                <v-col cols="12" md="9" class="pt-1 pb-2">
                  <v-text-field
                    
                    ref="staffShoppassword"
                   
                    autocomplete="off"
                    outlined
                    dense hide-details
                    :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show_password ? 'text' : 'password'"
                    @click:append="show_password = !show_password"
                  ></v-text-field>
                </v-col>
              </v-row> -->

              <!-- <v-row>
                <v-col cols="12" md="3" class="pt-1 pb-2">
                  <span class="fn-14"> {{use_language.confirm_password}} : </span>
                </v-col>
                <v-col cols="12" md="9" class="pt-1 pb-2">
                  <v-text-field
                    
                    ref="staffShoppassword2"
                  
                    autocomplete="off"
                    outlined
                    dense hide-details
                    :append-icon="show_password2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show_password2 ? 'text' : 'password'"
                    @click:append="show_password2 = !show_password2"
                  ></v-text-field>
                </v-col>
              </v-row> -->
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn  primary >บันทึก</v-btn>
            </v-card-actions>
          </v-card>
       
      </v-dialog>
      <div ><firsttimedialog v-if="IsShowSetupDialog && branchID !== 301 " /></div>

    </div>
</template>

<script>
    //import LoadingDialog from '@/website/components/Loading'
    import firsttimedialog from '@/website/components/firsttimedialog'
    import VueGoogleCharts from 'vue-google-charts'
    import { GChart } from 'vue-google-charts'
    import axios from 'axios'
    import { generalService_dotnet, reportService_dotnet, branchService_dotnet,shopService_dotnet } from '@/website/global'
    import { format_number, format_order_id, format_price,get_languages } from '@/website/global_function'
 
    import { dashboard_language } from "@/website/language/dashboard_language";

    export default {
        components: {
            firsttimedialog,
            //LoadingDialog,
            GChart,
            // 'barcode': VueBarcode
        },

        data: () => ({
            page_loading: true,
            loading: false,
            loadSucsess : false,
            IsShowSetupDialog:false,
            branchID: null,
            use_language: null,
            set_language: null,

            // selectChartYear

            select_year_to_this_year_items: null,
            year_to_this_year_items :[],
            dialog_changepassword:false,
            lastlogin:null,


            // headers_admin: [
            //   { text: 'ลำดับ',   align: 'center',   value: 'no', width: '5%', sortable: false },
            //   { text: 'ชื่อ',   align: 'start',   value: 'staffName', width: '60%', sortable: false },
            //   { text: 'จำนวนออเดอร์',   align: 'end', value: 'countOrder', width: '35%', sortable: false },
            //   { text: 'ยอดขาย',   align: 'end', value: 'totalSale', width: '35%', sortable: false },
            // ],

            // headers_product: [
            //   { text: 'ลำดับ',   align: 'center',   value: 'no', width: '5%', sortable: false },
            //   { text: 'สินค้า',   align: 'start',   value: 'productName',  width: '45%', sortable: false },
            //   { text: 'จำนวน',   align: 'end', value: 'totalQuantity',  width: '25%', sortable: false },
            //   { text: 'ยอดขาย',   align: 'end', value: 'totalSale',  width: '25%', sortable: false },
            // ],

            today_admin_list : [],
            today_product_list : [],

            chartData: [
              ['month', 'Sales'],
              ['January', 0],
              ['February', 0],
              ['March', 0],
              ['April', 0],
              ['May', 0],
              ['June', 0],
              ['July', 0],
              ['August', 0],
              ['September', 0],
              ['October', 0],
              ['November', 0],
              ['December', 0],
            ],


            chartOptions: {
              chart: {
                title: 'Company Performance',
                subtitle: 'Sales, Expenses, and Profit: 2014-2017',
              }
            },

            // param Dashboard_shop_salse
            sum_today_salse: 0,
            sum_thisweek_salse: 0,
            sum_lastweek_salse: 0,
            sum_this_month_salse: 0,

            // param Dashboard_admin_salse
            rs_admin_salse: null,
            rs_products_salse: null,


            // กราฟวงกลม chanal price
            chartPieData_chanal_price: [],

            chartPieOptions_chanal_price: {
              chart: {
                // title: "ยอดขายตามช่องทางการขาย",
                pieHole: 10.4,
                width: '100%',
                height: 450,
                legend: { position: 'bottom' }
              }
            },

            // กราฟวงกลม admin price
            chartPieData_admin_price: [],

            chartPieOptions_admin_price: {
              chart: {
                // title: "ยอดขายตามแอดมิน",
                pieHole: 10.4,
                width: '100%',
                height: 450,
                legend: { position: 'bottom' }
              }
            },

            dialog_promotion: false,
            data_promotion_list: [], 
            header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')}
        }),

        computed: {
          // computed_search_date() {
          //   return this.formatDate(this.search_date_range)
          // },
        },

        async created() {


        //เก็บภาษามาไว้ใน use_language
        
         this.use_language = await this.get_languages(); 

          this.menuCode100 = localStorage.getItem("100") //หน้าแรก
          if(this.menuCode100 == 'null'){
            this.$router.push('/order')
          }
          this.branchID = parseInt(localStorage.getItem("branch_id")),


          this.page_loading = false
          await this.get_staffshop_only()
          await this.set_date()
          await this.get_data_promotions()
          // await this.get_data_dash_borad_page()

          var thisYear = new Date().getFullYear()

          this.get_shop_dashboard(thisYear)
          this.get_monthly_sale(thisYear)
          // this.get_today_sale(thisYear)

          this.get_postcodeMapping_dotnet()


        },

        methods: {
          get_languages,
            format_number,
            format_order_id,
            format_price,
            // allowedDates: val => val <= new Date().toISOString().substr(0, 10),
            setZero (data) {
              if  (parseInt(data) < 10 ) {
                return '0'+ data
              } else {
                return data
              }
            },

            get_shop_dashboard (year) {
              axios.post(reportService_dotnet+'Report/shop-dashboard', {
                "shopID": localStorage.getItem("shop_id"),
                "year": year
              },
              { headers: this.header_token}).then((response) => {

                this.sum_lastweek_salse = response.data.lastWeekTotalSale
                this.sum_thisweek_salse = response.data.thisWeekTotalSale

                this.sum_this_month_salse = response.data.thisMonthTotalSale

                this.rs_admin_salse = response.data.adminSaleTableResponse
                this.rs_products_salse = response.data.productSaleTableResponse

                this.chartPieData_admin_price = response.data.pie_admin
                this.chartPieData_chanal_price = response.data.pie_channel

                this.sum_today_salse = response.data.todayTotalSale

                this.today_admin_list = response.data.todayAdminSaleTableResponse
                this.today_product_list = response.data.todayProductSaleTableResponse

                this.loadSucsess = true

              })
            },

            get_monthly_sale(year){
              axios.post(reportService_dotnet+'Report/monthly-sale', {
                "shopID": localStorage.getItem("shop_id"),
                "year": year
              },
              { headers: this.header_token}).then((response) => {
                this.chartData = response.data.graph_sale

              })
            },

            gen_firt_and_last_day_for_munth (date) {

              date = new Date(date)
              var fd = new Date(date.getFullYear(), date.getMonth(), 1);
              var ld = new Date(date.getFullYear(), date.getMonth() + 1, 0);

              this.firstDay =  fd.getFullYear().toString()+'-'+ this.setZero(fd.getMonth()+1).toString()+'-'+ this.setZero(fd.getDate())
              this.lastDay =  ld.getFullYear().toString()+'-'+ this.setZero(ld.getMonth()+1).toString()+'-'+ this.setZero(ld.getDate())
              return [this.firstDay + ' 00:00:00', this.lastDay + ' 23:59:59']
            },

            set_date () {

              // set data to year_to_this_year_items
              var date_year_select = new Date()
              this.date_year_select =  date_year_select.getFullYear().toString()
              for (var y = 2019; y <= parseInt(this.date_year_select); y++) {
                this.year_to_this_year_items.push({
                    text:  y.toString(),
                    value: y.toString()
                });
              }

              // set defal select data
              var date_select = new Date();
              var year_for_select = date_select.getFullYear();
              this.select_year_to_this_year_items = year_for_select.toString()


            },

            to_report_page (type) {
              if(type == 'admin'){
                window.open("report-sales1");
              } else {

                window.open("dashboard_best_sale_product");
              }
            },

            get_postcodeMapping_dotnet () {
              axios.post(generalService_dotnet+'General/get-postcodemapping', {},
              { headers: this.header_token})
              .then(response => localStorage.setItem('postcodeMapping_dotnet', JSON.stringify(response.data)))
            },



            async get_data_promotions () {

              let res1 = await axios.post(branchService_dotnet+'Branch/get-new-promotions-branch',{
                "branchID": localStorage.getItem("branch_id"),
                "shopID": localStorage.getItem("shop_id"),
              } , { headers: this.header_token})
              this.data_promotion_list = res1.data
              // if(res1.data.length > 0){
              //   this.dialog_promotion = true
              // }
            },
          async get_staffshop_only () {
            await axios.post(shopService_dotnet+'StaffShop/get-staffshop-only-by-staffid', {
            "id": localStorage.getItem('staff_id'),
          },{headers: this.header_token }).then((res) => {
            this.lastlogin = res.data.lastLoginDate
            if(this.lastlogin==null){
              this.IsShowSetupDialog=true;
            }
          }).catch((error) => { });

        },

            to_url (item) {
              window.open(item.url)
            },

        }
    }
</script>
<style scoped>
    /* >>>.v-dialog {
        -webkit-box-shadow: none;
        box-shadow: none;
    } */

    >>>.bex48-logo {
        width: 12.533vw;
        height: 11.2vw;
        display: block;
        /* margin: 8.8vw auto 1.6vw; */
        margin: 1.6vw auto 1.6vw;
    }

    >>>.slide-fade-enter-active {
      transition: all .3s ease;
    }
    >>>.slide-fade-leave-active {
      transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    >>>.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
      transform: translateY(10px);
      opacity: 0;
    }

    .packhai-border-table{
      border:1px solid rgba(0, 0, 0, 0.12);
      border-radius:4px;
    }
    .packhai-border-table thead{
      color:white;
    }
    .packhai-border-table thead tr th{

      font-weight: bold;
      font-size: 1rem;
    }
    .return-order-table{
      width: 100%;
    }
    .padding-for-order-dialog{
      padding: 0px !important ;
    }
    >>>.margin-card-r-10 {
      margin-right: 0px;
    }
    >>>.margin-card-l-10 {
      margin-left: 0px;
    }
    @media screen and (min-width: 768px) {
      .return-order-table {
        width: 300px;
      }

      .padding-for-order-dialog{
        padding: 15px !important;
      }

      .margin-card-r-3 {
        margin-right: 10px;
      }
      .margin-card-l-3 {
        margin-left: 10px;
      }
    }

    .packhai-border-preview {
      border-top: solid 1px black;
      border-left: solid 1px black;
      border-right: solid 1px black;
      border-bottom: solid 1px black;
      border-radius: 10px ;
    }

    .px-15{
      padding-left: 15px;
      padding-right:  15px;
    }

    .fz-12{
      font-size: 12px !important;
    }

    .fz-14{
      font-size: 14px !important;
    }


</style>
