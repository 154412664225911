<template>
  <div v-if="page_loading">
      <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title  style="height: 60px;">
        <h4>การเงิน</h4> <v-icon>mdi-chevron-right</v-icon><span class="font-weight-regular body-1">cod-wallet</span>
      </v-card-title>
    </v-card>

    <!-- <v-card elevation="1" class="v-card-bottom-30">
      <v-tabs v-model="tab_list" dark background-color="primary" show-arrows>
        <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
        <v-tab @click="tab_list = 0, getHistoryWallet()"><span>cod wallet</span> </v-tab>
        <v-tab @click="tab_list = 1, getHistoryWithdraw()" ><span>แจ้งถอน</span> </v-tab>
      </v-tabs>
    </v-card> -->

    <!-- tap1 -->
    <v-card class="v-card-bottom-30" v-if="tab_list == 0">
      <v-card-title class="py-5">
        <span v-if="balance > 0">ยอดเงินคงเหลือ : <font class="ml-3 text-success"> {{format_price(balance)}}</font></span>
        <span v-else>ยอดเงินคงเหลือ : <font class="ml-3 text-danger" > {{balance}}</font></span>
        <v-spacer/>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="2">
            <v-menu
                ref="search_menu_date_from"
                v-model="search_menu_date_from"
                :close-on-content-click="false"
                :return-value.sync="search_date_from"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      :value="computed_search_date_from"
                      @change="value => computed_search_date_from = value"
                      autocomplete="off"
                      label="เริ่ม"
                      outlined
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      hide-details
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="search_date_from" @input="search_menu_date_from = false, $refs.search_menu_date_from.save(search_date_from)" no-title :allowed-dates="allowedDates" class="my-0">
                </v-date-picker>
              </v-menu>
          </v-col>
          <v-col cols="12" md="2">
            <v-menu
                ref="search_menu_date_to"
                v-model="search_menu_date_to"
                :close-on-content-click="false"
                :return-value.sync="search_date_to"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      :value="computed_search_date_to"
                      @change="value => computed_search_date_to = value"
                      autocomplete="off"
                      label="สิ้นสุด"
                      outlined
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      hide-details
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="search_date_to" @input="search_menu_date_to = false, $refs.search_menu_date_to.save(search_date_to)" no-title :allowed-dates="allowedDates" class="my-0">
                </v-date-picker>
              </v-menu>
          </v-col>
          <v-col cols="12" md="6">
            <v-btn color="primary" @click="getHistoryWallet(), descriptionIndex = null">ค้นหา</v-btn>
          </v-col>
          <v-col cols="12" md="2" align="right" class="hidden-xs-only">
            <vue-excel-xlsx
                :data="topupHistory.table"
                :columns="excel_wallet_header"
                :filename="'รายงาน cod wallet'"
                :sheetname="'รายงาน cod wallet'"
                >
                <v-btn color="success" outlined >Export to Excel</v-btn>
            </vue-excel-xlsx>
          </v-col>

          <v-col cols="12" >
            <!-- แสดงจอ PC -->
            <div class="hidden-xs-only">
              <v-data-table :headers="headers" :items="topupHistory.table" hide-default-footer :items-per-page="10000" class="elevation-0 packhai-border-table" >
                <template v-slot:item.created="{ item }">
                  {{ set_format_date_time(item.created) }}
                </template>
                <template v-slot:item.description="{ item }">
                  <v-row>
                    <v-col cols="12" align="left" v-if="item.Income != null">
                      <span>{{ item.description }}</span>
                    </v-col>
                    <v-col cols="12" align="left" style="padding-left: 50px;" v-else>
                      <span >{{ item.description }}</span>
                     </v-col>
                  </v-row>
                </template>
                <template v-slot:item.income="{ item }">
                  <font v-if="item.income != null" class="text-success"><b>+ {{format_price(item.income)}}</b></font>
                </template>
                <template v-slot:item.expense="{ item }">
                  <font v-if="item.expense != null" class="text-danger"><b>- {{format_price(item.expense)}}</b></font>
                </template>
                <template v-slot:item.balance="{ item }">
                  <font><b>{{ format_price(item.balance) }}</b></font>
                </template>
                <template v-slot:item.seeMore="{ item }">
                  <span v-if="item.codreportId != null">
                    <v-btn @click="seeMore_Invoice_List(item.codreportId)"  icon><v-icon color="primary">mdi-clipboard-text</v-icon></v-btn>
                  </span>
                </template>
              </v-data-table>
            </div>
            <!-- แสดงจอมือถือ -->
            <div class="hidden-sm-and-up">
              <v-divider/>
              <v-card elevation="0" class="my-1" v-for="(item, index) in topupHistory.table" :key="index" >
                <v-row>
                  <v-col class="pb-0" cols="4" align="left"> <b>{{ set_format_date_time(item.created).substring(0, 10)}}</b> </v-col>
                  <v-col class="pb-0" cols="4" align="right">
                    <span v-show="item.income != null" class="text-success"><b>+ {{format_price(item.income)}}</b></span>
                    <span v-show="item.expense != null" class="text-danger"><b>- {{format_price(item.expense)}}</b></span>
                  </v-col>
                  <v-col class="pb-0" cols="4" align="right">= {{ format_price(item.balance) }}</v-col>
                </v-row>
                <v-row>
                  <v-col class="pb-0" cols="6" align="left">{{ set_format_date_time(item.created).substring(11, 16)}}</v-col>
                  <v-col class="pt-0" cols="6" align="right">
                    <v-btn icon @click="showDescription(index, item.id)" v-if="descriptionIndex != index" ><v-icon>fa-angle-down</v-icon></v-btn>
                    <v-btn icon @click="showDescription(index, item.id)" v-else="" ><v-icon>fa-angle-up</v-icon></v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="descriptionIndex == index ">
                  <v-col class="pt-0" cols="9">
                    <span>{{ item.description }}</span>
                  </v-col>
                  <v-col class="pt-0" cols="3" align="center">
                    <span v-if="item.codreportId != null">
                      <v-btn class="pb-5" @click="seeMore_Invoice_List(item.codreportId)"  icon> <b><a href="#" style="color: #00BFFF; text-decoration: none;">รายละเอียด {{'>>'}} </a></b>  </v-btn>
                    </span>
                  </v-col>
                </v-row>
                <v-divider/>
              </v-card>
            </div>

          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- tap2 -->
    <!-- <v-card class="v-card-bottom-30" v-if="tab_list == 1">
      <v-card-title class="py-5">
        <span v-if="balance > 0">ยอดเงินคงเหลือ : <font class="ml-3 text-success"> {{format_price(balance)}}</font></span>
        <span v-else>ยอดเงินคงเหลือ : <font class="ml-3 text-danger" > {{balance}}</font></span>
        <v-spacer/>
        <v-btn v-if="this.balance > 0" class="hidden-sm-and-up" @click="open_dialog_withdrow(true) " dark color="primary"><v-icon left>mdi-plus</v-icon> แจ้งถอน</v-btn>
        <v-btn v-if="this.balance > 0" class="hidden-xs-only" @click="open_dialog_withdrow(false) " dark color="primary"><v-icon left>mdi-plus</v-icon> แจ้งถอน</v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="2">
            <v-menu
                ref="search_menu_date_from_withdraw"
                v-model="search_menu_date_from_withdraw"
                :close-on-content-click="false"
                :return-value.sync="search_date_from_withdraw"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      :value="computed_search_date_from_withdraw"
                      @change="value => computed_search_date_from_withdraw = value"
                      autocomplete="off"
                      label="เริ่ม"
                      outlined
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      hide-details
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="search_date_from_withdraw" @input="search_menu_date_from_withdraw = false, $refs.search_menu_date_from_withdraw.save(search_date_from_withdraw)" no-title :allowed-dates="allowedDates" class="my-0">
                </v-date-picker>
              </v-menu>
          </v-col>
          <v-col cols="12" md="2">
            <v-menu
                ref="search_menu_date_to_withdraw"
                v-model="search_menu_date_to_withdraw"
                :close-on-content-click="false"
                :return-value.sync="search_date_to_withdraw"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      :value="computed_search_date_to_withdraw"
                      @change="value => computed_search_date_to_withdraw = value"
                      autocomplete="off"
                      label="สิ้นสุด"
                      outlined
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      hide-details
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="search_date_to_withdraw" @input="search_menu_date_to_withdraw = false, $refs.search_menu_date_to_withdraw.save(search_date_to_withdraw)" no-title :allowed-dates="allowedDates" class="my-0">
                </v-date-picker>
              </v-menu>
          </v-col>
          <v-col cols="12" md="6">
            <v-btn color="primary" @click="getHistoryWithdraw(), descriptionIndex = null">ค้นหา</v-btn>
          </v-col>
          <v-col cols="12" md="2" align="right" class="hidden-xs-only">
            <vue-excel-xlsx
                :data="showdataTable_withdraw"
                :columns="excel_withdrow_header"
                :filename="'รายงาน แจ้งถอน wallet'"
                :sheetname="'รายงาน แจ้งถอน wallet'"
            >
              <v-btn color="success" outlined >Export to Excel</v-btn>
            </vue-excel-xlsx>
          </v-col>
          <v-col cols="12" class="hidden-xs-only">
            <v-data-table :headers="headers_withdraw" :items="showdataTable_withdraw" class="elevation-0 packhai-border-table" hide-default-footer :items-per-page="10000">
              <template v-slot:item.id="{ item }">
                {{ FormatWithdraw(item.id) }}
              </template>
              <template v-slot:item.created="{ item }">
                {{ set_format_date_time (item.created) }}
              </template>
              <template v-slot:item.shopBankAccountId="{ item }">
                <span class="mr-1">
                  <v-avatar size="25px">
                    <img :src="item.bankLogo"/>
                  </v-avatar>
                </span>
                {{ item.fullDataBank }}
              </template>
              <template v-slot:item.amount="{ item }">
                {{ format_price( item.amount ) }}
              </template>

              <template v-slot:item.statusId="{ item }">
                <v-row >
                  <v-col cols="12" align="left" style="margin-left: 15px;">
                    <span v-if="item.statusId == TopupStatusDict['WaitCheck']"><v-icon size="10" color="yellow">mdi-checkbox-blank-circle</v-icon> รอตรวจสอบ</span>
                    <span v-else-if="item.statusId == TopupStatusDict['InProgress']"><v-icon size="10" color="info">mdi-checkbox-blank-circle</v-icon> กำลังดำเนินการ</span>
                    <span v-else-if="item.statusId == TopupStatusDict['Paid']"><v-icon size="10" color="success">mdi-checkbox-blank-circle</v-icon> จ่ายแล้ว</span>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item.detail="{ item }">
                <v-btn icon color="primary" @click="show_detail_withdrow(item.id, false)" v-if="item.statusId == TopupStatusDict['Paid']"><v-icon size="18" >mdi-clipboard-text</v-icon></v-btn>
              </template>
              <template v-slot:item.delete="{ item }">
                <v-btn icon color="danger" @click="detete_withdrow(item.id)" v-if="item.statusId == TopupStatusDict['WaitCheck']"><v-icon size="18" >fa-trash-alt</v-icon></v-btn>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" class="hidden-sm-and-up pa-0" style="background: #f0f0f0;">
            <v-card class="hidden-sm-and-up mx-0 mb-2" style="border-radius: 0px;" elevation="0" v-for="(item, index) in showdataTable_withdraw" :key="index">
              <v-row class="pa-1 mx-0">
                <v-col cols="8" align="left">
                  <span v-if="item.statusId == TopupStatusDict['WaitCheck']"><v-icon size="10" color="yellow">mdi-checkbox-blank-circle</v-icon> รอตรวจสอบ</span>
                  <span v-else-if="item.statusId == TopupStatusDict['InProgress']"><v-icon size="10" color="info">mdi-checkbox-blank-circle</v-icon> กำลังดำเนินการ</span>
                  <span v-else-if="item.statusId == TopupStatusDict['Paid']"><v-icon size="10" color="success">mdi-checkbox-blank-circle</v-icon> จ่ายแล้ว</span>
                </v-col>
              </v-row>
              <v-divider/>
              <v-row class="pa-1 mx-0">
                <v-col cols="12">
                  <v-row class="mx-0 px-0">
                    <v-col class="mx-0 px-0" cols="12" align="left">
                      {{ FormatWithdraw(item.id) }}
                    </v-col>
                  </v-row>
                  <v-row class="mx-0 px-0">
                    <v-col class="mx-0 px-0 py-10" cols="12" align="center">
                      <span><h1>฿ {{ format_price(item.amount) }}</h1></span>
                    </v-col>
                  </v-row>
                  <v-row class="mx-0 px-0">
                    <v-col class="mx-0 px-0" cols="4" align="left">
                      ธนาคาร :
                    </v-col>
                    <v-col class="mx-0 px-0" cols="8" align="right">
                      <span class="mr-1">
                        <v-avatar size="25px">
                          <img :src="item.bankLogo"/>
                        </v-avatar>
                      </span>
                      {{ item.fullDataBank }}
                    </v-col>
                  </v-row>
                  <v-row class="mx-0 px-0" >
                    <v-col class="mx-0 px-0" cols="4" align="left">
                      <span>หมายเหตุ : </span>
                    </v-col>
                    <v-col class="mx-0 px-0" cols="8" align="right">
                      <span>{{ item.remark }}</span>
                    </v-col>
                  </v-row>
                  <v-row class="mx-0 px-0" v-if="item.statusId == TopupStatusDict['Paid']">
                    <v-col class="mx-0 px-0" cols="4" align="left">
                      <span>รายละเอียด : </span>
                    </v-col>
                    <v-col class="mx-0 px-0" cols="8" align="right">
                      <v-btn icon color="primary" @click="show_detail_withdrow(item.id, true)" ><v-icon size="18" >mdi-clipboard-text</v-icon></v-btn>
                    </v-col>
                  </v-row>

                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card> -->

    <v-dialog v-model="withdrow_dialog" scrollable :max-width="size_percent_for_mobile" persistent >
      <v-card class="my-0">
        <v-card-title >
          <span>แจ้งถอน</span>
          <v-spacer></v-spacer>
          <v-btn icon  @click="withdrow_dialog = false, clear_data(), resetValidForm()"><v-icon color="danger">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row  row>
              <v-col class="pb-0" cols="12" md="2">ธนาคาร :</v-col>
              <v-col class="pb-0" cols="12" md="10">
                <v-select
                  ref="ShopBankAccountID"
                  :items="bank_list"
                  v-model="ShopBankAccountID"
                  item-key="fullBankName"
                  item-value="id"
                  style="max-width: 500px;"
                  hide-details
                  dense
                  outlined
                >
                  <template slot="selection" slot-scope="data">
                    <v-col cols="1" class='px-0' v-if="data.item.logo != null">
                      <v-avatar size="25px" >
                        <img :src="data.item.logo"/>
                      </v-avatar>
                    </v-col>
                    <v-col cols="11" class='mx-0'>
                      {{ data.item.fullBankName }}
                    </v-col>
                  </template>
                  <template slot="item" slot-scope="data">
                    <v-avatar size="25px" v-if="data.item.logo != null">
                      <img  :src="data.item.logo" />
                    </v-avatar>
                    <v-col class='ml-3'>
                      {{ data.item.fullBankName }}
                    </v-col>
                  </template>
                </v-select>
                <span v-if="this.BankValid == true && this.ShopBankAccountID == null" style="color: red;">{{ this.BankValidText }}</span>
              </v-col>
            </v-row>
            <v-row  row>
              <v-col class="pb-1" cols="12" md="2">ยอดถอน :</v-col>
              <v-col class="pb-1" cols="12" md="10">
                <v-text-field
                  ref="Amount"
                  :value="Amount"
                  @change="value => Amount = value"
                  autocomplete="off"
                  outlined
                  type="number"
                  :rules="AmountRules"
                  required
                  hide-details
                  dense
                  style="width: 250px"
                ></v-text-field>
                <span v-if="this.AmountValid == true && (this.Amount == null || this.Amount == '') " style="color: red;">{{ this.AmountValidText }}</span>
              </v-col>
            </v-row>
            <v-row  row>
              <v-col class="pb-1" cols="12" md="2">หมายเหตุ :</v-col>
              <v-col class="pb-1" cols="12" md="10">
                <v-textarea
                  ref="Remark"
                  v-model="Remark"
                  outlined
                  name="input-7-4"
                  style="max-width: 500px;"
                  hide-details
                  dense
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider/>
        <v-card-actions >
          <v-col class="py-1" cols="12" align="right">
            <v-btn color="danger" dark @click="createWithdrowOrder()">แจ้งถอน</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_detail_withdrow" scrollable :max-width="size_percent_for_mobile" persistent >
      <v-card class="my-0">
        <v-card-title >
          <span>รายละเอียดแจ้งถอน</span>
          <v-spacer></v-spacer>
          <v-btn icon  @click="dialog_detail_withdrow = false"><v-icon color="danger">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text style="min-height: 520px;">
          <v-data-table :headers="headers_detail_withdraw" :items="detail_withdrow_list" class="elevation-0 packhai-border-table hidden-xs-only" hide-default-footer :items-per-page="10000">
            <template v-slot:item.pic="{ item }">
              <div align="center">
                <v-img style="margin:5px;"
                    v-if="item.pic != null "
                    :src="item.pic"
                    aspect-ratio="1"
                    class="grey lighten-2"
                    max-width="50"
                    max-height="50"
                    @click="image_click(item.pic)"
                ></v-img>
              </div>
            </template>
            <template v-slot:item.amount="{ item }">
              {{ format_price(item.amount) }}
            </template>
          </v-data-table>

          <v-card class="hidden-sm-and-up mb-5"  v-for="(item, index) in detail_withdrow_list" :key="index">
            <v-row>
              <v-col cols="4" class="pl-5"><b>วันที่โอน : </b></v-col>
              <v-col cols="89">{{ item.paid }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="pl-5"><b>สลิป : </b></v-col>
              <v-col cols="8">
                <v-img style="margin:5px;"
                    v-if="item.pic != null "
                    :src="item.pic"
                    aspect-ratio="1"
                    class="grey lighten-2"
                    max-width="50"
                    max-height="50"
                    @click="image_click(item.pic)"
                ></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="pl-5"><b>จำนวนเงิน : </b></v-col>
              <v-col cols="8">{{ format_price(item.amount)}}</v-col>
            </v-row>
          </v-card>

        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_photo" width="900">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
                <v-img :src="dialog_photo_link" aspect-ratio="1.3" contain></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<style scoped>
  >>>.v-input__prepend-inner{
    margin-top: 10px;
  }
</style>

<script>
  import Loading from '@/website/components/Loading'
  import axios from 'axios'
  import { shopService_dotnet } from '@/website/global'
  import { format_number, format_order_id, format_price, FormatWithdraw, FormatCODWallet, set_format_date_time, withdraw_return_status_text } from '@/website/global_function'
  
  export default {
    components: {
        Loading
    },
    data: () => ({
      header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
      page_loading: true,
      loading: false,

      // dialog_photo
      dialog_photo: false,
      dialog_photo_link: null,

      // tab_list
      tab_list : 0,

      search_menu_date_from: false,
      search_date_from: null,

      search_menu_date_to: false,
      search_date_to: null,

      // tap 2

      headers_withdraw:[
        { text: 'เลขที่รายการ', value: 'id','width': '125px', 'align': 'center' ,sortable: false },
        { text: 'แจ้งวันที่', align: 'left', sortable: false, value: 'created', width:'170px' },
        { text: 'ธนาคาร', align: 'left', sortable: false, value: 'shopBankAccountId',  width:'300px' },
        { text: 'จำนวนเงิน(บาท)', align: 'right', value: 'amount', sortable: false,  width:'120px' },
        { text: 'หมายเหตุ', value: 'remark', sortable: false, 'width': '300px', align: 'left' },
        { text: 'สถานะ', align: 'center', value: 'statusId', sortable: false,  width:'150px' },
        { text: 'รายละเอียด', align: 'center', value: 'detail', sortable: false,  width:'100px' },
        { text: 'ลบ', align: 'center', value: 'delete', sortable: false,  width:'100px' },
      ],
      showdataTable_withdraw: [],

      dialog_detail_withdrow: false,
      headers_detail_withdraw:[
        { text: 'วันที่โอน', align: 'left', sortable: false, value: 'paid', width:'170px' },
        // { text: 'ธนาคาร', align: 'left', sortable: false, value: 'ShopBankAccountID',  width:'300px' },
        { text: 'สลิป', align: 'center', value: 'pic', sortable: false,  width:'70px' },
        { text: 'จำนวนเงิน(บาท)', align: 'right', value: 'amount', sortable: false,  width:'120px' },
      ],
      detail_withdrow_list: [],

      TopupStatusDict: {
        'WaitCheck': 0,
        'InProgress': 1,
        'Paid': 2,
        // 'Rejected': 2,
      },


      search_menu_date_from_withdraw: false,
      search_date_from_withdraw: null,

      search_menu_date_to_withdraw: false,
      search_date_to_withdraw: null,

      // balance: 15400,
      balance: 0,
      filterSelect: 1,
      topupHistory: {
        'headersAll': [
          // { text: 'ลำดับ', align: 'center', value: 'no', sortable: false },
          { text: 'วันที่', align: 'center', value: 'created', sortable: false, width: '10%' },
          { text: 'รายละเอียด', align: 'left', value: 'description', sortable: false, width: '25%' },
          { text: 'เข้า', align: 'right', value: 'income', sortable: false, width: '10%' },
          { text: 'ออก', align: 'right', value: 'expense', sortable: false, width: '10%' },
          { text: 'คงเหลือ', align: 'right', value: 'balance', sortable: false, width: '10%' },
          { text: 'รายละเอียด', align: 'center', value: 'seeMore', sortable: false, width: '5%' },
          // { text: 'ปริ้น', align: 'center', value: 'Print', sortable: false, width: '5%'},
        ],
        'table': [],
        'keyTable': 0
      },
      to_excel_data: [],
      to_excel_data_withdraw: [],
      footer_props: {
         'items-per-page-options': [50],
         'items-per-page-text': null,
         'disable-items-per-page': true
      },

      descriptionIndex: null,

      // dialog topup
      withdrow_dialog: false,
      isFullscreen: false,
      size_percent_for_mobile: '45%',

      // validate
      valid: true,
      AmountRules: [
        v => !!v || 'กรุณากรอก ยอดที่โอน',
      ],

      AmountValid: false,
      AmountValidText: false,
      BankValid: false,
      BankValidText: false,

      // ธนาคาร
      bank_list: [],
      Amount: null,
      ShopBankAccountID: null,
      Remark: null,

      // excel
      excel_wallet_header : [
        { label: "วันที่", field: "created", width: 20, dataFormat: set_format_date_time},
        { label: "รายละเอียด", field: "description", width: 30 },
        { label: "เข้า", field: "income", width: 15 },
        { label: "ออก", field: "expense", width: 15 },
        { label: "คงเหลือ", field: "balance", width: 15 }
      ],

      excel_withdrow_header : [
        { label: "เลขที่รายการ", field: "id", width: 20, dataFormat: FormatWithdraw },
        { label: "แจ้งวันที่", field: "created", width: 30, dataFormat: set_format_date_time },
        { label: "ธนาคาร", field: "fullDataBank", width: 40 },
        { label: "จำนวนเงิน(บาท)", field: "amount", width: 15 },
        { label: "หมายเหตุ", field: "remark", width: 15 },
        { label: "สถานะ", field: "statusId", width: 15 , dataFormat : withdraw_return_status_text }
      ],
    }),
    computed: {
      headers: function() {
        if (this.filterSelect==1){
          return this.topupHistory['headersAll']
        } else {
          return this.topupHistory['headersAll']
        }
      },

      computed_search_date_from() {
        return this.formatDates(this.search_date_from)
      },
      computed_search_date_to() {
        return this.formatDates(this.search_date_to)
      },

      computed_search_date_from_withdraw() {
        return this.formatDates(this.search_date_from_withdraw)
      },
      computed_search_date_to_withdraw() {
        return this.formatDates(this.search_date_to_withdraw)
      },

    },
    watch: {
      filterSelect: async function() {
        await this.getHistoryWallet()
      }
    },
    async created() {
      // await this.getBalance()
      await this.getHistoryWallet()
      await this.get_bank_account()
      // await this.getHistoryBalance()
      this.page_loading = false
    },
    methods: {
      format_number, format_order_id, format_price, FormatWithdraw, FormatCODWallet, set_format_date_time, withdraw_return_status_text,
      allowedDates: val => val <= new Date().toISOString().substr(0, 10),

      formatDate (date) {
        if (date.length == 0) return null

        if (date.length == 1) {
          const [year, month, day] = date[0].split('-')
          return `${day}-${month}-${year}`
        } else {
          const [year_from, month_from, day_from] = date[0].split('-')
          const [year_to, month_to, day_to] = date[1].split('-')
          if (date[0] < date[1]) {
            return `${day_from}-${month_from}-${year_from} ~ ${day_to}-${month_to}-${year_to}`
          } else {
            return `${day_to}-${month_to}-${year_to} ~ ${day_from}-${month_from}-${year_from}`
          }
        }
      },

      formatDates (date) {
        if (date == null){
          return null
        } else {
          const [year, month, day] = date.split('-')
          return `${day}-${month}-${year}`
        }
      },

      image_click(photo_link) {
          this.dialog_photo_link = photo_link
          this.dialog_photo = true
      },

      showDescription (index, ID) {
        if(this.descriptionIndex == index){
          this.descriptionIndex = null
        }else {
          this.descriptionIndex = index
        }

      },

      async getHistoryWallet() {
        this.loading = true

        let res1 = await axios.post(shopService_dotnet+'Balance/get-cod-wallet-statement-list', {
          "shopID":  localStorage.getItem('shop_id'),
          "dateFrom": this.search_date_from,
          "dateTo": this.search_date_to
        },
        { headers: this.header_token})

        this.topupHistory['table'] = res1.data.statements
        this.balance = res1.data.balance
        this.search_date_from = res1.data.dateFrom
        this.search_date_to = res1.data.dateTo

        if(res1.data.dateFrom == null){
          this.search_date_from = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        }

        if(res1.data.dateTo == null){
          this.search_date_to = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        }

        this.loading = false
      },

      seeMore_Invoice_List (CODReportID) {
        window.open('/view-detail-cod-wallet?id='+CODReportID.toString(), '_blank');
      },


      async getHistoryWithdraw() {

        let res1 = await axios.post(shopService_dotnet+'CODWallet/get-cod-withdraw-list', {
          "shopID":  localStorage.getItem('shop_id'),
          "dateFrom": this.search_date_from_withdraw,
          "dateTo": this.search_date_to_withdraw
        },
        { headers: this.header_token})
        this.showdataTable_withdraw = res1.data.statements
        this.search_date_from_withdraw = res1.data.dateFrom
        this.search_date_to_withdraw = res1.data.dateTo

        if(res1.data.dateFrom == null){
          this.search_date_from_withdraw = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        }

        if(res1.data.dateTo == null){
          this.search_date_to_withdraw = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        }
        this.loading = false
      },

      open_dialog_withdrow (isFullscreen) {
        this.withdrow_dialog = true
        this.isFullscreen = isFullscreen
        if(isFullscreen == true){
          this.size_percent_for_mobile = '95%'
        }else {
          this.size_percent_for_mobile = '45%'
        }
      },

      clear_data () {
        this.Amount = null
        this.Remark = null
        this.ShopBankAccountID = this.bank_list[0]['id']
      },
      resetValidForm () {
        this.$refs.form.resetValidation()
        this.AmountValid = false
        this.AmountValidText = null
        this.BankValid = false
        this.BankValidText = null
      },

      async get_bank_account () {

        let res1 = await axios.post(shopService_dotnet+'CODWallet/get-vshop-bank-account-wallet', {
          "shopID":  localStorage.getItem('shop_id')
        },
        { headers: this.header_token})
         this.bank_list = res1.data
         this.bank_list.splice(0, 0, {
            id: null,
            fullBankName: '--- กรุณาเลือกธนาคาร ---',
            bankNameTH: "--- กรุณาเลือกธนาคาร ---",
            logo: null,
         });
      },

      async createWithdrowOrder () {
        if(this.Amount == ''){this.Amount = null}
        if(this.Remark == ''){this.Remark = null}

        this.ShopBankAccountID
        if(this.ShopBankAccountID == null){
          this.$refs.ShopBankAccountID.focus()
          this.BankValid = true
          this.BankValidText = 'กรุณาเลือกธนาคาร'
          return
        }else if (this.Amount == null) {
          this.$refs.Amount.focus()
          this.AmountValid = true
          this.AmountValidText = 'กรุณากรอก ยอดถอน'
          return
        }

        if(parseFloat(this.Amount) > parseFloat(this.balance) ){
          this.$refs.Amount.focus()
          // this.AmountValid = true
          // this.AmountValidText = 'ยอดถอนเกินจำนวน balance'
          this.$refs.Amount.focus()
          this.Amount = this.balance
          return
        }


        let res1 = await axios.post(shopService_dotnet+'CODWallet/create-cod-withdraw', {
          "shopID": localStorage.getItem('shop_id'),
          "shopBankAcountID": this.ShopBankAccountID,
          "createdBy": localStorage.getItem('staff_id'),
          "amount": this.Amount,
          "balanceType":1,
          "remark": this.Remark
        },
        { headers: this.header_token})

        this.clear_data()
        this.resetValidForm()
        this.search_date_from_withdraw = null
        this.search_date_to_withdraw = null
        this.getHistoryWithdraw()
        this.withdrow_dialog = false

        this.loading = false

      },

      async show_detail_withdrow (withdrow_id, isFullscreen) {
        this.loading = true
        let res1 = await axios.post(shopService_dotnet+'CODWallet/get-cod-withdraw-details', {
          'witdrowID': withdrow_id
        },
        { headers: this.header_token})
        this.detail_withdrow_list = res1.data

        if(isFullscreen == true){
          this.size_percent_for_mobile = '95%'
        }else {
          this.size_percent_for_mobile = '45%'
        }
        this.dialog_detail_withdrow = true
        this.loading = false
      },

      async detete_withdrow (withdrow_id) {
        this.loading = true
        let res1 = await axios.post(shopService_dotnet+'CODWallet/delete-withdraw', {
          "rowID":  withdrow_id
        },
        { headers: this.header_token})
        this.getHistoryWithdraw()
        this.loading = false
      },
    }
  }
</script>
<style>
  .packhai-border-table{
    /* border:1px solid #e9edf4; */
    border-collapse: collapse;
    border-spacing: 0px;
    border-right: 1px solid #e9edf4;
    border-bottom: 1px solid #e9edf4;
    border-top: 1px solid #e9edf4;
  }
  .packhai-border-table thead{
    color:white;
  }
  .packhai-border-table thead tr th{
    color: black !important;
    font-weight: bold;
    font-size: 14px;
  }
  .packhai-border-table tr {
    /* border-collapse: collapse; */
    /* border: 1px solid #e9edf4; */
  }
  .packhai-border-table th {
    /* border-collapse: collapse; */
      border-left: 1px solid #e9edf4;
  }
  .packhai-border-table tr td{
    border-left: 1px solid #e9edf4;
  }


</style>
