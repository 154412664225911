<template>
    <div v-if="page_loading">
        <loading :page_loading="page_loading" />
    </div>
    <div v-else>
      <loading :loading="loading" />

      <v-card class="v-card-bottom-30">
        <v-card-title class="py-5">
          <h4>{{use_language.home_page}}</h4> <v-icon>mdi-chevron-right</v-icon><span class="font-weight-regular body-1">{{use_language.sales_by_admin}}</span>
          <v-spacer/>
        </v-card-title>
      </v-card>

      <v-card class="v-card-bottom-30">
        <v-card-title>
         <v-icon size="20" color="primary">fa-sliders-h</v-icon><span class="py-2 ml-3">{{use_language.search}}</span>
         <v-spacer/>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="2">
              <v-menu
                  ref="search_menu_date_from"
                  v-model="search_menu_date_from"
                  :close-on-content-click="false"
                  :return-value.sync="search_date_from"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        :value="computed_search_date_from"
                        @change="value => computed_search_date_from = value"
                        autocomplete="off"
                        label="เริ่ม"
                        outlined
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        hide-details
                        dense
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="search_date_from" @input="search_menu_date_from = false, $refs.search_menu_date_from.save(search_date_from)" no-title :allowed-dates="allowedDates" class="my-0">
                  </v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="2">
              <v-menu
                  ref="search_menu_date_to"
                  v-model="search_menu_date_to"
                  :close-on-content-click="false"
                  :return-value.sync="search_date_to"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        :value="computed_search_date_to"
                        @change="value => computed_search_date_to = value"
                        autocomplete="off"
                        label="สิ้นสุด"
                        outlined
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        hide-details
                        dense
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="search_date_to" @input="search_menu_date_to = false, $refs.search_menu_date_to.save(search_date_to)" no-title :allowed-dates="allowedDates" class="my-0">
                  </v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-btn dark color="primary" @click="search_admin_sale()" class="mr-3">{{use_language.search}}</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="v-card-bottom-30">
        <v-card-title>
         <v-icon size="20" color="warning">fa-chart-area</v-icon><span class="py-2 ml-3">{{use_language.sales_list_by_admin}}</span>
         <v-spacer/>
        </v-card-title>
        <v-divider/>
        <v-card-text class="padding-mobile-display">
          <v-row>
            <v-col cols="12" md="12">
              <v-card
                class="mx-auto text-center"
                color="success"
                dark
                max-width="100%"
              >
                <v-card-text>
                  <GChart
                    type="LineChart"
                    :data="chartData"
                    :options="chartOptions"
                    max-width="100%"
                  />
                </v-card-text>

                <v-card-text>
                  <div class="display-1 font-weight-thin">
                    {{use_language.monthly_sales}}
                  </div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="justify-center">
                  <v-btn
                    block
                    text
                  >
                    {{use_language.go_to_report}}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="v-card-bottom-30">
        <v-card-title>
         <v-icon size="20" color="success">fa-receipt</v-icon><span class="py-2 ml-3">{{use_language.sales_list_by_admin}}</span>
         <v-spacer/>
         <!-- <v-btn class="hidden-sm-and-up"  dark color="success" >Excel</v-btn>
         <v-btn class="hidden-xs-only"  dark color="success" >Excel</v-btn> -->
         <v-btn v-if="data_table.length != 0" size="1" color="success" dark @click="admin_export_excel()" class="mr-1"><v-icon >mdi-file-excel</v-icon>Export Excel</v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="padding-mobile-display">
          <v-row class="hidden-xs-only">
            <v-col cols="12" v-show="this.data_table.length != 0">
              <v-data-table :headers="headers" :items="data_table" class="elevation-0 packhai-border-table" hide-default-footer :items-per-page="10000">
                <template v-slot:item.No="{ item }">
                  {{ data_table.indexOf(item) + 1 }}
                </template>
                <template v-slot:item.SUM_TotalOrder="{ item }">
                  {{ format_number(item.SUM_TotalOrder) }}
                </template>
                <template v-slot:item.SUM_TotalSaleAfterDeliveryPriceDiscount="{ item }">
                  {{ format_price(item.SUM_TotalSaleAfterDeliveryPriceDiscount) }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>


    </div>
</template>

<script>
    import Loading from '@/website/components/Loading'
    import VueGoogleCharts from 'vue-google-charts'
    import { GChart } from 'vue-google-charts'
    import axios from 'axios'
    import { reportService } from '@/website/global'
    import { format_number, format_order_id, format_price,get_languages } from '@/website/global_function'
    import { dashboard_language } from "@/website/language/dashboard_language";

    
    export default {
        components: {
            Loading,
            GChart,
            // 'barcode': VueBarcode
        },

        data: () => ({
            page_loading: true,
            loading: false,

            use_language: null,
            set_language: null,

            search_menu_date_from: false,
            search_date_from: null,
            search_menu_date_to: false,
            search_date_to: null,

            headers: [
              { text: 'ลำดับ',   align: 'center',   value: 'No', width: '5%', sortable: false },
              { text: 'วันที่',   align: 'start',   value: 'ReportDate', width: '20%', sortable: false },
              { text: 'ชื่อ',   align: 'start',   value: 'StaffName', width: '40%', sortable: false },
              { text: 'จำนวนออเดอร์',   align: 'end', value: 'SUM_TotalOrder', width: '15%', sortable: false },
              { text: 'ยอดขาย',   align: 'end', value: 'SUM_TotalSaleAfterDeliveryPriceDiscount', width: '20%', sortable: false },
            ],
            data_table: [],

            // กราฟ
            chartData: [],


            chartOptions: {
              chart: {
                title: 'Company Performance',
                subtitle: 'Sales, Expenses, and Profit: 2014-2017',
              }
            },

        }),

        computed: {
          computed_search_date_from() {
            return this.formatDates(this.search_date_from)
          },

          computed_search_date_to() {
            return this.formatDates(this.search_date_to)
          },
        },

        async created() {

     //เก็บภาษามาไว้ใน use_language
         this.use_language = await this.get_languages(localStorage.getItem("set_language"));



            this.set_date()

            this.get_data_admin_sale()
            this.page_loading = false
        },

        methods: {
            format_number,
            format_order_id,
            format_price,
            get_languages,

            allowedDates: val => val <= new Date().toISOString().substr(0, 10),

            formatDates (date) {
              if (date == null){
                return null
              } else {
                const [year, month, day] = date.split('-')
                return `${day}-${month}-${year}`
              }
            },

            set_zero_date (num) {
              var num_tmp
              if (parseInt(num) > 10) {
                num_tmp = num
              } else {
                num_tmp = '0' + num
              }
              return num_tmp
            },

            set_date () {
              var dateObj = new Date();
              var month = dateObj.getUTCMonth() + 1; //months from 1-12
              var day = dateObj.getUTCDate();
              var year = dateObj.getUTCFullYear();

              var firstDay = year + "-" + this.set_zero_date(month) + '-01'
              var nowdate = year + "-" + this.set_zero_date(month) + "-" + this.set_zero_date(day)

              this.search_date_from = firstDay
              this.search_date_to = nowdate
            },

            async get_data_admin_sale () {

              this.search_date = [
                this.search_date_from,
                this.search_date_to
              ]

              this.loading = true
              let response = await axios.post(reportService+'CODReport/get_data_admin_sale', {
                  'shop_id':localStorage.getItem("shop_id"),
                  'search_date': this.search_date
              })
              if (response.data.status == 'Success') {
                  this.data_table = response.data.data_table
                  this.chartData = response.data.chardata
                  this.loading = false
              } else {
                this.$swal({
                  type: 'warning',
                  title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                })
                this.loading = false
              }
            },

            search_admin_sale () {
              this.get_data_admin_sale()
            },

            dialog_export_excel () {
              alert('export to excel')
            },

            async admin_export_excel () {

              this.loading = true
              let response = await axios.post(reportService+'CODReport/dashbord_admin_and_product_export_excel', {
                'shop_id':localStorage.getItem("shop_id"),
                'data_export': this.data_table,
                'chanal': 'admin'
              })
              if (response.data.status == 'Success') {

                var filename = response.data.this_path
                window.open(reportService+'CODReport/download/'+filename, "_blank");

                this.loading = false
              } else {
                this.$swal({
                  type: 'warning',
                  title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                })
                this.loading = false
              }
              this.loading = false
            },

        }
    }
</script>
<style scoped>
    /* >>>.v-dialog {
        -webkit-box-shadow: none;
        box-shadow: none;
    } */

    >>>.bex48-logo {
        width: 12.533vw;
        height: 11.2vw;
        display: block;
        /* margin: 8.8vw auto 1.6vw; */
        margin: 1.6vw auto 1.6vw;
    }

    >>>.slide-fade-enter-active {
      transition: all .3s ease;
    }
    >>>.slide-fade-leave-active {
      transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    >>>.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
      transform: translateY(10px);
      opacity: 0;
    }

    .packhai-border-table{
      border:1px solid rgba(0, 0, 0, 0.12);
      border-radius:4px;
    }
    .packhai-border-table thead{
      color:white;
    }
    .packhai-border-table thead tr th{

      font-weight: bold;
      font-size: 1rem;
    }
    .return-order-table{
      width: 100%;
    }
    .padding-for-order-dialog{
      padding: 0px !important ;
    }
    >>>.margin-card-r-10 {
      margin-right: 0px;
    }
    >>>.margin-card-l-10 {
      margin-left: 0px;
    }
    @media screen and (min-width: 768px) {
      .return-order-table {
        width: 300px;
      }

      .padding-for-order-dialog{
        padding: 15px !important;
      }

      .margin-card-r-3 {
        margin-right: 10px;
      }
      .margin-card-l-3 {
        margin-left: 10px;
      }
    }

    .packhai-border-preview {
      border-top: solid 1px black;
      border-left: solid 1px black;
      border-right: solid 1px black;
      border-bottom: solid 1px black;
      border-radius: 10px ;
    }

    .px-15{
      padding-left: 15px;
      padding-right:  15px;
    }

    .fz-12{
      font-size: 12px !important;
    }

    .fz-14{
      font-size: 14px !important;
    }


</style>
