import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.page_loading)?_c('div',[_c('loading',{attrs:{"page_loading":_vm.page_loading}})],1):_c('div',[_c('loading',{attrs:{"loading":_vm.loading}}),_c(VCard,{staticClass:"v-card-bottom-30 hidden-xs-only"},[_c(VCardTitle,{staticClass:"py-5"},[_c('h4',[_vm._v(_vm._s(_vm.use_language.feedback))]),_c(VSpacer)],1)],1),_c(VCard,{staticClass:"v-card-bottom-30"},[_c(VCardTitle,{staticClass:"py-5"},[_c(VIcon,{staticClass:"mr-3",attrs:{"color":"info"}},[_vm._v("fa-comment-dots")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.use_language.packhai_feedback))]),_c(VSpacer)],1),_c(VCardText,[_c(VRow,[_c(VCol,[_c('span',[_vm._v(_vm._s(_vm.use_language.packhai_feedback_detail))])])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"12","md":"12","lg":"8"}},[_c(VTextarea,{attrs:{"filled":"","auto-grow":"","color":"info","rows":"4","row-height":"30","shaped":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.use_language.packhai_feedback_text))])]},proxy:true}]),model:{value:(_vm.feedback),callback:function ($$v) {_vm.feedback=$$v},expression:"feedback"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"12","md":"12","lg":"8"}},[(_vm.feedback != null && _vm.feedback != '' && _vm.feedback.length > 10)?_c(VBtn,{attrs:{"color":"info","dark":""},on:{"click":function($event){return _vm.create_shop_feedback()}}},[_vm._v("ส่งข้อเสนอแนะ")]):_c(VBtn,{attrs:{"dark":""}},[_vm._v(_vm._s(_vm.use_language.send_feedback))])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }